const Style = {

  base: `relative text-center                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        after:absolute after:w-4 after:h-[2px] after:left-1/2 
    after:-bottom-2 after:bg-slate-200 after:-translate-x-1/2`,

  title: 'font-semibold',
  subtitle: 'font-semibold mt-0 text-xl md:text-2xl ',
  brand: 'text-white',                                                                                                            
  dark: 'text-white',
  light: 'text-brand-500',
  desc: 'text-center'

}

export default Style;