import React, { Fragment, useState, useEffect } from "react";
import { SubNav, Row, useAPI, Grid } from "components/lib";
import { useParams } from "react-router-dom";

const Axios = require("axios");

export function SanctionsView(props) {
  const params = useParams();
  const verification = useAPI(`/api/verification/${params.id}`);
  const [navBarItems, setNavBarItems] = useState([]);

  function getSanctionsResultHtml(sanctionsResults) {
    if (sanctionsResults.found_records.length === 0) {
      return <p>Sanctions Results: No matches found</p>;
    } else {
      const sanctionsMarkupArray = sanctionsResults.found_records.map((r) => (
        <p>
          Last Names: {r?.last_names?.toString()}
          <br />
          Given Names: {r?.given_names?.toString()}
          <br />
          Alias Names: {r?.alias_names?.toString()}
          <br />
          DoB: {r?.date_of_birth?.toString()}
          <br />
          Place of Birth: {r?.place_of_birth?.toString()}
          <br />
          Name: {r?.name}
          <br />
          Source: {r?.source_id}
          <br />
          <br />
        </p>
      ));

      const sanctionsMarkup = [];
      sanctionsMarkup.push(
        <div>
          <br />
          <p>
            Sanctions Results: <strong>Possible Matches found</strong>
          </p>
        </div>
      );

      sanctionsMarkupArray.forEach((sanction, index) => {
        sanctionsMarkup.push(<div key={index}>{sanction}</div>);
      });

      return sanctionsMarkup;
    }
  }

  useEffect(() => {
    async function setUpNavBarItems() {
      const verificationInfo = verification.data.verification[0];
      console.log("Setting up nav bar items", verificationInfo);
      const updatedNavBarItems = [];

      if (verificationInfo.sanctions_results) {
        updatedNavBarItems.push(
          {
            label: "KYC",
            link: `/verification/${params.id}`,
          },
          {
            label: "Sanctions",
            link: `/verification/sanctions/${params.id}`,
          }
        );
      }

      if (verificationInfo.adverse_media_results) {
        updatedNavBarItems.push({
          label: "Adverse Media",
          link: `/verification/adverse/${params.id}`,
        });

        if (updatedNavBarItems.length === 1) {
          updatedNavBarItems.push({
            label: "KYC",
            link: `/verification/${params.id}`,
          });
        }
      }

      setNavBarItems(updatedNavBarItems);
    }

    if (!verification.loading && verification.data) {
      setUpNavBarItems();
    }
  }, [verification]);

  return (
    <Fragment>
      <Grid>
        <Row>
          <SubNav items={navBarItems} />
          {verification?.data?.verification?.[0].sanctions_results &&
            getSanctionsResultHtml(
              verification?.data?.verification?.[0].sanctions_results
            )}
        </Row>
      </Grid>
    </Fragment>
  );
}
