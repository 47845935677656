/***
 *
 *   HELP
 *   Information for user to get help and support
 *
 **********/

import React, { Fragment } from "react";
import Faq from "react-faq-component";

import "./faqs.scss";

export function Faqs(props) {
  const data = {
    title: "FAQS",
    rows: [
      {
        title: "What is KYC?",
        content: `KYC means Know Your Customer. A KYC Check is a process for validating the identity of an individual using government-issued identity documents. KYC is mandatory in many environments, including banks or other organisations prone to fraud. KYC is typically required in new account opening, or at other key stages of transactions.`,
      },
      {
        title: "How does the KYC process work at IdentityCheck?",
        content:
          "You can request KYC for any contact from within your CRM (E.g. HubSpot). IdentityCheck emails the contact a unique URL, which they click on to commence the KYC process. The contact is prompted to correctly take a picture of their government-issued documents, and a selfie. The images are used by our platform to determine if the identity is valid. The result (positive or negative), plus a link to the raw images are added to the contact record inside your CRM.",
      },
      {
        title: " What types of KYC documentation are accepted?",
        content: `IdentityCheck accepts over 10,000 document types from over 200 countries to verify the identity of an individual. This includes passport, drivers licence, ID card, and residency permits.`,
      },
      {
        title: "How does Identity Verification work?",
        content:
          "Once the KYC documents are submitted, over 1,000 data points are analysed through a device and network fingerprinting solution. The average decision time is made in just 6 seconds, and 95% are complete on the first try!",
      },
      {
        title: "Does IdentityCheck meet KYC compliance requirements?",
        content:
          "IdentityCheck is powered by a KYC platform that is compliant with GDPR, WCAG, CCPA, and SOC 2 Type II",
      },
      {
        title: "How are biometrics used for KYC verification?",
        content:
          "KYC verification is completed by leveraging more than 1,000 data points including device, network, document, video, biometric, and behavioural information. The platform that powers KYC checks uses biometric authentication to confirm a user is who they claim to be, and mitigates fraudulent activities including account takeover and identity theft.",
      },
    ],
  };

  const styles = {
    titleTextColor: "#6363AC",
    rowTitleColor: "#6363AC",
  };

  const config = {
    animate: true,
    // arrowIcon: "V",
    tabFocus: true,
  };

  return (
    <Fragment>
      <Faq data={data} styles={styles} config={config} />
    </Fragment>
  );
}
