/***
 *
 *   ACCOUNT NAV
 *   Acount sub nav that renders different options based
 *   on user permissions. Shows billing & user admin to only account owners
 *
 **********/

import React from "react";
import { SubNav } from "components/lib";

export function AccountNav(props) {
  return (
    <SubNav
      items={[
        { link: "/account/profile", label: "Profile" },
        { link: "/account/password", label: "Password" },
        { link: "/account/2fa", label: "2FA" },
        { link: "/account/billing", label: "Billing", permission: "owner" },
        {
          link: "/account/apikeys/",
          label: "API Keys",
          permission: "developer",
        },
        { link: "/account/users", label: "Users", permission: "admin" },
        { link: "/account/sanctions", label: "Sanctions", permission: "admin" },
        {
          link: "/account/custom-emails",
          label: "Emails",
          permission: "admin",
        },
        {
          link: "/account/notifications",
          label: "Notifications",
          permission: "admin",
        },
        {
          link: "/account/integrations",
          label: "Integrations",
          permission: "admin",
        },
        {
          link: "/account/visibility",
          label: "Archive",
          permission: "admin",
        },
        {
          link: "/account/adverse-media",
          label: "Adverse Media",
          permission: "admin",
        },
        {
          link: "/account/reminders",
          label: "Reminders",
          permission: "admin",
        },
        {
          link: "/account/forms",
          label: "Forms",
          permission: "admin",
        },
        {
          link: "/account/post-form",
          label: "Post Verification Form",
          permission: "admin",
        },
      ]}
    />
  );
}
