// new view for showing the current integrations
// Path: client/src/views/account/integrations.js
import React, { Fragment, useContext } from "react";
import {
  Grid,
  Card,
  useAPI,
  Button,
  Image,
  Link,
  Loader,
  ViewContext,
  AuthContext,
} from "components/lib";
import xerologo from "../../assets/xero/xerologo.jpg";
import xeroConnect from "../../assets/xero/connect-white.svg";
import xeroDisconnect from "../../assets/xero/disconnect-white.svg";
import Axios from "axios";

// import Style from "./integrations.module.scss";

export function Integrations(props) {
  const hubspotConnected = useAPI("/api/integration/hubspot/connected");
  const xeroConnected = useAPI("/api/integration/xero/connected");
  const xeroInstall = useAPI("/api/integration/xero/install");
  const hubspotAuthLink = useAPI("/api/integration/hubspot/install");
  const submitHeroIntegration = useAPI("/api/integration/type/submithero");
  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);

  //   if (!hubspotConnected.loading) return <Loader />;
  //   if (!xeroConnected.loading) return <Loader />;
  //   if (!xeroInstall.loading) return <Loader />;
  //   if (!hubspotAuthLink.loading) return <Loader />;

  const handleDisconnect = async () => {
    const response = await Axios.get("/api/integration/xero/disconnect");
    console.log(response);
    window.location.reload();
  };

  const submitHeroSettings = async () => {
    console.log("submitting hero settings");
    viewContext.modal.show(
      {
        title: "Configure SubmitHero",
        form: {
          enabled: {
            label: "Active",
            type: "select",
            default: submitHeroIntegration?.data?.[0]?.enabled,
            options: [
              { value: true, label: "Active" },
              { value: false, label: "Inactive" },
            ],
          },
          campaign_id: {
            label: "HubSpot Contact Property with Campaign ID",
            value: submitHeroIntegration?.data?.[0]?.data?.campaign_id,
            type: "text",
            required: true,
          },
        },
        buttonText: "Save",
        url: `/api/integration/submithero`,
        method: "PATCH",
      },
      (res) => {
        viewContext.notification.show(
          "SubmitHero Integration" + " was updated",
          "success",
          true
        );
        // callback(res);
      }
    );
  };

  const xpmInstallAction = async () => {
    const response = await Axios.get("/api/integration/xpm/install");
    console.log(response.data.data.url);
    window.location = response.data.data.url;
  };

  const pipedriveInstallAction = async () => {
    const response = await Axios.get("/api/integration/pipedrive/install");
    console.log(response.data.data.url);
    window.location = response.data.data.url;
  };

  const getXeroButton = () => {
    if (xeroConnected?.data === undefined) {
      return <Loader />;
    }
    if (xeroConnected?.data === true) {
      return (
        <a href="#" onClick={handleDisconnect}>
          <img src={xeroDisconnect} />
        </a>
      );
    }
    if (xeroConnected?.data === false) {
      return (
        <a href={xeroInstall?.data?.url}>
          <Image source={xeroConnect} />
        </a>
      );
    }
  };

  const hubspotInstall = () => {
    window.location = hubspotAuthLink?.data?.url;
  };

  return (
    <Fragment>
      <Grid cols="4">
        <Card className="min-h-[180px]">
          <Image source="https://upload.wikimedia.org/wikipedia/commons/3/3f/HubSpot_Logo.svg"></Image>
          <br />
          {hubspotConnected?.data === true ? (
            <Button
              loading={hubspotAuthLink?.loading}
              text="Manage"
              action={() => (window.location = "/account/integrations/hubspot")}
              small
            ></Button>
          ) : (
            <Button
              loading={hubspotAuthLink?.loading}
              text="Manage"
              action={hubspotInstall}
              small
            ></Button>
          )}
        </Card>

        <Card>
          <img src={xerologo} alt="xero logo" width={75} />
          <p className="text-xs leading-6">
            Status: {xeroConnected.data ? "Connected" : "Disconnected"}
          </p>
          {!xeroConnected.loading && getXeroButton()}
        </Card>

        <Card className="min-h-[180px]">
          <Image source="https://storage.googleapis.com/submithero-images/SVG/SubmitHero_Logo_RGB_Red.svg"></Image>
          <br />
          <Button
            loading={hubspotAuthLink?.loading}
            text="Manage"
            action={submitHeroSettings}
            small
          ></Button>
        </Card>
        <Card className="min-h-[180px]">
          <div className="max-w-[35px]">
            <Image source="https://storage.googleapis.com/identitycheck/xpm-l2.png"></Image>
          </div>
          {/* <br /> */}
          {/* <p className="text-s leading-6">Xero Practice Manager</p> */}
          <p className="text-xs leading-6">
            Xero Practice Manager:{" "}
            {xeroConnected.data ? "Connected" : "Disconnected"}
          </p>
          <Button text="Install" action={xpmInstallAction} small></Button>
          <p className="text-xs leading-6">
            <Link
              url="/account/integrations/xpm"
              text="Manage"
              small
              btn
            ></Link>
          </p>
        </Card>
        <Card className="min-h-[180px]">
          <div className="max-w-[35px]">
            <Image source="https://gdm-catalog-fmapi-prod.imgix.net/ProductLogo/f2e2e04e-14be-474e-8d90-7162080c28b0.png?auto=format,compress&size=150"></Image>
          </div>
          {/* <br /> */}
          <p className="text-s leading-6">Pipedrive</p>
          <Button text="Install" action={pipedriveInstallAction} small></Button>
        </Card>
        <Card className="min-h-[180px]">
          <div className="max-w-[35px]">
            <Image source="https://storage.googleapis.com/identitycheck/fyi-logo.png"></Image>
          </div>
          <br />
          <p className="text-s leading-6">FYI Docs</p>
          <Button
            text="Install"
            action={() => (window.location = "/account/integrations/fyi")}
            small
          ></Button>
        </Card>
        <Card className="min-h-[180px]">
          <div className="max-w-[35px]">
            <Image source="https://storage.googleapis.com/identitycheck/karbon.png"></Image>
          </div>
          <br />
          <p className="text-s leading-6">Karbon (beta)</p>
          <Button
            text="Install"
            action={() => (window.location = "/account/integrations/karbon")}
            small
          ></Button>
        </Card>
      </Grid>
    </Fragment>
  );
}
