import React, { useState, useEffect } from "react";
import { useAPI, Grid, Icon, Link, Card, Button } from "components/lib";
import { useParams } from "react-router-dom";

import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import { setLicenseKey, Serializer } from "survey-core";
import { PropertyGridEditorCollection } from "survey-creator-core";
import Axios from "axios";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.min.css";

setLicenseKey(
  "YjExNDVhZmEtNTI2ZC00YjIwLTkyNDAtZGRiMzQwYjgzNmQ1OzE9MjAyNS0wNy0xNSwyPTIwMjUtMDctMTUsND0yMDI1LTA3LTE1"
);

export function FormBuilderView(props) {
  const params = useParams();
  const id = params.id;
  const formAPI = useAPI("/api/form/" + id);

  const [formCreatorMode, setFormCreatorMode] = useState("standard");
  const [readOnly, setReadOnly] = useState(true);
  const [formData, setFormData] = useState({});
  const [form, setForm] = useState({});

  Serializer.addProperty("question", {
    name: "private",
    type: "boolean",
    category: "general",
    default: false,
    visibleIndex: 0,
    onSetValue: (survey, value) => {
      // ...
      // You can perform required checks or modify the `value` here
      // ...
      // Set the `value`
      survey.setPropertyValue("private", value);
      // You can perform required actions after the `value` is set
      // ...
    },
  });

  Serializer.addProperty("question", {
    name: "tfn",
    type: "boolean",
    category: "general",
    default: false,
    visibleIndex: 0,
    onSetValue: (survey, value) => {
      // ...
      // You can perform required checks or modify the `value` here
      // ...
      // Set the `value`
      survey.setPropertyValue("tfn", value);
      // You can perform required actions after the `value` is set
      // ...
    },
  });

  Serializer.addProperty("survey", {
    name: "tfns",
    type: "single",
    category: "general",
    default: "",
    visibleIndex: 0,
    onSetValue: (survey, value) => {
      // ...
      // You can perform required checks or modify the `value` here
      // ...
      // Set the `value`
      survey.setPropertyValue("tfns", value);
      // You can perform required actions after the `value` is set
      // ...
    },
  });

  const creatorOptions = {
    showLogicTab: true,
    isAutoSave: false,
    // haveCommercialLicense: true,
    showJSONEditorTab: true,
    pageEditMode: formCreatorMode,
    readOnly: readOnly,
  };

  useEffect(() => {
    if (!formAPI?.data?.[0]?.misc?.saveNo) {
      setReadOnly(true);
    }
    console.log("fomr data", formData);
    setFormData(formAPI?.data?.[0]?.data);
    // if (
    //   formAPI?.data &&
    //   !formAPI?.loading &&
    //   Object.keys(formData).length === 0
    // ) {
    //   console.log("updating form data");
    //   setFormData(formAPI?.data?.[0]?.data);
    // }
  }, [formAPI.loading]);

  const creator = new SurveyCreator(creatorOptions);

  console.log("in before cre json", formData, formAPI?.data?.[0]?.data);
  creator.JSON = formData;

  const propertyStopList = ["storeDataAsText", "valueName", "allowMultiple"];

  creator.onShowingProperty.add(function (sender, options) {
    options.canShow = propertyStopList.indexOf(options.property.name) == -1;
  });

  creator.onQuestionAdded.add(function (sender, options) {
    var question = options.question;
    // Check if the added question is of type "file"
    if (question.getType() === "file") {
      // Set the storeDataAsText property to false by default
      question.storeDataAsText = false;
    }
  });

  creator.saveSurveyFunc = async (saveNo, callback) => {
    await saveSurveyJSON(
      "/api/form/" + id,
      creator.JSON,
      (formAPI?.data?.[0]?.misc?.saveNo || 0) + saveNo,
      callback
    );
  };

  const saveSurveyJSON = async (url, json, saveNo, callback) => {
    const response = await Axios.patch(url, {
      data: json,
      misc: { saveNo: saveNo },
    });
    if (response.data.success) {
      setFormData(json);
      callback(saveNo, true);
    } else {
      callback(saveNo, false);
    }
  };

  return (
    <>
      <Grid cols={4}>
        <Card>
          <a href={`/account/forms`}>
            <Icon
              color="dark"
              image="arrow-left"
              size={20}
              className="absolute top-1/2 left-4 -translate-y-1/2"
            />
          </a>
          <div className="relative text-center min-h-max">
            <p className="text-2xl font-bold">{formAPI?.data?.[0]?.name}</p>
            <p className="text-xs text-slate-400">
              <Link url={`/account/forms`}>Back to forms</Link>
            </p>
          </div>
        </Card>
        <Card>
          <Icon
            color="dark"
            image="alert-triangle"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2"
          />
          <div
            className="relative text-center min-h-max"
            onClick={() =>
              readOnly ? setReadOnly(false) : window.location.reload()
            }
          >
            <p className="text-2xl font-bold">{readOnly ? "No" : "Yes"}</p>
            <p className="text-sm text-sky-500">
              <Button
                text="Edit"
                textOnly
                className="underline text-sky-500"
                action={() =>
                  readOnly ? setReadOnly(false) : window.location.reload()
                }
              />
            </p>
          </div>
        </Card>
        <Card>
          <Icon
            color="dark"
            image="layout"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2"
          />
          <div className="relative text-center min-h-max">
            <p
              className="text-2xl font-bold"
              onClick={() =>
                setFormCreatorMode(
                  formCreatorMode === "bypage" ? "standard" : "bypage"
                )
              }
            >
              {formCreatorMode === "bypage" ? "By Page" : "Standard"}
            </p>
            <p className="text-sm text-slate-400">
              <Button
                text="Mode"
                textOnly
                className="underline"
                action={() =>
                  setFormCreatorMode(
                    formCreatorMode === "bypage" ? "standard" : "bypage"
                  )
                }
              />
            </p>
          </div>
        </Card>
        <Card>
          <Icon
            color="dark"
            image="calendar"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2"
          />
          <div className="relative text-center min-h-max">
            <p className="text-2xl font-bold">
              {formAPI?.data?.[0]?.created_at.split("T")[0]}
            </p>
            <p className="text-sm text-slate-400">Date</p>
          </div>
        </Card>
      </Grid>
      <div style={{ height: "100%", width: "92%", position: "absolute" }}>
        {
          <SurveyCreatorComponent
            creator={creator}
            style={{ height: "100%" }}
          />
        }
      </div>
    </>
  );
}
