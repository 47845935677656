import React, { Fragment, useEffect, useState } from "react";
import { SubNav, Row, useAPI, Grid } from "components/lib";
import { useParams } from "react-router-dom";

const Axios = require("axios");

export function AdverseMediaView(props) {
  const params = useParams();
  const verification = useAPI(`/api/verification/${params.id}`);
  const [navBarItems, setNavBarItems] = useState([]);

  function getAdverseMediaResults(adverseMediaResults) {
    if (adverseMediaResults?.status?.serviceSuspected === false || !adverseMediaResults) {
      return <p>Adverse Media Resuts: No matches found</p>;
    } else {
      const adverseMediaArray = adverseMediaResults.data.map((r) => (
        <>
          <p className="text-sky-600">
            <a href={`${r?.url}`}>
              <strong>{r?.title}</strong>
            </a>
          </p>
          <p>Text: {r?.text}</p>
          <p>
            <br />
          </p>
        </>
      ));

      const sanctionsMarkup = [];
      sanctionsMarkup.push(
        <div>
          <br />
          <p>
            Adverse Media Results: <strong>Possible Matches found</strong>
          </p>
          <br />
        </div>
      );

      adverseMediaArray.forEach((sanction, index) => {
        sanctionsMarkup.push(<div key={index}>{sanction}</div>);
      });

      return sanctionsMarkup;
    }
  }

  useEffect(() => {
    async function setUpNavBarItems() {
      const verificationInfo = verification.data.verification[0];
      let updatedNavBarItems = [];

      if (verificationInfo.sanctions_results) {
        updatedNavBarItems.push(
          {
            label: "KYC",
            link: `/verification/${params.id}`,
          },
          {
            label: "Sanctions",
            link: `/verification/sanctions/${params.id}`,
          }
        );
      }

      if (verificationInfo.adverse_media_results) {
        updatedNavBarItems.push({
          label: "Adverse Media",
          link: `/verification/adverse/${params.id}`,
        });

        if (updatedNavBarItems.length === 1) {
          updatedNavBarItems = [
            {
              label: "KYC",
              link: `/verification/${params.id}`,
            },
            ...updatedNavBarItems,
          ];
        }
      }

      if (verificationInfo?.additional_checks?.length > 0) {
        updatedNavBarItems.push({
          label: "US Criminal Checks",
          link: `/verification/us-criminal-v1/${params.id}`,
        });
      }

      setNavBarItems(updatedNavBarItems);
    }
  
    if (!verification.loading && verification.data) {
      setUpNavBarItems();
    }
  }, [verification]);

  return (
    <Fragment>
      <Grid>
        <Row>
          <SubNav items={navBarItems} />
          {verification?.data?.verification?.[0].adverse_media_results &&
            getAdverseMediaResults(
              verification?.data?.verification?.[0]?.adverse_media_results
                ?.AML?.[0]
            )}
        </Row>
      </Grid>
    </Fragment>
  );
}
