import React, { Fragment } from "react";
import {
  Grid,
  Image,
  useAPI,
  Card,
  Button,
  Link,
  Loader,
} from "components/lib";
import "survey-core/defaultV2.min.css";

export function PreVerificationFormSettings(props) {  
  const xeroConnected = useAPI("/api/integration/xero/connected");
  const forms = useAPI("/api/form");

  const xpmClickAction = () => {
    window.location.href = "/account/pre-verification/xpm";
  };

  if (forms.loading) return <Loader />;

  if (forms?.data?.length === 0) {
    return (
      <Fragment>
        <Grid cols="4">
          <Card>
            <p className="text-xs leading-6">
              No forms have been created yet. Please create a form to continue.
            </p>
            <Link url="/account/form" text="Create Form" small btn></Link>
          </Card>
        </Grid>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Grid cols="4">
        <Card>
          <div className="max-w-[35px]">
            <Image source="https://storage.googleapis.com/identitycheck/xpm-l2.png"></Image>
          </div>
          <p className="text-xs leading-6">
            Xero Practice Manager:{" "}
            {xeroConnected.data ? "Connected" : "Disconnected"}
          </p>
          <Button text="View" action={xpmClickAction} small></Button>
        </Card>
      </Grid>
    </Fragment>
  );
}
