import React, { Fragment, useCallback, useContext } from "react";
import Axios from "axios";
import { AuthContext, Row, AccountNav, useAPI, Grid } from "components/lib";
import "survey-core/defaultV2.min.css";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";

StylesManager.applyTheme("defaultV2");

const json = {
  completedHtml: "<h3>Your changes have been saved.</h3>",
  navigateToUrl: "/account",
  pages: [
    {
      name: "Enable Custom Notification Settings",
      elements: [
        {
          type: "boolean",
          name: "enableCustomNotifications",
          title: "Enable Custom Notification Settings",
          isRequired: true,
        },
      ],
    },
    {
      name: "info",
      elements: [
        {
          type: "text",
          inputType: "email",
          name: "notificationEmail",
          title: "Notification Email",
          description:
            "Email address to send notifications for each IdentityCheck Verification",
          isRequired: true,
          enableIf: "{enableCustomNotifications} = true",
        },
        {
          type: "text",
          inputType: "url",
          name: "redirectUrl",
          title: "Redirect URL",
          description: "URL to redirect to after verification is complete",
          isRequired: false,
          enableIf: "{enableCustomNotifications} = true",
        },
      ],
    },
  ],
  showQuestionNumbers: "off",
};

export function NotificationSettingsView(props) {
  const accountInfo = useAPI("/api/account");
  const survey = new Model(json);

  async function saveSurveyResults(url, json) {
    console.log("saveSurveyResults", url, json);
    const response = await Axios.post(url, json);
    console.log("saveSurveyResults response", response);
  }

  function setExistingNotificationSettings() {
    if (!accountInfo?.loading && accountInfo?.data) {
      survey.setValue(
        "enableCustomNotifications",
        accountInfo?.data.notification_enabled
      );
      survey.setValue(
        "notificationEmail",
        accountInfo?.data.notification_settings?.notificationEmail
      );
      survey.setValue(
        "redirectUrl",
        accountInfo?.data.notification_settings?.redirectUrl
      );
    }
  }

  const customNotificationConfigComplete = useCallback((sender) => {
    saveSurveyResults("/api/account/notifications", sender.data);
    window.location = "/account";
  });

  useContext(AuthContext);

  setExistingNotificationSettings();
  survey.onComplete.add(customNotificationConfigComplete);

  return (
    <Fragment>
      <AccountNav />
      <Grid>
        <Row>
          <h1>Manage Notifications</h1>
          {!accountInfo?.loading && <Survey model={survey} />}
        </Row>
      </Grid>
    </Fragment>
  );
}
