import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { useAPI, Grid, Card } from "components/lib";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
const Settings = require("settings.json");
const server_url = Settings[process.env.NODE_ENV].server_url;

StylesManager.applyTheme("defaultV2");

export function HsResponseFormView(props) {
  console.log("settings", Settings);
  const [response, setResponse] = useState({});
  const [formData, setFormData] = useState(null);
  const [ready, setReady] = useState(false);

  const params = useParams();
  const responseAPI = useAPI(`/api/public/onboarding/${params.id}`);

  const uploadFiles = useCallback((sender, options) => {
    console.log("uploadFiles", sender, options);
    var formData = new FormData();
    options.files.forEach(function (file) {
      formData.append("file", file);
      formData.append("response_id", params.id);
      formData.append("field_name", options.name);
      formData.append("file_name", file.name);
    });
 
    Axios.post(server_url + "/api/utility/gcp/upload", formData).then((data) => {
      console.log("data", data);
      options.callback(
        "success",
        options.files.map(function (file) {
          return {
            file: file,
            name: data.name,
            type: data.name,
            content: data.url,
            id: file.id,
          };
        })
      );
    });
  });

  const themeJson = {
    "themeName": "default",
    "colorPalette": "light",
    "isPanelless": false,
    "backgroundImage": "",
    "backgroundOpacity": 1,
    "backgroundImageAttachment": "scroll",
    "backgroundImageFit": "cover",
    "cssVariables": {
        "--sjs-corner-radius": "4px",
        "--sjs-base-unit": "8px",
        "--sjs-shadow-small": "0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
        "--sjs-shadow-inner": "inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
        "--sjs-border-default": "rgba(0, 0, 0, 0.16)",
        "--sjs-border-light": "rgba(0, 0, 0, 0.09)",
        "--sjs-general-backcolor": "rgba(255, 255, 255, 1)",
        "--sjs-general-backcolor-dark": "rgba(248, 248, 248, 1)",
        "--sjs-general-backcolor-dim-light": "rgba(249, 249, 249, 1)",
        "--sjs-general-backcolor-dim-dark": "rgba(243, 243, 243, 1)",
        "--sjs-general-forecolor": "rgba(0, 0, 0, 0.91)",
        "--sjs-general-forecolor-light": "rgba(0, 0, 0, 0.45)",
        "--sjs-general-dim-forecolor": "rgba(0, 0, 0, 0.91)",
        "--sjs-general-dim-forecolor-light": "rgba(0, 0, 0, 0.45)",
        "--sjs-secondary-backcolor": "rgba(255, 152, 20, 1)",
        "--sjs-secondary-backcolor-light": "rgba(255, 152, 20, 0.1)",
        "--sjs-secondary-backcolor-semi-light": "rgba(255, 152, 20, 0.25)",
        "--sjs-secondary-forecolor": "rgba(255, 255, 255, 1)",
        "--sjs-secondary-forecolor-light": "rgba(255, 255, 255, 0.25)",
        "--sjs-shadow-small-reset": "0px 0px 0px 0px rgba(0, 0, 0, 0.15)",
        "--sjs-shadow-medium": "0px 2px 6px 0px rgba(0, 0, 0, 0.1)",
        "--sjs-shadow-large": "0px 8px 16px 0px rgba(0, 0, 0, 0.1)",
        "--sjs-shadow-inner-reset": "inset 0px 0px 0px 0px rgba(0, 0, 0, 0.15)",
        "--sjs-border-inside": "rgba(0, 0, 0, 0.16)",
        "--sjs-special-red-forecolor": "rgba(255, 255, 255, 1)",
        "--sjs-special-green": "rgba(25, 179, 148, 1)",
        "--sjs-special-green-light": "rgba(25, 179, 148, 0.1)",
        "--sjs-special-green-forecolor": "rgba(255, 255, 255, 1)",
        "--sjs-special-blue": "rgba(67, 127, 217, 1)",
        "--sjs-special-blue-light": "rgba(67, 127, 217, 0.1)",
        "--sjs-special-blue-forecolor": "rgba(255, 255, 255, 1)",
        "--sjs-special-yellow": "rgba(255, 152, 20, 1)",
        "--sjs-special-yellow-light": "rgba(255, 152, 20, 0.1)",
        "--sjs-special-yellow-forecolor": "rgba(255, 255, 255, 1)",
        "--sjs-article-font-xx-large-textDecoration": "none",
        "--sjs-article-font-xx-large-fontWeight": "700",
        "--sjs-article-font-xx-large-fontStyle": "normal",
        "--sjs-article-font-xx-large-fontStretch": "normal",
        "--sjs-article-font-xx-large-letterSpacing": "0",
        "--sjs-article-font-xx-large-lineHeight": "64px",
        "--sjs-article-font-xx-large-paragraphIndent": "0px",
        "--sjs-article-font-xx-large-textCase": "none",
        "--sjs-article-font-x-large-textDecoration": "none",
        "--sjs-article-font-x-large-fontWeight": "700",
        "--sjs-article-font-x-large-fontStyle": "normal",
        "--sjs-article-font-x-large-fontStretch": "normal",
        "--sjs-article-font-x-large-letterSpacing": "0",
        "--sjs-article-font-x-large-lineHeight": "56px",
        "--sjs-article-font-x-large-paragraphIndent": "0px",
        "--sjs-article-font-x-large-textCase": "none",
        "--sjs-article-font-large-textDecoration": "none",
        "--sjs-article-font-large-fontWeight": "700",
        "--sjs-article-font-large-fontStyle": "normal",
        "--sjs-article-font-large-fontStretch": "normal",
        "--sjs-article-font-large-letterSpacing": "0",
        "--sjs-article-font-large-lineHeight": "40px",
        "--sjs-article-font-large-paragraphIndent": "0px",
        "--sjs-article-font-large-textCase": "none",
        "--sjs-article-font-medium-textDecoration": "none",
        "--sjs-article-font-medium-fontWeight": "700",
        "--sjs-article-font-medium-fontStyle": "normal",
        "--sjs-article-font-medium-fontStretch": "normal",
        "--sjs-article-font-medium-letterSpacing": "0",
        "--sjs-article-font-medium-lineHeight": "32px",
        "--sjs-article-font-medium-paragraphIndent": "0px",
        "--sjs-article-font-medium-textCase": "none",
        "--sjs-article-font-default-textDecoration": "none",
        "--sjs-article-font-default-fontWeight": "400",
        "--sjs-article-font-default-fontStyle": "normal",
        "--sjs-article-font-default-fontStretch": "normal",
        "--sjs-article-font-default-letterSpacing": "0",
        "--sjs-article-font-default-lineHeight": "28px",
        "--sjs-article-font-default-paragraphIndent": "0px",
        "--sjs-article-font-default-textCase": "none",
        "--sjs-general-backcolor-dim": "rgba(243, 243, 243, 1)",
        "--sjs-primary-backcolor": "#279BFF",
        "--sjs-primary-backcolor-dark": "rgba(37, 146, 240, 1)",
        "--sjs-primary-backcolor-light": "rgba(39, 155, 255, 0.1)",
        "--sjs-primary-forecolor": "rgba(255, 255, 255, 1)",
        "--sjs-primary-forecolor-light": "rgba(255, 255, 255, 0.25)",
        "--sjs-special-red": "rgba(229, 10, 62, 1)",
        "--sjs-special-red-light": "rgba(229, 10, 62, 0.1)"
    },
    "headerView": "basic"
};


  useEffect(() => {
    (async () => {
      if (!responseAPI.loading && responseAPI.data !== null) {
        setResponse(responseAPI.data);
      }
    })();
  }, [responseAPI]);

  useEffect(() => {
    const survey  = new Model(response);

    const params = {};
    const queryString = window.location.search.substring(1);
    const queries = queryString.split("&");

    queries.forEach(function(query) {
        const [key, value] = query.split("=");
        params[key] = decodeURIComponent(value || "");
    });

    survey.data = params;
    setFormData(survey);
    setReady(true);
  }, [response]);

  useEffect(() => {
    if (formData) {
      formData.onComplete.add(reponseComplete);
      formData.onUploadFiles.add(uploadFiles);
      formData.applyTheme(themeJson || '');
    }
  }, [formData]);

  async function saveSurveyResults(url, json) {
    console.log("saveSurveyResults", url, json);
    const updatedResponse = await Axios.post(url, json);
    if (updatedResponse.data.redirect) {
      console.log("redirecting to", updatedResponse.data.redirect);
      window.location.href = updatedResponse.data.redirect;
    }
    // window.location.replace("/public/verification/" + response.verification_id);
  }

  const reponseComplete = useCallback((sender, options) => {
    console.log("reponseComplete", sender);
    console.log("data", sender.data);
    const resultData = [];
    for (const key in sender.data) {
      const question = sender.getQuestionByName(key);
      if (!!question) {
        const item = {
          name: key,
        };

        // Generate an array of file item values from a File question
        if (question.getType() === "file") {
          item.value = [];
          question.value.forEach((fileItem) => {
            item.value.push({
              ...fileItem,
              key: `${params.id}/${key}/${fileItem.name}`,
            });
          });
        } else {
          // For regular questions, simply use a value
          item.value = question.value;
        }
        resultData.push(item);
      }
    }
    console.log("resultData", resultData);
    const outputObject = {};

    resultData.forEach((item) => {
      outputObject[item.name] = item.value;
    });
    // Send data to a server storage
    saveSurveyResults("/api/public/onboarding/" + params.id, outputObject);
  });

  // const uploadFiles = useCallback((sender, options) => {
  //   console.log("uploadFiles", sender, options);
  //   var formData = new FormData();
  //   options.files.forEach(function (file) {
  //     formData.append("file", file);
  //     formData.append("response_id", params.id);
  //     formData.append("field_name", options.name);
  //     formData.append("file_name", file.name);
  //   });
  //   var xhr = new XMLHttpRequest();
  //   xhr.open("POST", server_url + "/api/utility/gcp/upload");
  //   xhr.onload = function () {
  //     var data = JSON.parse(xhr.responseText);
  //     console.log("data", data);
  //     options.callback(
  //       "success",
  //       options.files.map(function (file) {
  //         return {
  //           file: file,
  //           name: data.name,
  //           type: data.name,
  //           content: data.url,
  //           id: file.id,
  //         };
  //       })
  //     );
  //   };
  //   console.log("form data", formData);
  //   xhr.send(formData);
  // });

  if (!formData) {
    return <div>Loading...</div>;
  }

  console.log("formData", formData);

  if (response && response.active === false) {
    return (
      <>
        <div className="min-w-min w-3/4">
          <Grid>
            <Card>
              <h2>Response Closed</h2>
              <div>
                This response is closed. You can no longer submit a response.
              </div>
            </Card>
          </Grid>
        </div>
      </>
    );
  }

  if (ready) {
    return (
      <>
        <div className="min-w-min w-3/4">
          <Grid>
            <Card>
              <Survey model={formData} />
            </Card>
          </Grid>
        </div>
      </>
    );
  }
}
