import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
  Grid,
  Card,
  useAPI,
  Button,
  Image,
  Loader,
  ViewContext,
  AuthContext,
} from "components/lib";
import Axios from "axios";
import { StylesManager, Model, QuestionTagboxModel } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";

StylesManager.applyTheme("defaultV2");

export function XpmSettingsView(props) {
  const exstingSettingsResponse = useAPI("/api/integration/xpm/settings");
  const xeroConnected = useAPI("/api/integration/xero/connected");
  const [xeroConnections, setXeroConnections] = useState([]);

  const env = process.env.NODE_ENV;
  const navigateToUrl =
    env === "production"
      ? `https://identity.stackgo.io/account`
      : `http://localhost:3000/account`;

  useEffect(async () => {
    if (xeroConnected.loading === false && xeroConnected.data === true) {
      // get connections
      const getXeroConnections = async () => {
        const xeroConnections = await Axios.get(
          "/api/integration/xero/connections"
        );

        return xeroConnections;
      };

      const xeroConnections = await getXeroConnections();
      // map to create connections dropdown
      console.log("xero connections", xeroConnections);
      const connections = xeroConnections.data.data.map((connection) => {
        console.log(connection);
        return { value: connection.tenantId, text: connection.tenantName };
      });
      setXeroConnections(connections);
    }
  }, [xeroConnected]);

  function setExistingXpmSettings() {
    if (
      exstingSettingsResponse.loading === false &&
      exstingSettingsResponse.data !== null
    ) {
      const settings = exstingSettingsResponse?.data?.[0];
      console.log("settings", settings);
      console.log(xeroConnections);
      survey.setValue("enabled", settings?.enabled);
      survey.setValue("tenantId", settings?.data?.tenantId);
    }
  }

  // surveyjs json
  const json = {
    completedHtml: "<h3>Your changes have been saved.</h3>",
    showQuestionNumbers: "off",
    pages: [
      {
        name: "Manage your XPM integration",
        elements: [
          {
            type: "boolean",
            name: "enabled",
            title: "Enable XPM integration",
            isRequired: true,
          },
          {
            type: "dropdown",
            name: "tenantId",
            title: "Select a Xero connection",
            isRequired: true,
            choices: xeroConnections,
          },
        ],
      },
    ],
  };

  json.navigateToUrl = navigateToUrl;

  async function saveSurveyResults(url, json) {
    console.log("saveSettings", url, json);
    const response = await Axios.post(url, json);
  }

  const xpmSettingsComplete = useCallback((sender) => {
    saveSurveyResults("/api/integration/xpm/settings", sender.data);
  });

  // if hubspot is not connected - return to integrations page
  // if (xeroConnected.loading === false && xeroConnected.data === false) {
  //   window.location = "/account/integrations";
  // }

  const survey = new Model(json);
  survey.onComplete.add(xpmSettingsComplete);

  setExistingXpmSettings();

  return (
    <Fragment>
      <Survey model={survey} />
    </Fragment>
  );
}
