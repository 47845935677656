/***
 *
 *   IN-APP PRICING
 *   Pricing table for your landing/pricing page
 *   Update the features array in /config file with your own
 *
 **********/

import React from "react";
import { Card, Button, Grid } from "components/lib";

export function InAppPricing(props) {
  return (
    <>
      <Card title="How pricing works" >
        <Grid cols={1}>
          <section className="relative p-6 x-1 mb-6 rounded last-of-type:mb-0 bg-blue-200">
            <h3>Starter Pack</h3><br/>
            <p>US $30 / month </p><br />
            <p>Includes 6 Identity Checks/month</p><br />
            <p>Over 6 checks = US $5.00 each at the end of the month</p><br />
            <p>Cancel / upgrade any time</p><br />
          </section>
          <section className="relative p-6 x-1 mb-6 rounded last-of-type:mb-0 bg-blue-200">
          <h3>Need More?</h3><br/>
            <p>PEP/Sanctions = US$1.00 per check</p><br />
            <p>Adverse Media = US $1.00 per check</p><br />
            <p>Discounts available at volume</p><br />
            <Grid cols={2}>
            <Button text="Request Quote" smaller external="https://share.hsforms.com/1XM2tQUHmR6eXVCDeNoP9bAcf82p"/> <Button text="Book Call" smaller external= "https://meetings.hubspot.com/matt-collis/identitycheck-demo?uuid=5145cc1f-c83d-4b1f-965b-aaabc9986d68"/>
            </Grid>
          </section>
        </Grid>
      </Card>
    </>
  );
}
