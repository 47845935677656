/***
 *
 *   ACCOUNT
 *   Index page for account functions
 *
 **********/

import React, { useContext } from "react";
import {
  AuthContext,
  Animate,
  Grid,
  Card,
  Icon,
  Link,
  useAPI,
  Loader,
} from "components/lib";

export function Account(props) {
  const context = useContext(AuthContext);
  const user = useAPI("/api/user");
  const iconSize = 20;

  if (user.loading) return <Loader />;

  return (
    <Animate>
      <Grid cols="4">
        <Card>
          <Icon image="user" size={iconSize} />
          <h2 className={Style.subtitle}>Profile</h2>
          <div>Update your profile information</div>
          <Link url="/account/profile" className={Style.link}>
            Update
          </Link>
        </Card>

        <Card>
          <Icon image="lock" size={iconSize} />
          <h2 className={Style.subtitle}>Password</h2>
          <div>
            {user?.data?.["has_password"]
              ? "Change your password"
              : "Create a password"}
          </div>
          <Link url="/account/password" className={Style.link}>
            {user?.data?.["has_password"] ? "Change" : "Create"}
          </Link>
        </Card>

        <Card>
          <Icon image="shield" size={iconSize} />
          <h2 className={Style.subtitle}>Two-Factor Authentication</h2>
          <div>Secure your account</div>
          <Link url="/account/2fa" className={Style.link}>
            {user?.data?.["2fa_enabled"] ? "Manage" : "Enable"}
          </Link>
        </Card>

        {context.permission?.owner && (
          <Card>
            <Icon image="credit-card" size={iconSize} />
            <h2 className={Style.subtitle}>Billing</h2>
            <div>Update your plan or credit card</div>
            <Link url="/account/billing" className={Style.link}>
              Manage
            </Link>
          </Card>
        )}

        {context.permission?.developer && (
          <Card>
            <Icon image="settings" size={iconSize} />
            <h2 className={Style.subtitle}>API Keys</h2>
            <div>Manage your API keys</div>
            <Link url="/account/apikeys" className={Style.link}>
              Manage
            </Link>
          </Card>
        )}
        {context.permission?.admin && (
          <Card>
            <Icon image="link" size={iconSize} />
            <h2 className={Style.subtitle}>Integration</h2>
            <div>Manage your integrations</div>
            <Link url="/account/integrations" className={Style.link}>
              Manage
            </Link>
          </Card>
        )}
        {context.permission?.admin && (
          <Card>
            <Icon image="alert-triangle" size={iconSize} />
            <h2 className={Style.subtitle}>Sanctions</h2>
            <div>Enable Sanctions Check</div>
            <Link url="/account/sanctions" className={Style.link}>
              Manage Sanctions
            </Link>
          </Card>
        )}

        {context.permission?.admin && (
          <Card>
            <Icon image="mail" size={iconSize} />
            <h2 className={Style.subtitle}>Custom Emails</h2>
            <div>Create custom emails</div>
            <Link url="/account/custom-emails" className={Style.link}>
              Manage
            </Link>
          </Card>
        )}

        {context.permission?.admin && (
          <Card>
            <Icon image="bell" size={iconSize} />
            <h2 className={Style.subtitle}>Notification Settings</h2>
            <div>Manage settings</div>
            <Link url="/account/notifications" className={Style.link}>
              Manage
            </Link>
          </Card>
        )}

        {context.permission?.admin && (
          <Card>
            <Icon image="users" size={iconSize} />
            <h2 className={Style.subtitle}>Users</h2>
            <div>Invite users to your account</div>
            <Link url="/account/users" className={Style.link}>
              Add users
            </Link>
          </Card>
        )}

        {context.permission?.admin && (
          <Card>
            <Icon image="trash" size={iconSize} />
            <h2 className={Style.subtitle}>Archive Verfications</h2>
            <div>Remove Verifications</div>
            <Link url="/account/visibility" className={Style.link}>
              Manage
            </Link>
          </Card>
        )}
        {context.permission?.admin && (
          <Card>
            <Icon image="alert-triangle" size={iconSize} />
            <h2 className={Style.subtitle}>Adverse Media</h2>
            <div>Enable Adverse Media Check</div>
            <Link url="/account/adverse-media" className={Style.link}>
              Manage
            </Link>
          </Card>
        )}
        {
          <Card>
            <Icon image="repeat" size={iconSize} />
            <h2 className={Style.subtitle}>Reminders</h2>
            <div>Manage your reminders</div>
            <Link url="/account/reminders" className={Style.link}>
              Manage
            </Link>
          </Card>
        }
        {context.permission?.admin && (
          <Card>
            <Icon image="check-square" size={iconSize} />
            <h2 className={Style.subtitle}>Forms</h2>
            <div>View, Edit and Manage Forms</div>
            <Link url="/account/forms" className={Style.link}>
              Manage
            </Link>
          </Card>
        )}
        {context.permission?.admin && (
          <Card>
            <Icon image="pen-tool" size={iconSize} />
            <h2 className={Style.subtitle}>Post Verification Form</h2>
            <div>Manage settings</div>
            <Link url="/account/post-form" className={Style.link}>
              Manage
            </Link>
          </Card>
        )}
        {context.permission?.admin && (
          <Card>
            <Icon image="sunrise" size={iconSize} />
            <h2 className={Style.subtitle}>Webhooks</h2>
            <div>Send events to your app</div>
            <Link url="/account/webhooks" className={Style.link}>
              Manage
            </Link>
          </Card>
        )}
      </Grid>
    </Animate>
  );
}

const Style = {
  subtitle: "font-bold mt-4",
  link: "text-blue-500 font-semibold mt-3 inline-block",
};
