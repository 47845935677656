import React, { Fragment, useState, useEffect } from "react";
import { SubNav, Row, useAPI, Grid, Card } from "components/lib";
import { useParams } from "react-router-dom";

const displayCaseKeys = {
  jurisdiction: "Jusrisdiction",
  idno: "ID No.",
  firstname: "First Name",
  middlename: "Middle Name",
  lastname: "Last Name",
  race: "Race",
  sex: "Sex",
  dob: "Date of Birth",
  height: "Height",
  weight: "Weight",
  hair_color: "Hair Color",
  eye_color: "Eye Color",
  ssn: "SSN",
  birth_state: "Birth State",
  generation: "Generation",
  city: "City",
  state: "State",
  zip: "Zip",
  age: "Age",
  scars_marks: "Scars/Marks",
  skin_tone: "Skin Tone",
  military_service: "Military Service",
  address: "Address",
  known_alias: "Known Alias",
};

const displayChargeKeys = {
  category: "Category",
  source: "Source",
  charges_filed_date: "Charges Filed Date",
  Offense_Date: "Offense Date",
  Offense_Code: "Offense Code",
  NCIC_Code: "NCIC Code",
  Offense_Desc1: "Offense Description 1",
  Offense_Desc2: "Offense Description 2",
  Counts: "Counts",
  Plea: "Plea",
  Conviction_Date: "Conviction Date",
  Conviction_Place: "Conviction Place",
  Sentence_Date: "Sentence Date",
  Probation_Date: "Probation Date",
  Court: "Court",
  Disposition: "Disposition",
  Disposition_Date: "Disposition Date",
  Court_Costs: "Court Costs",
  Arresting_Agency: "Arresting Agency",
  Case_Type: "Case Type",
  Fines: "Fines",
  source_name: "Source Name",
  source_state: "Source State",
};

export function UsCriminalCheckResultsView(props) {
  const params = useParams();
  const verification = useAPI(`/api/verification/${params.id}`);
  const [navBarItems, setNavBarItems] = useState([]);
  const [usCriminalCheckResults, setUsCriminalCheckResults] = useState("");

  useEffect(() => {
    async function setUpNavBarItems() {
      const verificationInfo = verification.data.verification[0];
      console.log("Setting up nav bar items", verificationInfo);
      const updatedNavBarItems = [];

      if (verificationInfo.sanctions_results) {
        updatedNavBarItems.push(
          {
            label: "KYC",
            link: `/verification/${params.id}`,
          },
          {
            label: "Sanctions",
            link: `/verification/sanctions/${params.id}`,
          }
        );
      }

      if (verificationInfo.adverse_media_results) {
        updatedNavBarItems.push({
          label: "Adverse Media",
          link: `/verification/adverse/${params.id}`,
        });

        if (updatedNavBarItems.length === 1) {
          updatedNavBarItems.push({
            label: "KYC",
            link: `/verification/${params.id}`,
          });
        }
      }

      if (verificationInfo?.additional_checks?.length > 0) {
        updatedNavBarItems.push({
          label: "US Criminal Checks",
          link: `/verification/us-criminal-v1/${params.id}`,
        });
      }

      setNavBarItems(updatedNavBarItems);
    }

    if (!verification.loading && verification.data) {
      setUpNavBarItems();
      setUsCriminalCheckResults(
        verification.data.verification[0].additional_checks[0]
      );
    }
  }, [verification]);

  const getSearchQuery = (url) => {
    console.log("url", url);
    if (!url) return;
    const urlParams = new URLSearchParams(new URL(url).search);
    console.log(urlParams.get("CITY"));
    return (
      "NAME=" +
      (urlParams.get("FNAME") || "") +
      " " +
      (urlParams.get("MNAME") || "") +
      " " +
      (urlParams.get("LNAME") || "") +
      " " +
      ", " +
      (urlParams.get("CITY") || "") +
      ", " +
      (urlParams.get("STATE") || "") +
      ", " +
      (urlParams.get("DOB") || "")
    );
  };

  if (!verification?.data?.verification?.[0]?.type) {
    return <p>Loading...</p>;
  }

  console.log('verification?.data?.[0]?.verification?.type', verification?.data?.verification?.[0]?.type);
  if (verification?.data?.verification?.[0]?.type.includes("us-criminal-v2")) {
    console.log('us-criminal-v2');
    return (
      <Fragment>
        <Grid>
          <Row>
            <SubNav items={navBarItems} />
            <Card>
              <h1>US Criminal Check Results</h1>
              <p>US Criminal Check Results</p>
              {/* <p>
                <strong>Search Input:</strong>{" "}
                {usCriminalCheckResults?.results?.stats?.searchInput ||
                  getSearchQuery(usCriminalCheckResults?.additional_info?.url)}
              </p> */}
              <p>
                <strong>Results:</strong>{" "}
                {usCriminalCheckResults?.results?.meta?.rows || 0}
              </p>
              {/* <p>{JSON.stringify(usCriminalCheckResults)}</p> */}
            </Card>

            {usCriminalCheckResults?.results?.meta?.rows
              ? usCriminalCheckResults?.results?.criminals?.criminal.map(
                  (item, index) => (
                    <Card key={index} className="suspect">
                      <h2>
                        Suspect: {item.suspect.name.firstName}{" "}
                        {item.suspect.name.lastName}
                      </h2>
                      <div>Date of Birth: {item.suspect.DOB}</div>
                      <div>Current Age: {item.suspect.currentAge}</div>
                      {item.crimeDetailsRecords && (
                        <Card>
                          <h3>Crime Details:</h3>
                          {Array.isArray(
                            item?.crimeDetailsRecords?.crimeDetails
                          ) ? (
                            item?.crimeDetailsRecords?.crimeDetails.map(
                              (crime, crimeIndex) => (
                                <Card key={crimeIndex} className="crime-detail">
                                  <div>Case Number: {crime.caseNumber}</div>
                                  <div>Crime Type: {crime.crimeType}</div>
                                  <div>
                                    Offense Description:{" "}
                                    {crime.offenseDescription1}
                                  </div>
                                  <div>Disposition: {crime.disposition}</div>
                                  <div>
                                    Disposition Date: {crime.dispositionDate}
                                  </div>
                                  <div>Court: {crime.court}</div>
                                </Card>
                              )
                            )
                          ) : (
                            <div className="crime-detail">
                              <div>
                                Case Number:{" "}
                                {
                                  item?.crimeDetailsRecords?.crimeDetails
                                    .caseNumber
                                }
                              </div>
                              <div>
                                Crime Type:{" "}
                                {
                                  item?.crimeDetailsRecords?.crimeDetails
                                    .crimeType
                                }
                              </div>
                              <div>
                                Offense Description:{" "}
                                {
                                  item?.crimeDetailsRecords?.crimeDetails
                                    .offenseDescription1
                                }
                              </div>
                              <div>
                                Disposition:{" "}
                                {
                                  item?.crimeDetailsRecords?.crimeDetails
                                    .disposition
                                }
                              </div>
                              <div>
                                Disposition Date:{" "}
                                {
                                  item?.crimeDetailsRecords?.crimeDetails
                                    .dispositionDate
                                }
                              </div>
                              <div>
                                Court:{" "}
                                {item?.crimeDetailsRecords?.crimeDetails.court}
                              </div>
                            </div>
                          )}
                        </Card>
                      )}
                      {item.warrantDetailRecords && (
                        <div>
                          <h3>Warrant Details:</h3>
                          {/* Mapping through warrant details if available */}
                        </div>
                      )}
                    </Card>
                  )
                )
              : "No results found"}
          </Row>
        </Grid>
        {/* <DataDisplay data={usCriminalCheckResults?.results?.person} /> */}
      </Fragment>
    );
  } 

  return (
    <Fragment>
      <Grid>
        <Row>
          <SubNav items={navBarItems} />
          <div>
            {/* <h1>US Criminal Check Results</h1> */}
            {/* <p>US Criminal Check Results</p> */}
            <p>
              <strong>Search Input:</strong>{" "}
              {usCriminalCheckResults?.results?.stats?.searchInput ||
                getSearchQuery(usCriminalCheckResults?.additional_info?.url)}
            </p>
            <p>
              <strong>Results:</strong>{" "}
              {usCriminalCheckResults?.results?.stats?.rows || 0}
            </p>
            {/* <p>{JSON.stringify(usCriminalCheckResults)}</p> */}
          </div>
        </Row>
      </Grid>
      <DataDisplay data={usCriminalCheckResults?.results?.person} />
    </Fragment>
  );
}

const DataDisplay = ({ data }) => {
  console.log("DataDisplay", data);
  if (!data)
    return (
      <>
        <p>No results</p>
      </>
    );
  
  // case when data is an object i.e. only  1 result
  if (!data.length) {
    return (
      <Grid cols={1}>
        <Row title={"Result 1"}>
          <Card>
            <h2>Case Details</h2>
            <ul>
              {Object.entries(data.Case_Details).map(
                ([key, value]) =>
                  value && (
                    <li key={key}>
                      <strong>{displayCaseKeys?.[key] || key}</strong>: {value}
                    </li>
                  )
              )}
            </ul>
          </Card>
          <Card>
            <h2>Charge Details</h2>
            <ul>
              {Object.entries(data.Charge_Details).map(
                ([key, value]) =>
                  value && (
                    <li key={key}>
                      <strong>{displayChargeKeys?.[key] || key}</strong>:{" "}
                      {value}
                    </li>
                  )
              )}
            </ul>
          </Card>
        </Row>
      </Grid>
    );
  }
  return (
    <Grid cols={2}>
      {data.map((item, index) => (
        <Row title={"Result " + Number(index + 1)} key={index}>
          <Card>
            <h2>Case Details</h2>
            <ul>
              {Object.entries(item.Case_Details).map(
                ([key, value]) =>
                  value && (
                    <li key={key}>
                      <strong>{displayCaseKeys?.[key] || key}</strong>: {value}
                    </li>
                  )
              )}
            </ul>
          </Card>
          <Card>
            <h2>Charge Details</h2>
            <ul>
              {Object.entries(item.Charge_Details).map(
                ([key, value]) =>
                  value && (
                    <li key={key}>
                      <strong>{displayChargeKeys?.[key] || key}</strong>:{" "}
                      {value}
                    </li>
                  )
              )}
            </ul>
          </Card>
        </Row>
      ))}
    </Grid>
  );
};
