import { Home } from "views/website/home";
import { Signin } from "views/auth/signin";
import { Contact } from "views/website/contact";
import { Pricing } from "views/website/pricing";
import { Terms } from "views/website/terms";
import { Privacy } from "views/website/privacy";
import { Verification } from "views/verification/page";
import { EULA } from "views/website/eula";
import { DocsPublicView } from "views/website/docs";
import { FinishVerification } from "views/website/finish";
import { ResponseView } from "views/verification/response";
import { PreVerificationFormView } from "views/verification/form";
import { ReportRequested } from "views/website/report-requested";
import { PreHsResponseFormView } from "views/verification/pre-hs-response";
import { HsResponseFormView } from "views/verification/hs-response";

const Routes = [
  {
    path: "/",
    view: Signin,
    layout: "auth",
    title: "IdentityCheck",
  },
  {
    path: "/contact",
    view: Contact,
    layout: "home",
    title: "Contact",
  },
  {
    path: "/pricing",
    view: Pricing,
    layout: "home",
    title: "Pricing",
  },
  {
    path: "/pp",
    view: Privacy,
    layout: "home",
    title: "Privacy Policy",
  },
  {
    path: "/tos",
    view: Terms,
    layout: "home",
    title: "Terms & Conditions",
  },
  {
    path: "/public/verification/:id",
    view: Verification,
    layout: "onboarding",
    title: "Verification",
  },
  {
    path: "/public/response/:id",
    view: ResponseView,
    layout: "response",
    title: "Form Response",
  },
  {
    path: "/public/pre_hs/:id",
    view: PreHsResponseFormView,
    layout: "preResponse",
    title: "Pre HubSpot Form",
  },
  {
    path: "/public/hs/:id",
    view:  HsResponseFormView,
    layout: "preResponse",
    title: "Pre HubSpot Form",
  },
  {
    path: "/onboarding/:id",
    view: PreVerificationFormView,
    layout: "onboarding",
    title: "Onboarding Form",
  },
  {
    path: "/eula",
    view: EULA,
    layout: "home",
    title: "End User Licence Agreement",
  },
  {
    path: "/integrations/documentation",
    view: DocsPublicView,
    layout: "home",
    title: "Docs",
  },
  {
    path: "/finish",
    view: FinishVerification,
    layout: "onboarding",
    title: "Finish Verification",
  },
  {
    path: "/report-requested",
    view: ReportRequested,
    layout: "onboarding",
    title: "Verification Report is being generated",
  }
];

export default Routes;
