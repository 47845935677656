import React, { Fragment, useMemo, useState, useContext } from "react";
import {
  Form,
  Button,
  Loader,
  Card,
  Row,
  AccountNav,
  useNavigate,
  AuthContext,
  useAPI,
  Grid,
} from "components/lib";
import MaterialReactTable from "material-react-table";
import { Box } from "@mui/material";

const Axios = require("axios");

export function VisibilityView(props) {
  const navigate = useNavigate();
  const [rowSelection, setRowSelection] = useState({});
  const authContext = useContext(AuthContext);

  const columns = useMemo(
    () => [
      {
        accessorKey: "first_name", //access nested data with dot notation
        header: "First Name",
      },
      {
        accessorKey: "last_name",
        header: "Last Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "result",
        header: "result",
      },
      {
        accessorFn: (row) => {
          const date = new Date(row.created_at);
          return `${date.getMonth() + 1}-${date.getFullYear()}`;
        },
        id: "month",
        header: "Month",
      },
      {
        accessorFn: (row) => {
          const date = new Date(row.created_at);
          return `${date.getDate()}-${
            date.getMonth() + 1
          }-${date.getFullYear()}`;
        },
        id: "date",
        header: "Date",
      },
    ],
    []
  );

  const hide = async () => {
    console.log("hide", rowSelection);
    const ids = Object.keys(rowSelection);
    if (ids.length === 0) return;
    await Axios.patch("/api/verification/visibility", { ids: ids });
    window.location.reload();
  };

  const verifications = useAPI("/api/verification");
  return (
    <Fragment>
      <AccountNav />
      <Grid>
        <Row>
          {verifications?.data?.verification && (
            // <p>hi</p>
            <>
              {console.log("in here", verifications?.data?.verification)}
              <MaterialReactTable
                title="Verifications"
                // theme={theme}
                data={verifications?.data?.verification}
                columns={columns}
                enableRowActions
                enableGrouping
                onRowSelectionChange={setRowSelection}
                state={{ rowSelection }}
                initialState={{
                  columnVisibility: { month: false, date: false },
                }}
                positionActionsColumn="last"
                getRowId={(originalRow) => originalRow.id}
                // renderRowActions={(row, index) => [
                //   <Button
                //     action={(row) => {
                //       console.info("View", row);
                //       navigate("/verification/" + row.row.original.id);
                //     }}
                //     params={row}
                //     text="Hide"
                //   />,
                // ]}
                positionToolbarAlertBanner="bottom"
                enableRowSelection={(r) => r.id}
                renderTopToolbarCustomActions={({ table }) => (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      p: "0.5rem",
                      flexWrap: "wrap",
                      align: "right",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <Button
                      action={hide}
                      text="Archive"
                      icon=""
                      small
                      key="hide"
                    />
                  </Box>
                )}
              />
            </>
          )}
        </Row>
      </Grid>
    </Fragment>
  );
}
