import { Dashboard } from "views/dashboard/dashboard";
import { Help } from "views/dashboard/help";
import { OnboardingView } from "views/onboarding/onboarding";
import { VerificationView } from "views/verification/verification";
import { LandingView } from "views/landing/landing";
import { DirectVerification } from "views/verification/direct-verification";
import { SanctionsView } from "views/verification/sanctions";
import { AdverseMediaView } from "views/verification/adverse";
import { AdminResponseView } from "views/verification/admin-response";
import { UsCriminalCheckResultsView } from "views/verification/us-criminal";
import { ReferralView } from "views/dashboard/referral";

const Routes = [
  {
    path: "/dashboard",
    view: Dashboard,
    layout: "app",
    permission: "user",
    title: "Dashboard",
  },
  {
    path: "/welcome",
    view: OnboardingView,
    layout: "onboarding",
    permission: "user",
    title: "Welcome",
  },
  {
    path: "/help",
    view: Help,
    layout: "app",
    permission: "user",
    title: "Get Help",
  },
  {
    path: "/refer",
    view: ReferralView,
    layout: "app",
    permission: "user",
    title: "Refer a friend",
  },
  {
    path: "/verification/:id",
    view: VerificationView,
    layout: "app",
    permission: "user",
    title: "Verification view",
  },
  {
    path: "/verification/sanctions/:id",
    view: SanctionsView,
    layout: "app",
    permission: "user",
    title: "Sanctions view",
  },
  {
    path: "/direct-verification",
    view: DirectVerification,
    layout: "app",
    permission: "user",
    title: "Direct Verification",
  },
  {
    path: "/landing",
    view: LandingView,
    layout: "app",
    permission: "user",
    title: "Dashboard",
  },
  {
    path: "/verification/adverse/:id",
    view: AdverseMediaView,
    layout: "app",
    permission: "user",
    title: "Adverse Media Results",
  },
  {
    path: "/verification/us-criminal-v1/:id",
    view: UsCriminalCheckResultsView,
    layout: "app",
    permission: "user",
    title: "US Criminal Check Results",
  },
  {
    path: "/verification/:verification_id/response/:id",
    view: AdminResponseView,
    layout: "app",
    permission: "user",
    title: "Verification Response",
  },
];

export default Routes;
