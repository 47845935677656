import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  Row,
  Card,
  AccountNav,
  Button,
  AuthContext,
  Grid,
  useAPI,
} from "components/lib";
import "survey-core/defaultV2.min.css";
import { StylesManager, Model, FunctionFactory } from "survey-core";
import { Survey } from "survey-react-ui";
const Axios = require("axios");

export function WebhooksView(props) {
  const [enabled, setEnabled] = useState(false);
  const portalUrl = useAPI("/api/webhook", "POST");
  const webhookIntegrationSettings = useAPI("/api/integration/webhook");

  useEffect(() => {
    if (
      webhookIntegrationSettings.data !== undefined &&
      webhookIntegrationSettings?.data?.length > 0
    ) {
      setEnabled(webhookIntegrationSettings?.data?.[0]?.enabled);
    }
  }, [webhookIntegrationSettings.data]);

  const json = {
    completedHtml: "<h3>Your changes have been saved.</h3>",
    completeText: "Save",
    pages: [
      {
        name: "Enable Webhooks",
        completeText: "Save",
        elements: [
          {
            type: "boolean",
            name: "enabled",
            title: "Enable Webhooks",
            isRequired: true,
          },
        ],
      },
    ],
    showQuestionNumbers: "off",
  };

  const survey = new Model(json);

  const goToPortal = () => {
    console.log("go to portal");
    window.open(portalUrl.data.url, "_blank");
  };

  useContext(AuthContext);

  function setExistingWebhookSettings() {
    console.log("setExistingWebhookSettings", webhookIntegrationSettings);
    survey.setValue("enabled", enabled);
  }

  async function saveSurveyResults(url, json) {
    console.log("saveSurveyResults", url, json);
    const response = await Axios.post(url, json);
  }

  const webhookConfigComplete = useCallback((sender) => {
    setEnabled(sender.data.enabled);
    saveSurveyResults("/api/integration/webhook", sender.data);
  });

  useContext(AuthContext);

  setExistingWebhookSettings();
  survey.onComplete.add(webhookConfigComplete);

  if (portalUrl.loading || portalUrl.data === undefined) {
    return (
      <>
        <h1>Webhooks</h1>
        <p>Loading...</p>
      </>
    );
  }

  return (
    <>
      <AccountNav />
      <Grid cols="2">
        <Row>
          <Card>
            <Survey model={survey} />
          </Card>
        </Row>
        {/* show button if webhook integration is enabled */}
        <Row>
          <Card>
            {enabled && (
              <>
                <p>
                  You will be able to add endpoints, access logs, and configure
                  your webhook settings.
                </p>
                <Button text="Go to Portal" action={goToPortal} />
              </>
            )}
            {!enabled && (
              <p>
                Webhooks are disabled. Please enable them to access the portal.
                Then you will be able to add endpoints, access logs, and
                configure your webhook settings.
              </p>
            )}
          </Card>
        </Row>
      </Grid>
    </>
  );
}
