/***
 *
 *   Response LAYOUT
 *   Simple layout to focus on user response screens
 *
 **********/

import React, { useEffect } from "react";
import Style from "./pre-response.module.scss";

export function PreResponseLayout(props) {
  useEffect(() => {
    document.body.classList.add("color");
    return () => {
      document.body.classList.remove("color");
    };
  }, []);

  return (
    <main className={Style.campaign}>{<props.children {...props.data} />}</main>
  );
}
