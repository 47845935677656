import { SetupOnboarding } from "views/setup/onboarding";

const Routes = [
  {
    path: "/setup",
    view: SetupOnboarding,
    layout: "onboarding",
    title: "Welcome to Identity Check",
  },
];

export default Routes;
