import React from "react";
import { Form, useAPI, Card } from "components/lib";

export function ReferralView() {
  const [success, setSuccess] = React.useState(false);

  return (
    <div>
      <div title="Refer a friend" className="w-1/2 bg-slate-100">
        <Form
          data={{
            name: {
              type: "text",
              required: true,
              errorMessage:
                "Please enter the name of the person you want to refer",
              label: "Name of the person you want to refer",
            },
            email: {
              type: "email",
              required: true,
              errorMessage: "Please enter a valid email address",
              label: "Email Address of the person you want to refer",
            },
            organization: {
              type: "text",
              required: true,
              errorMessage: "Please enter the name of the organization",
              label: "Organization/Company Name",
            },
            message: {
              type: "textarea",
              label: "Any other details you want to add?",
              errorMessage: "Please enter a message",
            },
          }}
          buttonText="Refer"
          method="POST"
          url="/api/utility/refer"
          callback={() => setSuccess(true)}
        />
      </div>
      {success && (
        <div>
          <br />
          <br />
          <Card title="Success" className="w-1/2 bg-slate-100 pt-2">
            <p>Your referral has been sent successfully</p>
          </Card>
        </div>
      )}

      <div>
        <br />
        <Card className="w-1/2 bg-slate-100 pt-2">
        <p>
          <strong>Refer a Friend - Terms and Conditions</strong>
        </p>
        <ol>
          <li>
            <strong>Referral Reward</strong>: You will receive a US$50 credit
            applied to your account once the person you referred successfully
            subscribes to our service. This credit will be deducted from your
            next bill(s) at our discretion.
          </li>
          <li>
            <strong>Eligibility</strong>: The referral program is only
            applicable for referring new customers who have not previously been
            in contact with us or are currently in our database.
          </li>
          <li>
            <strong>Qualified Referrals</strong>: A referral is only considered
            successful if the person you referred subscribes to our service and
            completes the payment. Only confirmed subscriptions qualify for the
            reward.
          </li>
          <li>
            <strong>Credit Application</strong>: The $50 credit can be applied
            to your future bills and is non-transferable. The manner and timing
            of the credit application will be at our discretion.
          </li>
          <li>
            <strong>Limitations</strong>: Each customer is limited to one reward
            per new customer referral. Multiple referrals of the same individual
            will not result in additional credit.
          </li>
          <li>
            <strong>Referral Method</strong>: The referral is only valid if made
            using the form above.
          </li>
          <li>
            <strong>Expiration:</strong> Referrals expire after 12 months.
          </li>
          <li>
            <strong>Program Changes</strong>: We reserve the right to change or
            end this referral program at any time, without prior notice.
          </li>
        </ol>
      </Card>
      </div>
    </div>
  );
}
