import React, { Fragment, useEffect, useState, useCallback, useContext} from "react";
import { useAPI, ViewContext } from "components/lib";
import Axios from "axios";
import { StylesManager, Model, QuestionTagboxModel } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";

StylesManager.applyTheme("defaultV2");

export function PipedriveSettingsView(props) {
  const exstingSettingsResponse = useAPI("/api/integration/pipedrive/settings");
  const [loading, setLoading] = useState(true);
  const env = process.env.NODE_ENV;
  const context = useContext(ViewContext);
  const navigateToUrl =
    env === "production"
      ? `https://identity.stackgo.io/account`
      : `http://localhost:3000/account`;

  // function setExistingPipedriveSettings() {
  //   if (
  //     exstingSettingsResponse.loading === false &&
  //     exstingSettingsResponse.data !== null
  //   ) {
  //     const settings = exstingSettingsResponse?.data?.[0];
  //     console.log("settings", settings);
  //     survey.setValue("enabled", settings?.enabled);
  //   }
  // }

  const ufi = new URLSearchParams(window.location.search).get("ufi");

  useEffect(async () => {
    // await setExistingPipedriveSettings();

    if (!ufi) {
      console.log("no ufi");
    } else {
      // send to rename backend
      console.log("ufi", ufi);
      const updates = Axios.post("/api/integration/pipedrive/rename", {
        ufi: ufi,
      });
      console.log("ufi updated", updates.data);
    }

    setLoading(false);
  }, [ufi]);

  // useEffect(() => {
  //   console.log('in effect', exstingSettingsResponse)
  //   if (
  //     exstingSettingsResponse.loading === false &&
  //     exstingSettingsResponse.data !== null
  //   ) {
  //     const settings = exstingSettingsResponse?.data?.[0];
  //     console.log("settings1", settings);
  //     survey.setValue("enabled", settings?.enabled);
  //   } 
  // }, [exstingSettingsResponse]);

  // useEffect(() => {
  //   if(!loading)  {
  //     console.log('in effect2', exstingSettingsResponse)
  //     survey.setValue("enabled", exstingSettingsResponse?.data?.[0]?.enabled);  
  //   }
  // },[loading])
  // surveyjs json
  const json = {
    completedHtml: "<h3>Please wait, your changes are being saved...</h3>",
    showQuestionNumbers: "off",
    pages: [
      {
        name: "Manage your Pipedrive integration",
        elements: [
          {
            type: "boolean",
            name: "enabled",
            title: "Enable Pipedrive integration",
            isRequired: true,
          },
        ],
      },
    ],
  };

  // json.navigateToUrl = navigateToUrl;

  async function saveSurveyResults(url, json) {
    console.log("saveSettings", url, json);
    const response = await Axios.post(url, json);
    window.location.reload();
   }

  const pipedriveSettingsComplete = useCallback((sender) => {
    saveSurveyResults("/api/integration/pipedrive/settings", sender.data);
  });

  // if hubspot is not connected - return to integrations page
  // if (xeroConnected.loading === false && xeroConnected.data === false) {
  //   window.location = "/account/integrations";
  // }

  const survey = new Model(json);
  survey.onComplete.add(pipedriveSettingsComplete);
  survey.setValue("enabled", exstingSettingsResponse?.data?.[0]?.enabled); 

  // setExistingPipedriveSettings();

  if (loading) return <div>Loading...</div>;

  return (
    <Fragment>
      <Survey model={survey} />
    </Fragment>
  );
}
