import React, { Fragment } from "react";
import {
  Form,
  Button,
  Loader,
  Card,
  Row,
  AccountNav,
  useAPI,
  Grid,
} from "components/lib";
const Axios = require("axios");

export function SanctionsView(props) {
  const accountInfo = useAPI("/api/account");

  const formData = {
    header: {
      title: "Sanctions Settings",
      type: "header",
    },
    sanctionsSources: {
      label: "Sanctions Sources",
      type: "radio",
      required: true,
      options: ["OFAC only", "All"],
      default: accountInfo?.data?.sanctions_settings?.sanctionsSources,
    },
    monitoring: {
      label: "Monitoring (check for sanctions on all every 30 days). Additional charges may apply.",
      type: "switch",
      default: accountInfo?.data?.sanctions_settings?.monitoring,
    },
  };
  const toggleSanctions = async () => {
    await Axios.post("/api/account/sanctions", {
      sanctions_enabled: !accountInfo?.data?.sanctions_enabled,
    });
    window.location.reload(false);
  };

  if (!accountInfo.data) {
    return <Loader />;
  }

  return (
    <Fragment>
      <AccountNav />
      <Grid>
        <Row>
          <h1>Toggle Sanctions</h1>
          <p className="text-xs leading-6">
            Current Status:{" "}
            {accountInfo?.data?.sanctions_enabled ? "Enabled" : "Disabled"}
            <Button text="Toggle" small action={toggleSanctions}></Button>
            <p className="text-xs leading-6">
              Please refer to the <a href="https://identity.stackgo.io/account/billing">billing</a> page for more details on Adverse Media costs.
            </p>
          </p>
        </Row>
        <Row>
          <Card restrictWidth>
            <Form
              data={formData}
              url="/api/account/sanctions/select"
              method="post"
              buttonText="Save"
            />
          </Card>
        </Row>
      </Grid>
    </Fragment>
  );
}

// To Dos
// Enable Sanctions
// Y/N
// Save to account
// toggle state to active
// Select sources
// Render a form
// single ofac checkbox
// Fix backend
// update backend to use the data in accout info on checks
