/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners coming from Xero
 *   Step 1: create account
 *   Step 2: select plan and make payment
 *
 **********/

import React, { useContext } from "react";
import {
  AuthContext,
  Animate,
  Row,
  Card,
  SocialSignin,
  Form,
  Link,
} from "components/lib";
import { useLocation } from "react-router-dom";

import { Helmet } from "react-helmet";
import "./account.scss";

export function SignupXero(props) {
  const context = useContext(AuthContext);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  return (
    <Animate type="pop">
      <Helmet>
        <meta
          name="description"
          content="Create your free IdentityCheck account to get started. Identity Checks Anywhere."
        ></meta>
        <meta name="title" content="Sign Up for IdentityCheck"></meta>
      </Helmet>
      <Row title="Create Your Account">
        <div className="img-wrapper">
          {/* <img src={logoImg} className='img-logo' alt=''/> */}
        </div>
        <Card loading={false} restrictWidth center>
          <SocialSignin network={["facebook", "twitter"]} showOr signup />

          <Form
            data={{
              name: {
                label: "First Name",
                type: "text",
                required: true,
                errorMessage: "Please enter your first name",
                value: query.get("name") || "",
              },
              email: {
                label: "Email",
                type: "email",
                required: true,
                value: query.get("email") || "",
              },
              password: {
                label: "Password",
                type: "password",
                required: true,
                complexPassword: true,
              },
              confirm_password: {
                type: "hidden",
                value: null,
              },
              plan: {
                type: "hidden",
                value: "free",
              },
            }}
            url="/api/account"
            method="POST"
            buttonText="Create Account"
            callback={context.xeroSignUp}
          />

          <div className="mt-4">
            Already registered? <Link url="/signin" text="Sign In" />
          </div>
        </Card>
      </Row>
    </Animate>
  );
}
