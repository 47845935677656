import React, { Fragment } from "react";
import {
  Form,
  Button,
  Loader,
  Card,
  Row,
  AccountNav,
  useAPI,
  Grid,
} from "components/lib";
const Axios = require("axios");

export function AdverseMediaView(props) {
  const accountInfo = useAPI("/api/account");

  if (!accountInfo.data) {
    return <Loader />;
  }

  const toggleAdverseMedia = async () => {
    await Axios.post("/api/account/adverse-media", {
      adverse_media_enabled: !accountInfo?.data?.adverse_media_enabled,
    });
    window.location.reload(false);
  };

  return (
    <Fragment>
      <AccountNav />
      <Grid>
        <Row>
          <h1>Toggle Adverse Media</h1>
          <p className="text-xs leading-6">
            Current Status:{" "}
            {accountInfo?.data?.adverse_media_enabled ? "Enabled" : "Disabled"}
            <Button text="Toggle" small action={toggleAdverseMedia}></Button>
          </p>
          <p className="text-xs leading-6">
            Please note, there are additional charges for Sanctions of $0.50 per
            check. By clicking Save you are acknowledging this additional
            charge. Please to your Account Manager for more details.
          </p>
        </Row>
      </Grid>
    </Fragment>
  );
}

// To Dos
// Enable Sanctions
// Y/N
// Save to account
// toggle state to active
// Select sources
// Render a form
// single ofac checkbox
// Fix backend
// update backend to use the data in accout info on checks
