import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
  Grid,
  Card,
  useAPI,
  Button,
  Image,
  Loader,
  ViewContext,
  AuthContext,
} from "components/lib";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { StylesManager, Model, QuestionTagboxModel } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";

StylesManager.applyTheme("defaultV2");

export function XpmPreVerificationFormSettings(props) {
  // get existing settings
  const params = useParams();
  const id = params.id;
  const existingPreVerificationFormSettings = useAPI(
    "/api/pre-verification-settings/" + id
  );
  const [form, setForm] = useState(null);
  const [formFields, setFormFields] = useState([]);
  const [selectedForm, setSelectedForm] = useState("");

  function setExistingReminderSettings() {
    if (
      existingPreVerificationFormSettings.loading === false &&
      existingPreVerificationFormSettings.data !== null
    ) {
      const settings = existingPreVerificationFormSettings?.data?.[0];
      model.setValue("enabled", settings?.enabled);
      model.setValue("action", settings?.data?.action);
      model.setValue("notificationEmail", settings?.data?.notificationEmail);
      model.setValue("redirectToVerification", settings?.data?.redirectToVerification);
      model.setValue("verifyIndividualIdentity", settings?.data?.verifyIndividualIdentity);
      model.setValue("email", settings?.data?.email);
      model.setValue("namePattern", settings?.data?.namePattern);
      model.setValue("taxAgent", settings?.data?.taxAgent);
      model.setValue("xpmMapping", settings?.data?.xpmMapping);
    }
  }

  useEffect(async () => {
    if (
      existingPreVerificationFormSettings.loading === false &&
      existingPreVerificationFormSettings.data !== null
    ) {
      console.log("formSettingsAPI", existingPreVerificationFormSettings.data);
      // get form id
      const formId =
        existingPreVerificationFormSettings?.data?.[0]?.misc?.form_id;
      // get form settings
      const formResponse = await Axios.get("/api/form/" + formId);
      console.log("formResponse", formResponse.data);
      setForm(formResponse.data.data[0]);
    }
  }, [existingPreVerificationFormSettings]);

  useEffect(() => {
    if (form === null) {
      return;
    }
    // get it written as survey model
    const model = new Model(form.data);
    const fields = model.getAllQuestions();
    console.log("fields", fields);

    // get all fields from the pages
    const formFields = fields.map((field) => {
      return {
        value: field.name,
        text: field.title,
      };
    });
    setFormFields(formFields);
  }, [form]);

  const json = {
    // title: "XPM Pre Verification Form Settings",
    logoPosition: "right",
    pages: [
      {
        name: "page1",
        elements: [
          {
            type: "boolean",
            name: "enabled",
            title: "Enabled",
          },
          {
            type: "radiogroup",
            name: "action",
            title: "Action",
            isRequired: true,
            choices: [
              {
                value: "insert",
                text: "Insert",
              },
              {
                value: "upsert",
                text: "Upsert (based on email)",
              },
            ],
          },
          {
            type: "boolean",
            name: "verifyIndividualIdentity",
            title: "Verify Individual Identity",
            isRequired: true,
          },
          {
            type: "boolean",
            name: "redirectToVerification",
            visibleIf: "{verifyIndividualIdentity} = true",
            title: "Redirect to verification",
            isRequired: true,
            requiredIf: "{verifyIndividualIdentity} = true",
          },
          {
            type: "text",
            name: "notificationEmail",
            title: "Notification Email",
          },
          {
            type: "tagbox",
            name: "email",
            title: "Customer Email - used for upsert - last 30 days clients scanned",
            choices: formFields,
            isRequired: true,
            maxSelectedChoices: 1,
          },
          {
            type: "text",
            name: "namePattern",
            title: "Name Pattern",
          },
          {
            type: "text",
            name: "taxAgent",
            title: "Tax Agent",
          },
          {
            type: "matrixdropdown",
            name: "xpmMapping",
            title: "XPM field mapping",
            isRequired: true,
            columns: [
              {
                name: "fields",
                title: "Form Field",
              },
            ],
            choices: formFields,
            rows: [
              {
                value: "entityName",
                text: "Entity Name",
              },
              {
                value: "title",
                text: "Title",
              },
              {
                value: "firstName",
                text: "First Name",
              },
              {
                value: "middleName",
                text: "Middle Name",
              },
              {
                value: "lastName",
                text: "Last Name"
              },
              {
                value: "gender",
                text: "Gender",
              },
              {
                value: "dob",
                text: "Date of Brith",
              },
              {
                value: "phone",
                text: "Phone",
              },
              {
                value: "streetAddress",
                text: "Street",
              },
              {
                value: "town",
                text: "Town",
              },
              {
                value: "state",
                text: "State",
              },
              {
                value: "country",
                text: "Country",
              },
              {
                value: "postCode",
                text: "Post Code",
              },
              {
                value: "postalStreetAddress",
                text: "Postal Street Address",
              },
              {
                value: "postalTown",
                text: "Postal Town",
              },
              {
                value: "postalState",
                text: "Postal State",
              },
              {
                value: "postalPostCode",
                text: "Postal Code Code",
              },
              {
                value: "postalCountry",
                text: "Postal Country",
              },
              {
                value: "accountName",
                text: "Account Name",
              },
              {
                value: "bsb",
                text: "BSB Number",
              },
              {
                value: "accountNumber",
                text: "Account Number",
              },
              {
                value: "tfn",
                text: "Tax File Number",
              },
              {
                value: "abn",
                text: "Australian Business Number (ABN)",
              },
              {
                value: "spousePanel",
                text: "Spouse Panel",
              },
              {
                value: "childrenPanel",
                text: "Children Panel",
              }
            ],
          },
        ],
      },
    ],
  };

  json.navigateToUrl = '/account/pre-verification/xpm';

  async function saveSurveyResults(url, json) {
    console.log("saveSettings", url, json);
    const response = await Axios.patch(url, json);
  }

  const writeBackSettingsComplete = useCallback((sender) => {
    saveSurveyResults("/api/pre-verification-settings/" + id, sender.data);
  });

  if (
    existingPreVerificationFormSettings.loading === true ||
    existingPreVerificationFormSettings?.data === null
  ) {
    return <Loader />;
  }

  if (form === null) {
    return <Loader />;
  }

  const model = new Model(json);
  model.onComplete.add(writeBackSettingsComplete);


  setExistingReminderSettings();
  return (
    <Fragment>
      {/* Check if enabled - give link else hide */}
      <Survey model={model} />
    </Fragment>
  );
}
