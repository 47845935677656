/***
 *
 *   PRIVACY POLICY
 *   Boilerplate privacy statement – replace the values
 *   inside [] with your own text
 *
 **********/

import React from "react";
import { Article, List } from "components/lib";
import { Helmet } from "react-helmet";

export function Privacy(props) {
  return (
    <Article>
      <Helmet>
        <meta
          name="description"
          content="IdentityCheck treats your Privacy with care. Read our Privacy Policy."
        ></meta>
        <meta name="title" content="IdentityCheck Privacy Policy"></meta>
      </Helmet>
      <div>
        <p></p>
        <h1>
          <b>PRIVACY POLICY</b>
        </h1>
        <p>
          This Privacy Policy sets out our commitment to protecting the privacy
          of personal information provided to us, or otherwise collected by us,
          offline or online, including through our website (<b>Site</b>). In
          this Privacy Policy <b>we</b>, <b>us</b> or <b>our</b> means StackGo
          Pty Ltd ABN 74 644 421 518
        </p>
        <p></p>
        <h3>
          <b>Personal information</b>
        </h3>
        <p>
          The types of personal information we may collect about you include:
        </p>
        <ul>
          <li>your name;</li>
          <li>
            your contact details, including email address, mailing address,
            street address and/or telephone number;
          </li>
          <li>your credit card details;</li>
          <li>your demographic information, such as postcode;</li>
          <li>your preferences and/or opinions;</li>
          <li>information you provide to us through customer surveys;</li>
          <li>
            details of products and services we have provided to you and/or that
            you have enquired about, and our response to you;
          </li>
          <li>
            your browser session and geo-location data, device and network
            information, statistics on page views and sessions, acquisition
            sources, search queries and/or browsing behaviour;
          </li>
          <li>
            information about your access and use of our Site, including through
            the use of Internet cookies, your communications with our Site, the
            type of browser you are using, the type of operating system you are
            using and the domain name of your Internet service provider;
          </li>
          <li>
            additional personal information that you provide to us, directly or
            indirectly, through your use of our Site, associated applications,
            associated social media platforms and/or accounts from which you
            permit us to collect information; and
          </li>
          <li>
            any other personal information requested by us and/or provided by
            you or a third party.
          </li>
        </ul>
        <p>
          We may collect these types of personal information directly from you
          or from third parties.
        </p>
        <p></p>
        <h3>
          <b>Collection and use of personal information</b>
        </h3>
        <p>
          We may collect, hold, use and disclose personal information for the
          following purposes:
        </p>
        <ul>
          <li>
            to enable you to access and use our Site, associated applications
            and associated social media platforms;
          </li>
          <li>to contact and communicate with you;</li>
          <li>for internal record keeping and administrative purposes;</li>
          <li>
            for analytics, market research and business development, including
            to operate and improve our Site, associated applications and
            associated social media platforms;
          </li>
          <li>to run competitions and/or offer additional benefits to you;</li>
          <li>
            for advertising and marketing, including to send you promotional
            information about our products and services and information about
            third parties that we consider may be of interest to you;
          </li>
          <li>
            to comply with our legal obligations and resolve any disputes that
            we may have; and
          </li>
          <li>to consider your employment application.</li>
        </ul>
        <h3>
          <b>&nbsp;</b>
        </h3>
        <h3>
          <b>Disclosure of personal information to third parties</b>
        </h3>
        <p>We may disclose personal information to:</p>
        <ul>
          <li>
            third party service providers for the purpose of enabling them to
            provide their services, including (without limitation) IT service
            providers, data storage, web-hosting and server providers, debt
            collectors, maintenance or problem-solving providers, marketing or
            advertising providers, professional advisors and payment systems
            operators;
          </li>
          <li>our employees, contractors and/or related entities;</li>
          <li>our existing or potential agents or business partners;</li>
          <li>sponsors or promoters of any competition we run;</li>
          <li>
            anyone to whom our business or assets (or any part of them) are, or
            may (in good faith) be, transferred;
          </li>
          <li>
            credit reporting agencies, courts, tribunals and regulatory
            authorities, in the event you fail to pay for goods or services we
            have provided to you;
          </li>
          <li>
            courts, tribunals, regulatory authorities and law enforcement
            officers, as required by law, in connection with any actual or
            prospective legal proceedings, or in order to establish, exercise or
            defend our legal rights; [and]
          </li>
          <li>
            third parties, including agents or sub-contractors, who assist us in
            providing information, products, services or direct marketing to
            you. This may include parties located, or that store data, outside
            of Australia; and
          </li>
          <li>
            third parties to collect and process data, such as Google Analytics
            or other relevant businesses. This may include parties that store
            data outside of Australia.
          </li>
        </ul>
        <p>
          By providing us with personal information, you consent to the
          disclosure of your personal information to third parties who reside
          outside Australia and acknowledge that we are not required to ensure
          that those third parties comply with Australian privacy laws.
        </p>
        <p></p>
        <h3>
          <b>
            How we treat personal information that is also sensitive information
          </b>
        </h3>
        <p>
          Sensitive information is a subset of personal information that is
          given a higher level of protection under the Australian Privacy
          Principles. <b>Sensitive information</b> means information relating to
          your racial or ethnic origin, political opinions, religion, trade
          union or other professional associations or memberships, philosophical
          beliefs, sexual orientation or practices, criminal records, health
          information or biometric information.&nbsp;
        </p>
        <p></p>
        <h3>
          <b>Your rights and controlling your personal information</b>
        </h3>
        <p>
          <b>Choice and consent</b>: Please read this Privacy Policy carefully.
          By providing personal information to us, you consent to us collecting,
          holding, using and disclosing your personal information in accordance
          with this Privacy Policy. You do not have to provide personal
          information to us, however, if you do not, it may affect your use of
          this Site or the products and/or services offered on or through it.
        </p>
        <p>
          <b>Information from third parties</b>: If we receive personal
          information about you from a third party, we will protect it as set
          out in this Privacy Policy. If you are a third party providing
          personal information about somebody else, you represent and warrant
          that you have such person’s consent to provide the personal
          information to us.
        </p>
        <p>
          <b>Restrict</b>: You may choose to restrict the collection or use of
          your personal information.<span>&nbsp; </span>If you have previously
          agreed to us using your personal information for direct marketing
          purposes, you may change your mind at any time by contacting us using
          the details below.
        </p>
        <p>
          <b>Access</b>: You may request details of the personal information
          that we hold about you.<span>&nbsp; </span>An administrative fee may
          be payable for the provision of such information.<span>&nbsp; </span>
          In certain circumstances, as set out in the <i>
            Privacy Act 1988
          </i>{" "}
          (Cth), we may refuse to provide you with personal information that we
          hold about you.
        </p>
        <p>
          <b>Correction</b>: If you believe that any information we hold about
          you is inaccurate, out of date, incomplete, irrelevant or misleading,
          please contact us using the details below. We will take reasonable
          steps to correct any information found to be inaccurate, incomplete,
          misleading or out of date.
        </p>
        <p>
          <b>Complaints</b>: If you believe that we have breached the Australian
          Privacy Principles and wish to make a complaint, please contact us
          using the details below and provide us with full details of the
          alleged breach. We will promptly investigate your complaint and
          respond to you, in writing, setting out the outcome of our
          investigation and the steps we will take to deal with your complaint.
        </p>
        <p>
          <span>
            <span>
              If you are unhappy with the outcome of your privacy complaint to
              us, you may complain directly to the{" "}
            </span>
            <a href="https://aus01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.oaic.gov.au%2F&amp;data=04%7C01%7Cenquiries%40oaic.gov.au%7C0a4337bb7701443d24a108d8ee6a7052%7Cea4cdebd454f4218919b7adc32bf1549%7C0%7C0%7C637521489368990863%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000&amp;sdata=cFxN4mGUAFXIEu33qO2ov5VlRYdT3g8WPvPNUeplDEE%3D&amp;reserved=0">
              <span>Office of the Australian Information Commissioner</span>
            </a>
            <span> You can find the </span>
            <a href="https://www.oaic.gov.au/about-us/contact-us">
              <span>OAIC Contact Details here</span>
            </a>
            <span>.</span>
          </span>
          <br />
        </p>
        <p>
          <b>Unsubscribe</b>: To unsubscribe from our e-mail database or opt-out
          of communications (including marketing communications), please contact
          us using the details below or opt-out using the opt-out facilities
          provided in the communication.
        </p>
        <p></p>
        <h3>
          <b>Storage and security</b>
        </h3>
        <p>
          We are committed to ensuring that the personal information we collect
          is secure. In order to prevent unauthorised access or disclosure, we
          have put in place suitable physical, electronic and managerial
          procedures to safeguard and secure the personal information and
          protect it from misuse, interference, loss and unauthorised access,
          modification and disclosure.
        </p>
        <p>
          We cannot guarantee the security of any information that is
          transmitted to or by us over the Internet. The transmission and
          exchange of information is carried out at your own risk. Although we
          take measures to safeguard against unauthorised disclosures of
          information, we cannot assure you that the personal information we
          collect will not be disclosed in a manner that is inconsistent with
          this Privacy Policy.
        </p>
        <p></p>
        <h3>
          <b>Cookies and web beacons</b>
        </h3>
        <p>
          We may use cookies on our Site from time to time. Cookies are text
          files placed in your computer’s browser to store your preferences.
          Cookies, by themselves, do not tell us your email address or other
          personally identifiable information. However, they do allow third
          parties, such as Google and Facebook, to cause our advertisements to
          appear on your social media and online media feeds as part of our
          retargeting campaigns. If and when you choose to provide our Site with
          personal information, this information may be linked to the data
          stored in the cookie.
        </p>
        <p>
          We may use web beacons on our Site from time to time. Web beacons
          (also known as Clear GIFs) are small pieces of code placed on a web
          page to monitor the visitor’s behaviour and collect data about the
          visitor’s viewing of a web page. For example, web beacons can be used
          to count the users who visit a web page or to deliver a cookie to the
          browser of a visitor viewing that page.
        </p>
        <p></p>
        <h3>
          <b>Links to other websites</b>
        </h3>
        <p>
          Our Site may contain links to other websites. We do not have any
          control over those websites and we are not responsible for the
          protection and privacy of any personal information which you provide
          whilst visiting those websites. Those websites are not governed by
          this Privacy Policy.
        </p>
        <p></p>
        <h3>
          <b>Amendments</b>
        </h3>
        <p>
          We may, at any time and at our discretion, vary this Privacy Policy by
          publishing the amended Privacy Policy on our Site. We recommend you
          check our Site regularly to ensure you are aware of our current
          Privacy Policy.
        </p>
        <p></p>
        <p>
          <b>
            For any questions or notices, please contact our Privacy Officer at:
          </b>
        </p>
        <p>StackGo Pty Ltd] ABN 74 644 421 518</p>
        <p>
          Email: <a href="mailto:team@stackgo.io">team@stackgo.io</a>
        </p>
        <p>
          <b>Last update</b>: 6 November 2020
        </p>
        <p>
          Privacy Policy provided by
          <a href="https://legalvision.com.au" target="_blank" rel="noopener">
            {" "}
            <span>LegalVision.com.au</span>
          </a>
        </p>
        <p></p>
      </div>
    </Article>
  );
}
