/***
 *
 *   XPM Pre-Verification Form Settings
 *   View table for existing pre verification forms for XPM
 *
 **********/

import React, { useState, useEffect, useContext } from "react";
import {
  useAPI,
  Table,
  Card,
  BlankSlateMessage,
  TitleRow,
  Button,
  ViewContext,
  AccountNav,
} from "components/lib";

export function XpmPreVerificationForms(props) {
  const preVerificationFormsAPI = useAPI("/api/pre-verification-settings");
  const forms = useAPI("/api/form");
  const [tempRes, setTempRes] = useState(null);
  const viewContext = useContext(ViewContext);

  const createForm = () => {
    viewContext.modal.show(
      {
        title: "Create Pre Verification Form Settings",
        form: {
          name: {
            label: "Name",
            type: "text",
            required: true,
          },
          form: {
            label: "Form",
            type: "select",
            required: true,
            options: forms.data.map((form) => {
              return {
                value: form.id,
                label: form.name,
              };
            }),
          }
        },
        buttonText: "Create",
        url: "/api/pre-verification-settings",
        method: "POST",
      },
      (form, res) => {
        setTempRes(res);
        console.log("res", res.data);
      }
    );
  };

  useEffect(() => {
    if (!tempRes) {
      return;
    }
    console.log("of res", tempRes);
    window.location.href = `/account/pre-verification/xpm/${tempRes?.id}`;
  }, [tempRes]);

  if (preVerificationFormsAPI?.data?.length === 0) {
    return (
      <BlankSlateMessage
        title="Create XPM Pre Verification Form Settings"
        text=""
        buttonText="Create"
        action={createForm}
      />
    );
  }



  return (
    <>
      <AccountNav />
      <Card>
        <TitleRow title="Manage Forms">
          <Button small text="Create" action={createForm} />
        </TitleRow>
        <Table
          data={preVerificationFormsAPI.data}
          show={["name"]}
          hide={["id"]}
          loading={preVerificationFormsAPI.loading}
          actions={{ view: { url: "/account/pre-verification/xpm", col: "id" } }}
        />
      </Card>
    </>
  );
}
