
import React, { Fragment, useCallback } from "react";
import { useAPI } from "components/lib";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import Axios from "axios";
import "survey-core/defaultV2.min.css";

StylesManager.applyTheme("defaultV2");

export function KarbonSettingsView(props) {
  const existingSettingsResponse = useAPI("/api/integration/karbon");
  const env = process.env.NODE_ENV;
  const navigateToUrl =
    env === "production"
      ? `https://identity.stackgo.io/account`
      : `http://localhost:3000/account`;

  function setExistingFYISettings() {
    if (
      existingSettingsResponse.loading === false &&
      existingSettingsResponse.data !== null
    ) {
      const settings = existingSettingsResponse?.data?.[0];
      survey.setValue("enabled", settings?.enabled);
      survey.setValue("karbon_access_key", settings?.data?.karbon_access_key);
      survey.setValue("karbon_note_email", settings?.data?.karbon_note_email)
    }
  }
  const json = {
    completedHtml: "<h3>Your changes have been saved.</h3>",
    showQuestionNumbers: "off",
    pages: [
      {
        name: "KarbonSettingsView",
        elements: [
          {
            type: "boolean",
            name: "enabled",
            title: "Enabled",
            isRequired: true,
            defaultValue: false,
          },
          {
            type: "text",
            name: "karbon_access_key",
            title: "Karbon Access Key",
            isRequired: true,
          },
          {
            type: "text",
            "inputType": "email",
            name: "karbon_note_email",
            title: "Karbon Note Email",
            description: "Email address to use for posting notes into Karbon. Note must be an active user in Karbon.",
            isRequired: true,
          }
        ],
      },
    ],
  };

  json.navigateToUrl = navigateToUrl;

  async function saveSurveyResults(url, json) {
    console.log("saveSettings", url, json);
    const response = await Axios.post(url, json);
  }

  const karbonSettingsAdded = useCallback((sender) => {
    saveSurveyResults("/api/integration/karbon", sender.data);
  });

  const survey = new Model(json);
  survey.onComplete.add(karbonSettingsAdded);
  setExistingFYISettings();

  return (
    <Fragment>
      <Survey model={survey} />
    </Fragment>
  );
}
