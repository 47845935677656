/***
 *
 *   CONTACT FORM
 *   Will send an email notification to you
 *
 **********/

import React from "react";
import { Row, ContactForm } from "components/lib";
import { Helmet } from "react-helmet";

export function Contact(props) {
  return (
    <Row title="Contact Us">
      <Helmet>
        <meta
          name="description"
          content="Contact the team behind IdentityCheck if you have any questions or need help."
        ></meta>
        <meta name="title" content="Contact IdentityCheck"></meta>
      </Helmet>
      <ContactForm />
    </Row>
  );
}
