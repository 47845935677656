/***
 *
 *   FormView
 *   View table for existing forms, or launch form builder
 *
 **********/

import React, { useState, useEffect, useContext } from "react";
import {
  useAPI,
  Table,
  Card,
  BlankSlateMessage,
  TitleRow,
  Button,
  ViewContext,
  AccountNav,
} from "components/lib";

export function FormView(props) {
  const formsAPI = useAPI("/api/form");
  const [forms, setForms] = useState([]);
  const [tempRes, setTempRes] = useState(null);
  const viewContext = useContext(ViewContext);

  const createForm = () => {
    viewContext.modal.show(
      {
        title: "Create Form",
        form: {
          name: {
            label: "Form Name",
            type: "text",
            required: true,
          },
        },
        buttonText: "Create",
        url: "/api/form",
        method: "POST",
      },
      (form, res) => {
        setTempRes(res);
        console.log("form and res", form, res);
        window.location.href = `/account/form/${res.id}`;
      }
    );
  };

  useEffect(() => {
    if (formsAPI?.data?.length > 0) {
      setForms(
        formsAPI.data.map((form) => {
          return {
            name: form.name,
            id: form.id,
          };
        })
      );
    }
  }, [formsAPI.data]);

  useEffect(() => {
    console.log("of res", tempRes);
  }, [tempRes]);

  if (forms.length === 0) {
    return (
      <BlankSlateMessage
        title="Form Builder"
        text="Create forms to collect information from your users."
        buttonText="Create Form"
        action={createForm}
      />
    );
  }

  console.log(forms);
  return (
    <>
      <AccountNav />
      <Card>
        <TitleRow title="Manage Forms">
          <Button small text="Create" action={createForm} />
        </TitleRow>
        <Table
          data={forms}
          show={["name"]}
          hide={["id"]}
          loading={formsAPI.loading}
          actions={{ view: { url: "/account/form", col: "id" } }}
        />
      </Card>
    </>
  );
}
