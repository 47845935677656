/***
 *
 *   FinishVerification
 *   This is the page that is displayed after a user has completed the verification process.
 *
 **********/

import React, { Fragment, useContext } from "react";
import { Row, Card, Button, ViewContext } from "components/lib";
import logo from "../../assets/IdentityCheck_Logo_RGB_Purple.png"; 

const axios = require("axios");

export function FinishVerification(props) {

  const context = useContext(ViewContext);

  const handleDownload = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log("param1:", queryParams.get("id"));
    if (queryParams.get("id")) {
      // show modal that a report will be emailed to them
      context.modal.show({
        title: "Download Report",
        text: "Your report will be emailed to you shortly. You can now close this window.",
      });
      // send request to backend to send email
      const response = await axios.get(
        `/api/public/verification/report/${queryParams.get("id")}`
      );
      window.location.replace("/report-requested");
    }
  };


  return (
    <Fragment>
      <Row color="white">
        <Card>
          <div class="flex items-center justify-center">
            <img src={logo} width="350px" />
          </div>
          <br />
          <br />
          <br />
          <h1 style={{ textAlign: "center", fontSize: "36px" }}>
            You may now close this window
          </h1>
          <br />
          <br />
          <br /> 
          <p>
            To learn more about the IdentityCheck Process,{" "}
            <a
              style={{ color: "blue" }}
              href="https://stackgo.io/identitycheck/#howitworks"
            >
              click here
            </a>
          </p> 
          {/* <section>
            <h2>Your Identity Verification is Complete!</h2>
            <p>
              Congratulations! You’ve successfully completed your identity verification.
            </p>
          </section>
          <br />
          <section>
            <h2>Get Your Identity Certificate</h2>
            <p>Download your identity certificate by clicking the button below. This provides you a secure and private method to verify yourself with other businesses.</p>
            <br />
            <div class="flex justify-center items-center text-center">
              <Button text="Download" action={handleDownload} />
            </div>
          </section>
          <br />
          <section>
            <h2>Why Download Your Report?</h2>
            <ul>
              <li>
                <strong>Conveniant:</strong>
                Quickly verify your identity with future applications for different services.
              </li>
              <li>
                <strong>Universal Use:</strong> 
                Use with a variety of businesses globally that require you to verify your identity.
              </li>
              <li>
                <strong>Secure:</strong> 
                Protect and control your identity by only permitting authorised businesses to access your personally identifiable information.
              </li>
            </ul>
          </section>
          <br />
          <section>
            <h2>Popular Use Cases</h2>
            <ul>
              <li>Applying for a Loan, bank account or other financial service.</li>
              <li>Enrolling in Educational Courses.</li>
              <li>Appointing regulated service providers like Financial Advisers, Accountants, or Lawyers.</li>
            </ul>
          </section> */}
        </Card>
      </Row>
    </Fragment>
  );
}
