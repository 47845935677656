import React, { Fragment, useEffect } from "react";
import { Animate, Row, Button, useAPI, Loader, Card } from "components/lib";
import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk";
import { useParams } from "react-router-dom";

export function Verification(props) {
  // const veriffFrame = createVeriffFrame({
  //   url: "https://alchemy.veriff.com/v/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uX2lkIjoiODA5ZDBmNGEtZmFhZi00NzA4LTg1NjEtNzI5YzZlOWY4YjVmIiwiaWF0IjoxNjUwNTIzNjQ4fQ.Vhjy1yCg_a9PjhjFz6yHmesuXXIQZ00Ht7nWBCIaL-A",
  // });
  const handleClick = (e) => {
    console.log("in click", e);

    //  let request = { verification:
    //   { callback: 'https://veriff.com',
    //     person:
    //      { firstName: 'Sid',
    //        lastName: 'From React App',
    //        idNumber: '123456789' },
    //     document: { number: 'B01234567', type: 'PASSPORT', country: 'EE' },
    //     vendorData: '11111111',
    //     timestamp: '2016-05-19T08:30:25.597Z' } };

    //  fetch( `https://api.veriff.me/v1/sessions/`, {
    //   method: "post",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "X-AUTH-CLIENT": `f1062f74-6e53-4e39-96ce-cf091378b0d5`,
    //   },
    //   body: JSON.stringify(request),
    // })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     console.log(
    //       "Response From Veriff: ",
    //       responseJson
    //     );
    //     console.log(
    //       "Session URL: ",
    //       responseJson.verification.url
    //     );
    //     const veriffFrame = createVeriffFrame({
    //       url: responseJson.verification.url,
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    const veriffFrame = createVeriffFrame({
      url: `https://alchemy.veriff.com/v/${verification.data.session_token}`,
    });
  };

  const handleCancel = () => {
    console.log("in cancel");
    window.location.replace("/finish");
    return;
  };

  const handleRedirect = () => {
    const response_id = verification?.data?.response_id;
    const response_type = verification?.data?.response_type;
    // case no response
    if (response_id === null) {
      window.location.replace(
        verification?.data?.redirectUrl || `/finish?id=${params.id}`
      );
      return; // Add a return statement here
    }

    //   // case response
    if (response_id !== null && response_type === "post_verification_form") {
      window.location.replace("/public/response/" + response_id);
      return; // Add a return statement here
    }

    // case redirect is set via account
    if (verification?.data?.redirectUrl) {
      console.log("case redirect is set via account");
      window.location.replace(verification?.data?.redirectUrl);
      return;
    } else {
      console.log("case default");
      window.location.replace("/finish");
      return;
    }
  };

  useEffect(() => {
    (async () => {
      if (!verification.loading && verification.data !== null) {
        console.log("veriff session id", verification.data);
        const veriffFrame = createVeriffFrame({
          url: `https://alchemy.veriff.com/v/${verification.data?.session_token}`,
        });
      }
    })();
  }, []);
  const params = useParams();
  const verification = useAPI(`/api/public/verification/${params.id}`);
  console.log(params);

  if (verification.loading) {
    <Loader />;
  }

  if (verification.data === null || verification.data === undefined) {
    return (
      <Animate type="pop">
        <Row
          color="brand"
          title="Your session is invalid, please contact your administrator!"
        ></Row>
      </Animate>
    );
  }

  // verification is not active and response_type is post_verification_form
  if (
    verification.data.active === false &&
    verification.data.response_type === "post_verification_form"
  ) {
    return (
      <>
        {window.location.replace(
          "/public/response/" + verification.data.response_id
        )}
      </>
    );
  }

  let veriffFrame = () => {
    createVeriffFrame({
      url: `https://alchemy.veriff.com/v/${verification.data.session_token}`,
      onEvent: function (msg) {
        console.log("msg", msg);
        switch (msg) {
          case MESSAGES.FINISHED:
            // check if response_id then redirect to form
            return (
              <div>
                {handleRedirect()}
                <Animate type="pop">
                  <Row
                    color="brand"
                    title="Thank you! Your submission has been recieved."
                  ></Row>
                </Animate>
              </div>
            );
          case MESSAGES.CANCELED:
            // check if response_id then redirect to form
            return (
              <div>
                {handleCancel()}
                <Animate type="pop">
                  <Row
                    color="brand"
                    title="Thank you! Your submission has been recieved."
                  ></Row>
                </Animate>
              </div>
            );
        }
      },
    });
    // implied return undefined else there is an error in react
  };

  if (
    verification.data.response_type === "post_verification_form_pre_hs" &&
    verification.data.response_active === true &&
    !verification.data.result
  ) {
    window.location.replace("/public/pre_hs/" + verification.data.response_id);
  }

  if (
    verification.data.result === "resubmission" ||
    !verification.data.result
  ) {
    return (
      // <Animate type="pop">
      <Fragment>
        {/* <Row color="brand"> */}
        <Card>{veriffFrame()}</Card>
        {/* </Row> */}
        {/* // </Animate> */}
      </Fragment>
    );
  }

  // verification is NOT active and no response

  // verification is NOT active and has response

  // return verification.loading ? (
  //   <Loader></Loader>
  // ) : (
  //   <div>
  //     {verification.data === null || verification.data === undefined ? (
  //       <Animate type="pop">
  //         <Row
  //           color="brand"
  //           title="Your session is invalid, please contact your administrator!"
  //         ></Row>
  //       </Animate>
  //     ) : (
  //       <div>
  //         {verification.data.active === true ? (
  //           <Animate type="pop">
  //             <Row color="brand">
  //               <Card>
  //                 {createVeriffFrame({
  //                   url: `https://alchemy.veriff.com/v/${verification.data.session_token}`,
  //                   onEvent: function (msg) {
  //                     switch (msg) {
  //                       case MESSAGES.FINISHED:
  //                         return (
  //                           <div>
  //                             {window.location.replace(
  //                               verification?.data?.redirectUrl || "/finish"
  //                             )}
  //                             <Animate type="pop">
  //                               <Row
  //                                 color="brand"
  //                                 title="Thank you! Your submission has been recieved."
  //                               ></Row>
  //                             </Animate>
  //                           </div>
  //                         );
  //                     }
  //                   },
  //                 })}
  //               </Card>
  //             </Row>
  //           </Animate>
  //         ) : (
  //           <div>
  //             <Animate type="pop">
  //               <Row
  //                 color="brand"
  //                 title="Thank you! Your submission has been recieved."
  //               ></Row>
  //             </Animate>
  //           </div>
  //         )}
  //       </div>
  //     )}
  //   </div>
  // );
}
