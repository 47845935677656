/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: select plan and make payment
 *
 **********/

import React, { useContext } from "react";
import {
  AuthContext,
  Animate,
  Row,
  Card,
  SocialSignin,
  Form,
  Link,
} from "components/lib";
import logoImg from "../../../assets/IdentityCheck_Logo_RGB_Purple.png";
import { Helmet } from "react-helmet";
import "./account.scss";

export function Signup(props) {
  const context = useContext(AuthContext);

  return (
    <Animate type="pop">
      <Helmet>
        <meta
          name="description"
          content="Create your free IdentityCheck account to get started. Identity Checks Anywhere."
        ></meta>
        <meta name="title" content="Sign Up for IdentityCheck"></meta>
      </Helmet>
      <Row title="Create Your Account">
        <div className="img-wrapper">
          {/* <img src={logoImg} className='img-logo' alt=''/> */}
        </div>
        <Card loading={false} center>
          <SocialSignin network={["facebook", "twitter"]} showOr signup />
          <div className="lg:grid grid-cols-6 gap-4">
            <div className="col-start-2 col-span-3">
              <Form
                data={{
                  name: {
                    label: "First Name",
                    type: "text",
                    required: true,
                    errorMessage: "Please enter your first name",
                  },
                  email: {
                    label: "Email",
                    type: "email",
                    required: true,
                  },
                  password: {
                    label: "Password",
                    type: "password",
                    required: true,
                    complexPassword: true,
                  },
                  confirm_password: {
                    type: "hidden",
                    value: null,
                  },
                }}
                url="/api/account"
                method="POST"
                buttonText="Create Account"
                callback={context.signin}
              />
              <div>
                <p>
                  Already registered? <Link url="/signin" text="Sign In" />
                </p>
              </div>
            </div>
            {/* <div className="col-start-5 col-span-2 gap-4 text-justify">
              <br />
              <h3>Why IdentityCheck?</h3>
              <ul>
                <li>
                  🚀 <strong>Quick </strong>- installs in 5 minutes.
                </li>
                <li>
                  😌 <strong>Frictionless</strong> - takes 30 seconds for end
                  customers, no data entry.
                </li>
                <li>
                  {" "}
                  🦾 <strong>Automated</strong> - zero manual effort for your
                  team.
                </li>
                <li>
                  🔒 <strong>Secure</strong> - private storage of identity
                  documents.
                </li>
                <li>
                  🌎 <strong>Global</strong> - 10k+ document types from 200+
                  countries.
                </li>
                <li>
                  😀 <strong>Affordable</strong> - free to try, then
                  pay-as-you-go.
                </li>
                <li>
                  🧩 <strong>Integrated</strong> - works with HubSpot, Xero, and
                  more.
                </li>
              </ul>
            </div> */}
          </div>

          <div className="mt-4"></div>
        </Card>
      </Row>
    </Animate>
  );
}
