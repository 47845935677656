/***
 *
 *   EULA
 *
 **********/

import React from "react";
import { Article, List } from "components/lib";

export function EULA(props) {
  return (
    <Article>
      <div>
        <p>
          <strong>Stackgo End User Licence Agreement</strong>
        </p>
        <p>
          By downloading, installing, copying, accessing or using the
          Application, you agree to the terms of this End User Licence
          Agreement. If you are accepting these terms on behalf of another
          person or company or other legal entity, you represent and warrant
          that you have full authority to bind that person, company or legal
          entity to this Agreement.
        </p>
        <p>
          If you do not agree to this Agreement, you must cease usage of the
          Application, or any of Stackgo&rsquo;s products or services,
          immediately.
        </p>
        <p>1 Definitions</p>
        <p>
          The following definitions apply unless the context requires otherwise.
        </p>
        <p>
          <strong>Application</strong> <a name="_Hlk483838327"></a>means{" "}
          <a name="_Hlk103694122"></a>Stackgo&rsquo;s web and/or mobile software
          application, which facilitates the provision of identity verification
          services through a Third Party Provider, and it includes but is not
          limited to any and all improvements, developments, modifications,
          derivatives and variations of such software and/or application from
          time to time and where the context permits.
        </p>
        <p>
          <strong>Stackgo</strong> means <a name="_Hlk103694259"></a>Stackgo Pty
          Ltd (ACN 644 421 518).
        </p>
        <p>
          <strong>Business Day</strong> means a day on which banks are generally
          open for business in New South Wales other than a Saturday, Sunday or
          public holiday.
        </p>
        <p>
          <strong>Client</strong> means the client of Stackgo or any Third Party
          Provider who has requested for your identification to be verified.
        </p>
        <p>
          <strong>Confidential Information</strong> means the confidential
          information of a party and includes information relating to, in the
          case of Stackgo:
        </p>
        <p>(a) its personnel, policies and business strategies;</p>
        <p>
          (b) the terms upon which the Application and other products have been
          supplied, installed and/or supported pursuant to this Agreement;
        </p>
        <p>(c) the pricing and payment terms set out in this Agreement;</p>
        <p>(d) the design and content of the Application; and</p>
        <p>
          (e) any information made available to you via the Application and/or
          the Website.
        </p>
        <p>
          <strong>Force Majeure</strong> means a circumstance beyond the
          reasonable control of a party and which results in that party being
          unable to observe or perform an obligation on time under this
          Agreement (other than an obligation to make a payment). Such
          circumstances include but are not limited to:
        </p>
        <p>
          (a) acts of God, lightning strikes, earthquakes, floods, droughts,
          storms, tempests, mud slides, washaways, pandemics, epidemics,
          explosions, fires and any natural disaster;
        </p>
        <p>
          (b) acts of war, acts of public enemies, terrorism, riots, civil
          commotion, malicious damage, sabotage and revolution; and
        </p>
        <p>(c) failure of internet and telecommunication services.</p>
        <p>
          <strong>Intellectual Property Rights</strong> means copyright,
          trademark, design, patent, semiconductor, circuit layout rights, the
          right to keep confidential information confidential and any other
          rights in the nature of intellectual property rights.
        </p>
        <p>
          <strong>Representative</strong> means any officer, employee, agent,
          consultant, contractor or other representative.
        </p>
        <p>
          <strong>Third Party Provider</strong> means any provider of software,
          services or goods directly to you via the Application, and includes
          but is not limited to the software, services and goods provided by
          Veriff O&Uuml; (registry code 12932944), a private limited company
          incorporated under the laws of the Republic of Estonia, and having its
          registered address at Niine 11, 10414 Tallinn, Estonia;
        </p>
        <p>
          <strong>Third Party Provider Terms</strong> means the terms and
          conditions of the Third Party Provider and includes any privacy
          policy, general data protection policies or procedures or anything
          similar to the aforesaid documents.
        </p>
        <p>
          <strong>Third Party Platform </strong>means the web or mobile
          application or software provided by a Third Party Provider.
        </p>
        <p>
          <strong>Use</strong> means to directly or indirectly activate the
          processing capabilities of the Application, load, execute, access,
          utilise, store, employ the Application, or display information
          resulting from such capabilities.
        </p>
        <p>
          <strong>Users</strong> means you or your Representative who have Use
          of the Application.{" "}
        </p>
        <p>
          <strong>Website</strong> means the website at{" "}
          <a href="http://www.stackgo.io">www.stackgo.io</a>.{" "}
        </p>
        <p>2 Licence Grants and Restrictions</p>
        <p>
          2.1 Subject to the terms and conditions of this Agreement, Stackgo
          hereby grants to you a non-exclusive, non-transferable, revokable
          right to access and use the Application solely for the purpose of
          providing identification verification.
        </p>
        <p>
          2.2 Except as otherwise expressly permitted under this Agreement, you
          must not (and must not allow a third party) to:
        </p>
        <p>
          (a) reverse engineer or otherwise attempt to discover the source code
          of or trade secrets embodied in the Application or any portion
          thereof;
        </p>
        <p>
          (b) distribute, transfer, grant sub-licences to, or otherwise make
          available the Application or any portion thereof to third parties,
          including as an application service provider, service bureau, or
          rental source, unless otherwise authorised in writing by Stackgo;
        </p>
        <p>
          (c) embed or incorporate in any manner the Application into other
          applications of you or third parties other than as authorised by
          Stackgo in writing;{" "}
        </p>
        <p>
          (d) create modifications to or derivative works of the Application;
        </p>
        <p>(e) reproduce the Application;</p>
        <p>
          (f) attempt to modify, alter, or circumvent any licence control and
          protection mechanisms within the Application;
        </p>
        <p>
          (g) use or transmit the Application in violation of any applicable
          law, rule, or regulation;
        </p>
        <p>(h) demonstrate the Application to a competitor of Stackgo;</p>
        <p>
          (i) intentionally access, use, or copy any portion of the Application
          to directly or indirectly develop, promote, distribute, sell, or
          support any product or service that is competitive with the
          Application; or
        </p>
        <p>
          (j) remove, obscure, or alter any copyright notices or any name,
          trademark, service mark, tagline, hyperlink, or other designation
          included on any display screen within the Application (the &ldquo;
          <strong>Stackgo&rsquo;s Marks</strong>&rdquo;).
        </p>
        <p>
          You shall be responsible for all damages and liabilities incurred as a
          result of such actions.
        </p>
        <p>3 How it Works </p>
        <p>
          <a name="_Hlk103698155"></a>3.1 Identification Document Photo
        </p>
        <p>
          You will be required to take a photo of your identification document
          through the Third Party Platform. If none of the photos taken by you
          enable the identification of your identification document, you may be
          required to recommence the verification process.
        </p>
        <p>3.2 Your Photograph</p>
        <p>
          You may be required to take a photograph of yourself via the Third
          Party Platform and the Third Party Provider will use reasonable
          endeavours to inspect the photographs provided to enable your
          identification on the photograph.
        </p>
        <p>3.3 Video and other data</p>
        <p>
          Video(s) of you may be recorded during the identification verification
          process and we or the Third Party Provider may inspect and control the
          data submitted by you or any other data that has been collected during
          the verification process.
        </p>
        <p>3.4 Device Data</p>
        <p>
          You acknowledge that the Application and/or the Third Party Platform
          may inspect the device that you have used to access the Application
          and/or the Third Party Platform for the purposes of verifying your
          identification.
        </p>
        <p>3.5 Personal Information</p>
        <p>
          3.6 You will be required to provide personal information about
          yourself, such as your full name, date of birth, expiry date of the
          document and document number.
        </p>
        <p>3.7 Reaching a Verification Decision</p>
        <p>
          After carrying out the verification process and checking the data, the
          Application or the Third Party Platform will make one of the following
          verification decisions (each a "Verification Decision"):
        </p>
        <p>
          (a) A positive Verification Decision: approved; the document photo and
          your portrait photograph are clear and readable, your appearance
          matches the image on the document, there is no data about the
          manipulation of technical data or any other non-compliance of the
          submitted data.
        </p>
        <p>
          (b) A negative Verification Decision: declined; the verification and
          inspection of you is deemed to have failed. The verification of you is
          deemed to have failed if any of the following issues have been
          detected:
        </p>
        <p>
          (i) you have deliberately submitted data that does not comply with the
          personal identification data available in the personal identification
          documents database, or does not match other information or data
          obtained in the course of the verification process;
        </p>
        <p>
          (ii) you refuse to follow the instructions given as to the framing and
          proportions of your face and document;
        </p>
        <p>
          (iii) there are circumstances that refer to the suspicion of criminal
          activities. If the suspicion is justified, Stackgo or the Third Party
          Provider may inform its Client of this information.
        </p>
        <p>3.8 Communication of the Verification Decision</p>
        <p>
          Stackgo or the Third Party Provider will communicate the Verification
          Decision to the Client and may provide the User Data to the Client.
        </p>
        <p>4 Your Obligations</p>
        <p>
          4.1 You will comply with and will be responsible for your Users&rsquo;
          compliance with the terms and conditions of this Agreement available
          via the Application or the Website; the Third Party Provider Terms;
          and all applicable laws and regulations with respect to the Use of the
          Application. You shall be responsible for all damages and liabilities
          incurred by Stackgo as a result of failure to comply with this clause.
        </p>
        <p>
          4.2 You are solely responsible for any data, text, files, information,
          images, graphics, and other content or materials that its Users upload
          to the Application or provided to the Third Party Provider
          (collectively, &ldquo;<strong>User Data</strong>&rdquo;). Stackgo does
          not claim ownership of any User Data. By submitting and uploading User
          Data, you grant Stackgo (or the Third Party Provider as the case may
          be) a non-exclusive, worldwide, transferable, irrevocable right and
          licence to use, store, reproduce, modify, distribute, publicly
          perform, and publicly display the User Data for the purpose of
          providing the Application to you and for deidentifying, benchmarking
          and reporting purposes.{" "}
        </p>
        <p>5 Intellectual Property</p>
        <p>5.1 Any Intellectual Property Rights:</p>
        <p>(a) in the Application;</p>
        <p>(b) database(s) design;</p>
        <p>
          (c) in any changes, modifications, developments or enhancements made
          to the Application by Stackgo or anyone else (including your specific
          modifications);
        </p>
        <p>(d) arising from Stackgo's provision of the Application; and</p>
        <p>(e) on the Website,</p>
        <p>are owned and are the sole property of Stackgo or its licensors.</p>
        <p>
          5.2 Stackgo indemnifies you against any finally awarded damages or
          settlement amounts approved by Stackgo arising out of or in connection
          with a claim that the use of the Application in accordance with this
          Agreement infringes a third party's copyright or an Australian patent,
          provided that you:
        </p>
        <p>
          (a) notify Stackgo immediately of any infringement or claim of which
          it is aware and does not make any admissions without Stackgo's prior
          written consent;{" "}
        </p>
        <p>
          (b) at Stackgo's request and expense, allow Stackgo to conduct and/or
          settle all negotiations and litigation resulting from any such claim,{" "}
        </p>
        <p>
          (c) assist and fully co-operate with Stackgo in the defence or
          settlement of the claim; and{" "}
        </p>
        <p>
          (d) do not take and ensure that no other entity or Representative
          takes any action that prejudices Stackgo's ability to defend or settle
          the claim.
        </p>
        <p>
          5.3 Notwithstanding anything to the contrary in this Agreement,
          Stackgo is not responsible or liable for any infringement of a third
          party&rsquo;s Intellectual Property Rights if the infringement would
          not otherwise exist except for:
        </p>
        <p>
          (a) the use of the Application with any other software or equipment;
        </p>
        <p>
          (b) any corrections to or modifications of the Application database
          other than by Stackgo;
        </p>
        <p>
          (c) modifications to the Application in compliance with your
          specifications or requirements;&nbsp;
        </p>
        <p>
          (d) any breach of this Agreement by you, or any unauthorised use,
          access or modification to the Application by you or a third party,
        </p>
        <p>
          and in such circumstances Stackgo's obligations under clause 5.2 do
          not apply.
        </p>
        <p>
          5.4 If the Application is or is likely to become the subject of a
          claim of infringement, Stackgo may at its sole discretion:
        </p>
        <p>
          (a) procure for you the right to use the Application or the affected
          part of it;
        </p>
        <p>
          (b) replace or modify the Application to make it non-infringing; or
        </p>
        <p>(c) require the return of the Application. </p>
        <p>
          5.5 If Stackgo's use or possession of the Application is determined by
          an independent tribunal of fact or law to infringe the Intellectual
          Property Rights of a third person, you must cease using the
          Application immediately.
        </p>
        <p>
          5.6 To the extent permitted by law and subject to clause 11.5, clauses
          5.2 to 5.5 state Stackgo's entire liability and your sole and
          exclusive remedies for any claim of infringement of third party's
          rights.
        </p>
        <p>6 Liability</p>
        <p>
          6.1 Subject to clauses 6.2, 6.4 and 11.5 and otherwise to the extent
          permitted by law:
        </p>
        <p>
          (a) the total aggregate liability of Stackgo to you for loss or damage
          arising from or in relation to this Agreement, whether arising from
          breach of contract, tort (including negligence), in equity or
          otherwise, is limited to the fees paid by you to Stackgo for the
          6-month period immediately preceding the event giving rise to the loss
          or damage;
        </p>
        <p>(b) Stackgo is not liable to you for:</p>
        <p>
          (i) any loss of data, goodwill, interest, revenues, profits, contracts
          or any inaccuracy of data; or
        </p>
        <p>
          (ii) any indirect, special, incidental, consequential, exemplary,
          punitive, or aggravated damages or loss; or
        </p>
        <p>
          (iii) any internal costs and expenses (whether director indirect)
          howsoever incurred,
        </p>
        <p>
          arising from the negligence of, or any breach of this Agreement by,
          Stackgo or its Representatives even if Stackgo has been advised of the
          possibility that such damages may occur,
        </p>
        <p>
          (c) Stackgo is not liable for loss or damage arising from the use or
          provision of any services, software or goods from a Third Party
          Provider.
        </p>
        <p>
          6.2 Nothing in this Agreement limits Stackgo's liability to an
          individual for death or personal injury caused by a negligent or
          wilful act or omission of Stackgo or its Representatives.
        </p>
        <p>
          6.3 No warranty, condition or term (&ldquo;term&rdquo;) applies to
          this Agreement unless it is set out in this Agreement or is implied by
          law and cannot be excluded, modified or restricted. Without limiting
          clause 6.4:
        </p>
        <p>
          (a) the Application is supplied on an &ldquo;as is&rdquo; basis; and
        </p>
        <p>
          (b) the Application is suitable for use in Australia and New Zealand
          only. If you intend to use the Application in, or in relation to, any
          other jurisdiction it does so at its own risk.{" "}
        </p>
        <p>6.4 If:</p>
        <p>
          (a) any term is implied into this Agreement by law, and the law
          prohibits the exclusion, restriction or modification of that term or
          liability under it, then that term shall be deemed to be included in
          this Agreement;
        </p>
        <p>
          (b) a statutory guarantee applies to goods or services supplied under
          this Agreement;
        </p>
        <p>
          then, to the extent permitted by law, Stackgo's liability for breach
          of that term or guarantee is limited to (at Stackgo's option):
        </p>
        <p>
          (c) in relation to services, re-supplying the services or paying for
          them to be re-supplied; and
        </p>
        <p>
          (d) in relation to goods, re-supplying them, or paying for having them
          re-supplied.
        </p>
        <p>
          6.5 You will at all times indemnify and hold harmless Stackgo and its
          Representatives from and against any loss, costs (including reasonable
          legal costs and expenses) or liability incurred or suffered by Stackgo
          or its Representatives arising from any proceedings against Stackgo or
          its Representatives where such loss, costs or liability was caused by:
        </p>
        <p>
          (a) a breach by you of your obligations under this Agreement (or any
          other applicable licence terms);
        </p>
        <p>
          (b) any wilful, unlawful or negligent act or omission of you or your
          Representatives; or
        </p>
        <p>
          (c) a claim by a third party alleging an infringement of that third
          party's Intellectual Property Rights if such infringement relates to
          an act or omission of you, your Representatives or a User.
        </p>
        <p>7 Privacy</p>
        <p>
          7.1 Stackgo takes your privacy seriously and any information provided
          through your use of the Application is subject to Stackgo&rsquo;s
          Privacy Policy, which is available at{" "}
          <a href="http://www.stackgo.io/privacypolicy">
            www.stackgo.io/privacypolicy
          </a>
          .
        </p>
        <p>8 Confidentiality</p>
        <p>
          8.1 Except as provided in this clause, a party must not, without the
          prior written approval of the other party, disclose the other party's
          Confidential Information.
        </p>
        <p>8.2 A party is not in breach of clause 8.1:</p>
        <p>
          (a) in circumstances where it is legally compelled to disclose the
          other party's Confidential Information; or
        </p>
        <p>
          (b) if it discloses Confidential Information (other than of a
          technical nature) to its related companies, solicitors, auditors,
          insurers and accountants or in the case of Stackgo, to third parties
          solely for the purposes of performing this Agreement.
        </p>
        <p>
          8.3 Each party must take all reasonable steps to ensure that its
          employees, agents and sub-contractors do not make public or disclose
          the other party's Confidential Information.
        </p>
        <p>
          8.4 Each party agrees to promptly notify the other party of any
          unauthorised access to or disclosure of the Confidential Information.
        </p>
        <p>
          8.5 The receiving party agrees that any breach of this clause may
          cause irreparable harm to the disclosing party, and such disclosing
          party shall be entitled to seek equitable relief in addition to all
          other remedies provided by this Agreement or available at law.
        </p>
        <p>9 Termination</p>
        <p>
          9.1 This Agreement becomes effective when you click on the
          &ldquo;Accept&rdquo; button, or when you in any other way use the
          Application, which constitutes your acceptance of this Agreement. Once
          accepted, this Agreement remains valid until terminated.
        </p>
        <p>
          9.2 You may terminate this Agreement by providing written notice of
          the termination. This Agreement shall be immediately terminated
          without notice to you if you fail to comply with any of the terms and
          conditions of this Agreement.
        </p>
        <p>
          9.3 You agree that, upon termination, you shall cease usage of the
          Application. Clause 5, 6, 8 and 11 and any other clauses which are
          capable of having effect after the expiration or termination of this
          Agreement will continue to remain in full force and effect.
        </p>
        <p>10 Dispute Resolution</p>
        <p>
          <a name="_Ref479174384"></a>
          <a name="_Toc479175782"></a>10.1 Negotiation
        </p>
        <p>
          Except as is expressly provided for in this Agreement, if there is a
          dispute or difference between the parties arising out of or in
          connection with this Agreement (<strong>Dispute</strong>), then within
          five Business Days of a party notifying the other party in writing of
          the Dispute, a senior representative from each party must meet and use
          all reasonable endeavours acting in good faith to resolve the Dispute
          by joint discussions.
        </p>
        <p>
          <a name="_Toc479175783"></a>10.2 Mediation
        </p>
        <p>
          (a) If the Dispute is not settled within thirty (30) days of
          notification under clause 10.1, the parties must submit the Dispute to
          mediation administered by the Australian Commercial Disputes Centre.
        </p>
        <p>
          (b) The Australian Commercial Disputes Centre's Guidelines for
          Commercial Mediation apply to any mediation under this Agreement and
          the parties must comply with those rules.
        </p>
        <p>
          (c) Any mediation meetings and proceedings under this clause must be
          held in Melbourne, Victoria.
        </p>
        <p>
          <a name="_Toc479175784"></a>10.3 Arbitration
        </p>
        <p>
          (a) This arbitration clause applies if you are not based in Australia
          or New Zealand.
        </p>
        <p>
          (b) In the event that the Dispute is not resolved at the conclusion of
          the mediation, both parties are prohibited from instituting legal
          proceedings concerning the subject matter of the Dispute and the
          Dispute, controversy or claim arising out of, relating to or in
          connection with this Agreement, including any question regarding its
          existence, validity or termination, will be resolved by arbitration in
          accordance with the&nbsp;<em>ICC Rules of Arbitration</em>&nbsp;(or
          any rules which supersede these rules). The seat of arbitration shall
          be Melbourne, Australia. The language of the arbitration shall be
          English. The number of arbitrators shall be one.{" "}
        </p>
        <p>
          (c) This clause 10.3 may be used as a bar to legal proceedings issued
          in any Court in any country which has ratified the&nbsp;
          <em>
            Convention on the Recognition and Enforcement of Foreign Arbitral
            Awards 1958
          </em>
          .
        </p>
        <p>10.4 Court proceedings and other relief</p>
        <p>
          A party may not start court proceedings in relation to a Dispute until
          it has exhausted the procedures in this clause, unless the party seeks
          injunctive or other interlocutory relief.
        </p>
        <p>
          <a name="_Toc479175785"></a>10.5 Continuation of rights and
          obligations
        </p>
        <p>
          Despite the existence of a dispute or difference each party must
          continue to perform this Agreement.
        </p>
        <p>11 General Provisions</p>
        <p>11.1 Force Majeure</p>
        <p>
          Each party will be excused from any delay or failure in performance
          hereunder, other than the payment of money, caused by reason of a
          Force Majeure event.
        </p>
        <p>11.2 Announcements</p>
        <p>
          Stackgo may identify you as a customer of Stackgo and a user of the
          Application on Stackgo's published customer lists, web site, and in
          its marketing and advertising materials and announcements. Stackgo may
          also include non-confidential information about your business and
          implementation of the Application. Stackgo may release updated
          information from time to time.
        </p>
        <p>11.3 Assignment</p>
        <p>
          (a) The terms of this Agreement shall be binding on the parties and
          their successors.
        </p>
        <p>
          (b) Stackgo and you may assign its rights and obligations under this
          Agreement:
        </p>
        <p>
          (i) to a third party if any part of the Stackgo&rsquo;s business or
          your business is sold or transferred to that third party; and/or
        </p>
        <p>
          (ii) provided that Stackgo or you (as applicable) can demonstrate that
          the assignee is able to perform its obligations under this Agreement.
        </p>
        <p>
          Stackgo and you may not otherwise assign its rights under this
          Agreement without the consent of the other party and such consent will
          not be unreasonably withheld or delayed.
        </p>
        <p>
          (c) Any attempted assignment, transfer, or delegation in violation of
          the foregoing shall be null and void.
        </p>
        <p>11.4 Entire Agreement</p>
        <p>
          The Agreement and any variations to it embody the entire understanding
          and agreement between the parties as to its subject matter. All
          previous negotiations, understandings, representations or warranties
          (&ldquo;other understandings') in relation to the subject matter of
          this Agreement are superseded by this Agreement. You acknowledge and
          agree that in entering into this Agreement it is not relying on any
          other understandings (including any express warranties) which are not
          expressly set out in this Agreement.
        </p>
        <p>
          <a name="_Ref484014044"></a>11.5 Compliance with the Australian
          Consumer Law
        </p>
        <p>
          If the Australian Consumer Law in Schedule 2 to the{" "}
          <em>Competition and Consumer Act 2010</em> (Cth) applies to any supply
          of goods or services under this Agreement, nothing in this Agreement
          excludes, restricts or modifies a guarantee, right, liability or
          remedy to the extent that to do so would render a term void or be a
          breach of the <em>Competition and Consumer Act 2010</em>.
        </p>
        <p>11.6 Waiver</p>
        <p>
          A failure to exercise or a delay in exercising any right, power or
          remedy under this Agreement does not operate as a waiver. A single or
          partial exercise or waiver of the exercise of any right, power or
          remedy does not preclude any other or further exercise of that or any
          other right, power or remedy. A waiver is not valid or binding on the
          Party granting that waiver unless made in writing.
        </p>
        <p>11.7 Severability</p>
        <p>
          (a) Any provision in this Agreement that is invalid or unenforceable
          in any jurisdiction is to be read down for the purpose of that
          jurisdiction, if possible, so as to be valid and enforceable, and
          otherwise shall be severed to the extent of the invalidity or
          unenforceability, without affecting the remaining provisions of this
          Agreement, so long as the economic or legal substance of the
          transactions contemplated hereby is not affected in any manner adverse
          to any party.{" "}
        </p>
        <p>
          (b) Upon such determination, the parties shall negotiate in good faith
          in an effort to agree upon such a suitable and equitable provision to
          effect the original intention of the parties.
        </p>
        <p>
          <a name="_Toc479175798"></a>11.8 Governing law &amp; Jurisdiction
        </p>
        <p>
          (a) This Agreement is governed by and is to be construed in accordance
          with the laws of New South Wales.
        </p>
        <p>
          (b) With respect to any legal action or proceedings which may be
          brought with respect to this Agreement or any transaction contemplated
          by this Agreement (each, a Relevant Action), each party irrevocably
          and unconditionally:
        </p>
        <p>
          (i) submits to and accepts, for itself and in respect of its assets,
          the non-exclusive jurisdiction of courts exercising jurisdiction in
          Melbourne, Victoria in connection with matters concerning this
          Agreement; and
        </p>
        <p>
          (ii) agrees that it will not object to the venue or claim that the
          Relevant Action has been brought in an inconvenient forum.
        </p>
        <p>
          (c) The United Nations Convention on Contracts for the International
          Sale of Goods (and any adopting legislation) does not apply to this
          Agreement.
        </p>
        <p>11.9 Limitation of action</p>
        <p>
          Subject to clause 11.5 and to the maximum extent permitted by law,
          other than in relation to a claim for breach of Stackgo's Intellectual
          Property Rights, no claim can be made under this Agreement more than
          two (2) years after;
        </p>
        <p>
          (a) the discovery of the circumstances giving rise to the claim; or
        </p>
        <p>(b) the effective date of termination of this Agreement.</p>
      </div>
    </Article>
  );
}
