/***
 *
 *   LOGO
 *   Replace the image in /images with your own logo
 *
 **********/

import React, { useContext } from "react";
import { Link, ClassHelper, AuthContext } from "components/lib";
import LogoWhite from "./images/IdentityCheck_Logo_RGB_White.png";
import LogoMarkWhite from "./images/IdentityCheck_Icon_RGB_White.png";
import LogoColor from "./images/IdentityCheck_Logo_RGB_Purple.png";
import LogoMarkColor from "./images/IdentityCheck_Icon_RGB_Purple.png";
import Style from "./logo.tailwind.js";

export function Logo(props) {
  const Logo = {
    color: {
      logo: LogoColor,
      mark: LogoMarkColor,
    },
    white: {
      logo: LogoWhite,
      mark: LogoMarkWhite,
    },
  };

  const logoStyle = ClassHelper(Style, props);
  const context = useContext(AuthContext);
  const isUser = context.user?.token;
  const link = isUser ? "/dashboard" : "https://www.stackgo.io/identitycheck";

  return (
    <Link url={link} className={logoStyle}>
      <img
        src={
          Logo[props.color ? "color" : "white"][props.mark ? "mark" : "logo"]
        }
        alt="Logo"
      />
    </Link>
  );
}
