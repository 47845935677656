/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners coming from Xero
 *   Step 1: create account
 *   Step 2: select plan and make payment
 *
 **********/

import React, { useContext, useEffect } from "react";
import {
  AuthContext,
  Animate,
  Row,
  Card,
  SocialSignin,
  Form,
  Link,
  useLocation,
  useNavigate,
} from "components/lib";


import { Helmet } from "react-helmet";
import "./account.scss";

export function SignUpPipedrive(props) {
  const context = useContext(AuthContext);
  console.log('in sign up pipedrive')
  console.log(context);
  const navigate = useNavigate();
  const location = useLocation();
  const qs = location.search;


  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  
  // console.log('ufi 2', ufi)

  useEffect(() => {
    if (context.user) {
     const ufi = query.get("userForeignIdentifier") ? `?ufi=${query.get("userForeignIdentifier")}` : "";
     console.log('ufi', ufi)
      window.location = "/account/integrations/pipedrive" + `${ufi}`;
    }
  }, [context.user]);

  return (
    <Animate type="pop">
      <Helmet>
        <meta
          name="description"
          content="Create your free IdentityCheck account to get started. Identity Checks Anywhere."
        ></meta>
        <meta name="title" content="Sign Up for IdentityCheck"></meta>
      </Helmet>
      <Row title="Create Your Account">
        <div className="img-wrapper">
          {/* <img src={logoImg} className='img-logo' alt=''/> */}
        </div>
        <Card loading={false} restrictWidth center>
          <SocialSignin network={["facebook", "twitter"]} showOr signup />

          <Form
            data={{
              name: {
                label: "First Name",
                type: "text",
                required: true,
                errorMessage: "Please enter your first name",
                value: query.get("name") || "",
              },
              email: {
                label: "Email",
                type: "email",
                required: true,
                value: query.get("email") || "",
              },
              password: {
                label: "Password",
                type: "password",
                required: true,
                complexPassword: true,
              },
              confirm_password: {
                type: "hidden",
                value: null,
              },
              plan: {
                type: "hidden",
                value: "free",
              },
            }}
            url="/api/account"
            method="POST"
            buttonText="Create Account"
            callback={context.pipedriveSignUp}
          />

          <div className="mt-4">
            Already registered?{" "}
            <Link
              url={"/signin/pipedrive" + `?ufi=${query.get("userForeignIdentifier")}`}
              text="Sign In"
            />
          </div>
        </Card>
      </Row>
    </Animate>
  );
}
