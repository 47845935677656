import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
  Grid,
  Card,
  useAPI,
  Button,
  Image,
  Loader,
  ViewContext,
  AuthContext,
} from "components/lib";
import Axios from "axios";
import { StylesManager, Model, QuestionTagboxModel } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";

StylesManager.applyTheme("defaultV2");

export function HubSpotSettingsView(props) {
  const hubspotFieldsResponse = useAPI("/api/integration/hubspot/fields");
  const exstingSettingsResponse = useAPI("/api/integration/hubspot/settings");
  const hubspotConnected = useAPI("/api/integration/hubspot/connected");
  const [dateFields, setDateFields] = useState([]);
  const [textFields, setTextFields] = useState([]);

  const env = process.env.NODE_ENV;
  const navigateToUrl =
    env === "production"
      ? `https://identity.stackgo.io/account`
      : `http://localhost:3000/account`;

  useEffect(() => {
    console.log("hubspotFieldsResponse", hubspotFieldsResponse);
    if (
      hubspotFieldsResponse.loading === false &&
      hubspotFieldsResponse.data !== null
    ) {
      const dateHSFields = hubspotFieldsResponse?.data?.results?.filter(
        (f) =>
          f.fieldType === "date" &&
          f?.modificationMetadata?.readOnlyValue === false
      );
      const textHSFields = hubspotFieldsResponse?.data?.results?.filter(
        (f) =>
          f.fieldType === "text" &&
          f?.modificationMetadata?.readOnlyValue === false
      );

      const dateChoices = dateHSFields.map((field) => {
        return {
          value: field.name,
          text: field.label,
        };
      });

      const textChoices = textHSFields.map((field) => {
        return {
          value: field.name,
          text: field.label,
        };
      });

      setTextFields(textChoices);
      setDateFields(dateChoices);
    }
  }, [hubspotFieldsResponse]);

  function setExistingReminderSettings() {
    if (
      exstingSettingsResponse.loading === false &&
      exstingSettingsResponse.data !== null
    ) {
      const settings = exstingSettingsResponse?.data?.[0];
      survey.setValue("enabled", settings?.enabled);
      survey.setValue("first_name", settings?.data?.first_name);
      survey.setValue("last_name", settings?.data?.last_name);
      survey.setValue("document_type", settings?.data?.document_type);
      survey.setValue("document_number", settings?.data?.document_number);
      survey.setValue("document_country", settings?.data?.document_country);
      survey.setValue("date_of_birth", settings?.data?.date_of_birth);
      survey.setValue("valid_from", settings?.data?.valid_from);
      survey.setValue("valid_until", settings?.data?.valid_until);
      survey.setValue("city", settings?.data?.city);
      survey.setValue("state", settings?.data?.state);
      survey.setValue("unit", settings?.data?.unit);
      survey.setValue("street", settings?.data?.street);
      survey.setValue("country", settings?.data?.country);
      survey.setValue("postcode", settings?.data?.postcode);
      survey.setValue("houseNumber", settings?.data?.houseNumber);
      survey.setValue("fullAddress", settings?.data?.fullAddress);
      survey.setValue("idNumber", settings?.data?.idNumber);
    }
  }

  // surveyjs json
  const json = {
    completedHtml: "<h3>Your changes have been saved.</h3>",
    showQuestionNumbers: "off",
    pages: [
      {
        name: "Write back to HubSpot",
        elements: [
          {
            type: "html",
            name: "question3",
            html: "<strong>Each of the Fields captured during the IdentityCheck process can be update back to HubSpot Contact</strong>\n<br />\n<br />\nSelect the Contact Property and IdentityCheck will write back to HubSpot CRM (if captured)",
          },
          {
            type: "boolean",
            name: "enabled",
            title: "Enabled",
            isRequired: true,
            defaultValue: false,
          },
          {
            type: "tagbox",
            name: "first_name",
            maxSelectedChoices: 1,
            title: "First Name",
            choices: textFields,
          },
          {
            type: "tagbox",
            name: "last_name",
            title: "Last Name",
            choices: textFields,
            maxSelectedChoices: 1,
          },
          {
            type: "tagbox",
            name: "document_type",
            title: "Document Type",
            choices: textFields,
            maxSelectedChoices: 1,
          },
          {
            type: "tagbox",
            name: "document_number",
            title: "Document Number",
            choices: textFields,
            maxSelectedChoices: 1,
          },
          {
            type: "tagbox",
            name: "document_country",
            title: "Document Country",
            choices: textFields,
            maxSelectedChoices: 1,
          },
          {
            type: "tagbox",
            name: "valid_from",
            title: "Valid From",
            choices: dateFields,
            maxSelectedChoices: 1,
          },
          {
            type: "tagbox",
            name: "valid_until",
            title: "Valid Until",
            choices: dateFields,
            maxSelectedChoices: 1,
          },
          {
            type: "tagbox",
            title: "Date of Birth",
            name: "date_of_birth",
            choices: dateFields,
            maxSelectedChoices: 1,
          },
          {
            type: "tagbox",
            name: "city",
            title: "City",
            choices: textFields,
            maxSelectedChoices: 1,
          },
          {
            type: "tagbox",
            name: "state",
            title: "State",
            choices: textFields,
            maxSelectedChoices: 1,
          },
          {
            type: "tagbox",
            name: "unit",
            title: "Unit",
            choices: textFields,
            maxSelectedChoices: 1,
          },
          {
            type: "tagbox",
            name: "street",
            title: "Street",
            choices: textFields,
            maxSelectedChoices: 1,
          },
          {
            type: "tagbox",
            name: "country",
            title: "Country",
            choices: textFields,
            maxSelectedChoices: 1,
          },
          {
            type: "tagbox",
            name: "postcode",
            title: "Postcode",
            choices: textFields,
            maxSelectedChoices: 1,
          },
          {
            type: "tagbox",
            name: "houseNumber",
            title: "House Number",
            choices: textFields,
            maxSelectedChoices: 1,
          },
          {
            type: "tagbox",
            name: "fullAddress",
            title: "Full Address",
            choices: textFields,
            maxSelectedChoices: 1,
          },
          {
            type: "tagbox",
            name: "idNumber",
            title: "ID Number",
            choices: textFields,
            maxSelectedChoices: 1,
          }
        ],
      },
    ],
  };

  json.navigateToUrl = navigateToUrl;

  async function saveSurveyResults(url, json) {
    console.log("saveSettings", url, json);
    const response = await Axios.post(url, json);
  }

  const writeBackSettingsComplete = useCallback((sender) => {
    saveSurveyResults(
      "/api/integration/hubspot/internal/settings",
      sender.data
    );
  });

  // if hubspot is not connected - return to integrations page
  if (hubspotConnected.loading === false && hubspotConnected.data === false) {
    window.location = "/account/integrations";
  }

  const survey = new Model(json);
  survey.onComplete.add(writeBackSettingsComplete);

  setExistingReminderSettings();

  return (
    <Fragment>
      <Survey model={survey} />
    </Fragment>
  );
}
