import React, { useEffect, useState, useCallback, useContext } from "react";
import { AccountNav, useAPI, AuthContext } from "components/lib";
import Axios from "axios";
import "survey-core/defaultV2.min.css";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
StylesManager.applyTheme("defaultV2");

export function PostVerificationFormSettings(props) {
  const forms = useAPI("/api/form");
  const postFormSettings = useAPI("/api/integration/post_form");
  // const postFormSettings = useAPI("/api/form");
  const [choices, setChoices] = useState([]);

  const json = {
    title: "Post Verification Form Settings",
    completedHtml:
      "<h3>Your post verification form settings have been updated</h3>",
    pages: [
      {
        name: "page1",
        elements: [
          {
            type: "boolean",
            name: "enabled",
            title: "Enable",
            isRequired: true,
          },
        ],
      },
      {
        name: "page2",
        elements: [
          {
            type: "dropdown",
            name: "direct",
            title: "Direct",
            choices: choices,
            isRequired: true,
            showNoneItem: true,
          },
          {
            type: "dropdown",
            name: "xpm",
            title: "XPM",
            choices: choices,
            isRequired: true,
            showNoneItem: true,
          },
          {
            type: "dropdown",
            name: "pipedrive",
            title: "Pipedrive",
            choices: choices,
            isRequired: true,
            showNoneItem: true,
          },
          {
            type: "dropdown",
            name: "hubspot",
            title: "Hubspot",
            choices: choices,
            isRequired: true,
            showNoneItem: true,
          },
          {
            type: "dropdown",
            name: "api",
            title: "API",
            choices: choices,
            isRequired: true,
            showNoneItem: true,
          },
        ],
        title: "Sources",
      },
      {
        name: "page3",
        title: "Emails",
        description: "TBC",
      },
    ],
  };

  const survey = new Model(json);

  const postVerifcationSettingsComplete = useCallback((sender) => {
    saveSurveyResults("/api/integration/post_form", sender.data);
  });

  function setExistingPostVerificationFormSettings() {
    console.log(
      "setExistingPostVerificationFormSettings",
      postFormSettings?.data
    );
    if (!postFormSettings?.data?.[0]) return;
    survey.setValue("enabled", postFormSettings?.data?.[0]?.enabled);
    survey.setValue("direct", postFormSettings?.data?.[0]?.data?.direct);
    survey.setValue("xpm", postFormSettings?.data?.[0]?.data?.xpm);
    survey.setValue("pipedrive", postFormSettings?.data?.[0]?.data?.pipedrive);
    survey.setValue("hubspot", postFormSettings?.data?.[0]?.data?.hubspot);
    survey.setValue("api", postFormSettings?.data?.[0]?.data?.api);
  }

  useContext(AuthContext);

  // setExistingPostVerificationFormSettings();
  survey.onComplete.add(postVerifcationSettingsComplete);

  async function saveSurveyResults(url, json) {
    console.log("saveSurveyResults", url, json);
    const response = await Axios.post(url, json);
  }

  useEffect(() => {
    if (forms.data && forms.loading === false) {
      const choices = forms.data.map((f) => {
        return {
          value: f.id,
          text: f.name,
        };
      });
      setChoices(choices);
    }
  }, [forms]);

  if (forms.loading) {
    return (
      <>
        <p>Waiting for forms to load</p>
      </>
    );
  }

  if (forms?.data?.length === 0) {
    return (
      <>
        <p>Please define some forms before continuing (insert link)</p>
      </>
    );
  }
  setExistingPostVerificationFormSettings();
  return (
    <>
      <AccountNav />
      <h1>Post Verification Form</h1>
      <Survey model={survey} />
    </>
  );
}
