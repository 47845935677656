/***
 *
 *   PRICING
 *   Modify the PricePlans component with your own features
 *
 **********/

import React from "react";
import { Animate, Row, PricePlans, useAPI } from "components/lib";
import { Helmet } from "react-helmet";

export function Pricing(props) {
  return (
    <Animate type="pop">
      {window.location.replace("https://stackgo.io/identitycheck/#pricing")}
      <Helmet>
        <meta
          name="description"
          content="Simple, affordable pricing to get you started on your Identity Verification journey."
        ></meta>
        <meta name="title" content="IdentityCheck Pricing"></meta>
      </Helmet>
      {/* <Row color="tint" align="center" title="Simple, affordable pricing">
        <PricePlans />
      </Row> */}
    </Animate>
  );
}
