/***
 *
 *   ONBOARDING
 *   Example of onboarding flow*
 *
 **********/

import React, { Fragment, useContext } from "react";
import {
  AuthContext,
  Onboarding,
  Form,
  Image,
  Message,
  Button,
  useAPI,
  Loader,
} from "components/lib";
import "../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Axios from "axios";

import "./onboarding.scss";
import video from "../../assets/big_buck_bunny_720p_5mb.mp4";
import logoImg from "../../assets/2560px-HubSpot_Logo.svg.png";
import img1 from "../../assets/demo/1.png";
import img2 from "../../assets/demo/2.png";
import img3 from "../../assets/demo/3.png";
import img4 from "../../assets/demo/4.png";
import img5 from "../../assets/demo/5.png";
export function OnboardingView(props) {
  const context = useContext(AuthContext);
  const views = [
    {
      name: "Getting Started",
      description: `This short video explains how IdentityCheck works. Click Next to continue.`,
      component: <Welcome />,
    },
    {
      name: "Organisation Name",
      description: "",
      component: <OrganisationName />,
    },
    {
      name: "Connect",
      description: `Connect to our integrated platforms to get started.`,
      component: <ConnectHS />,
    },
    {
      name: "Demo",
      description: `These pictures how to request your first identity check on a HubSpot contact. Click on the right arrow to view all the steps`,
      component: <KYCDemo />,
    },
  ];

  // if (context.permission.admin) {
  //   views.push({

  //     name: 'Invite your team',
  //     description: 'Gravity is more fun when you invite your team.',
  //     component: <InviteUsers/>,

  //   });
  // }

  if (context.user.duplicate_user) {
    views.unshift({
      name: "Important!",
      description: "",
      component: <DuplicateUser />,
    });
  }

  return <Onboarding save onFinish="/dashboard" views={views} />;
}

function DuplicateUser() {
  return (
    <Message
      type="warning"
      title={`You already have an account`}
      text="We noticed you have already registered an account, so we used your original password to save you any confusion in the future."
    />
  );
}

const IntroCarousel = () => {
  return (
    <Carousel
      showThumbs={false}
      showIndicators={false}
      showArrows={true}
      showStatus={false}
    >
      <div>
        <video
          width="470"
          height="240"
          controls
          loop="true"
          autoplay="autoplay"
        >
          <source
            src="https://storage.googleapis.com/identitycheck/identitycheck-explainer.mp4"
            type="video/mp4"
          />
          <source
            src="https://storage.googleapis.com/identitycheck/identitycheck-explainer.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </Carousel>
  );
};

const KYCDemoCarousel = () => {
  return (
    <Carousel
      showThumbs={true}
      showIndicators={false}
      showArrows={true}
      showStatus={true}
      infiniteLoop
    >
      <div className="carousel">
        <div>
          <video
            width="470"
            height="240"
            controls
            loop="true"
            // autoplay="autoplay"
          >
            <source
              src="https://storage.googleapis.com/identitycheck/Hubspot%20demo%20-%20how%20it%20works2.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        {/* <div className="demo-text">Login to Hubspot</div> */}
      </div>

      {/* <div>
        <div>
          <img src={img2} />
        </div>
        <div className="demo-text">
          Select View all properties from within the contact you want to verify
        </div>
      </div>

      <div>
        <div>
          <img src={img3} />
        </div>
        <div className="demo-text">
          (a) Search kyc, and (b) choose Add to your view next to KYC Command
          and KYC Status
        </div>
      </div>

      <div>
        <div>
          <img src={img4} />
        </div>
        <div className="demo-text">
          From KYC Command drop-down choose Start. The contact will be emailed a
          unique KYC link.
        </div>
      </div>

      <div>
        <div>
          <img src={img5} />
        </div>
        <div className="demo-text">
          Monitor status of your KYC request under (a) KYC Status, and (b)
          Notes.
        </div>
      </div> */}
    </Carousel>
  );
};
function Welcome() {
  return (
    <Fragment>
      {/* <p>This is an example of the user-onboarding flow.
        You can replace this with your own content, try out the action
        in the next step or skip the intro entirely.</p>
        
      <p><strong>Have fun playing with Gravity! 🛠 </strong></p> */}
      {IntroCarousel()}
    </Fragment>
  );
}

function ConnectHS() {
  const hubspotLink = useAPI("/api/integration/hubspot/install");
  const xpmInstallAction = async () => {
    const response = await Axios.get("/api/integration/xpm/install");
    console.log(response.data.data.url);
    window.location = response.data.data.url;
  };

  return (
    <Fragment>
      {hubspotLink?.loading ? (
        <Loader />
      ) : (
        <div className="align-center connect-container place-item-center">
          <div className="">
            <img src={logoImg} />
          </div>
          <Button
            text="CONNECT"
            action={() => (window.location = hubspotLink?.data?.url)}
            color="blue"
            className="inline-block mt-6"
          />
          <div className="align-center connect-container place-item-center">
            <div className="">
              {/* <img src={logoImg} /> */}
              <Image source="https://storage.googleapis.com/identitycheck/xpm-l2.png"></Image>
            </div>
            <Button
              text="CONNECT"
              action={xpmInstallAction}
              color="blue"
              className="inline-block mt-6"
            />
          </div>
        </div>
      )}
    </Fragment>
  );
}
function KYCDemo() {
  const createFields = useAPI("/api/integration/hubspot/fields", "post");
  return (
    <Fragment>
      {KYCDemoCarousel()}
      <div className="align-center">
        {/* <Button
          text="PROCEED"
          action={{}}
          color="blue"
          className="inline-block mt-6"
        /> */}
      </div>
    </Fragment>
  );
}

function OrganisationName() {
  return (
    <Fragment>
      <Form
        data={{
          orgName: {
            label: "Your Company's Name",
            type: "text",
            required: true,
          },
        }}
        buttonText={false}
        url="/api/account/name"
        method="POST"
        submitOnChange
      />
    </Fragment>
  );
}

function InviteUsers(props) {
  return (
    <Form
      data={{
        email: {
          label: "Email",
          type: "email",
          required: true,
        },
      }}
      buttonText="Send Invite"
      url="/api/invite"
      method="POST"
    />
  );
}
