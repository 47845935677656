import { Loader, useAPI, Grid, Card } from "components/lib";
import React, { useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { StylesManager, Model, FunctionFactory } from "survey-core";
import { Survey } from "survey-react-ui";
import Axios from "axios";
import "survey-core/defaultV2.min.css";
const Settings = require("settings.json");
const server_url = Settings[process.env.NODE_ENV].server_url;

StylesManager.applyTheme("defaultV2");

export function PreVerificationFormView(props) {
  // get form details
  const params = useParams();
  const id = params.id;
  const formAPI = useAPI("/api/public/onboarding/" + id);
  const [formData, setFormData] = React.useState(null);
  const [tfns, setTfns] = React.useState([]);
  // const [tfnValues, setTfnsValues] = React.useState([]);
  // const tfnValues = useRef([]);

  React.useEffect(() => {
    if (!formAPI?.loading && formAPI?.data !== null) {
      console.log("formAPI", formAPI?.data);
      setFormData(formAPI?.data);
      setTfns(formAPI?.data.tfns);
    }
  }, [formAPI]);

  async function saveSurveyResults(url, json) {
    console.log("saveSurveyResults", url, json);
    console.log("abort", formData?.abort);
    if (
      formAPI.data?.abort &&
      json[formAPI?.data?.abort?.name] === true &&
      formAPI?.data?.abort?.type === "redirect"
    ) {
      console.log("aborting");
      window.location.href = formAPI?.data?.abort?.redirect_url || "/finish";
      return;
    }
    const respose = await Axios.post(url, json);
    console.log("respose", respose);
    if (respose.data.redirect) {
      console.log("redirecting to", respose.data.redirect);
      window.location.href = respose.data.redirect;
    }
  }

  const reponseComplete = useCallback((sender, options) => {
    console.log("reponseComplete", sender);
    console.log("data", sender.data);
    // console.log("tfnValues", tfnValues);
    saveSurveyResults("/api/public/onboarding/" + params.id, sender.data);
  });

  function isValidUtr(sender, options) {
    console.log("isValidUTR", sender, options);
    const utrQuestion = sender.getQuestionByName("utr");
    console.log("utrQuestion", utrQuestion);

    if (!utrQuestion) {
      return;
    }

    if (utrQuestion.isRequired && !options.value) {
      console.log("UTR is required");
      options.error = "UTR is required";
    }

    if (!utrQuestion.isRequired && !options.value) {
      return;
    }

    if (options.name === "utr") {
      let utr = (options.value || "").toString();
      console.log("utr", utr);

      // Check if UTR length is 10 digits
      if (utr.length !== 10) {
        return (options.error = "UTR must be 10 digits");
      }

      // Define the weighting factors
      const weights = [0, 6, 7, 8, 9, 10, 5, 4, 3, 2];
      const remainderWeights = [2, 1, 9, 8, 7, 6, 5, 4, 3, 2, 1];

      let sum = 0;

      weights.forEach((weight, position) => {
        const digit = Number(utr[position]);
        sum += weight * digit;
      });
      console.log("sum", sum);

      const checkDigit = sum % 11;
      const expectedCheckDigit = remainderWeights[checkDigit];

      console.log("checkDigit", checkDigit);
      console.log("expectedCheckDigit", expectedCheckDigit);

      if (expectedCheckDigit !== Number(utr.charAt(0))) {
        return (options.error = "Invalid UTR");
      }
    }
  }

  function isValidABN(sender, options) {
    // console.log("isValidABN", sender, options);
    const abnQuestion = sender.getQuestionByName("abn");
    // console.log("abnQuestion", abnQuestion);

    if (!abnQuestion) {
      return;
    }

    if (abnQuestion.isRequired && !options.value) {
      console.log("ABN is required");
      options.error = "ABN is required";
    }

    if (!abnQuestion.isRequired && !options.value) {
      return;
    }

    if (options.name === "abn") {
      console.log("ABN question found", options.value);
      let abn = (options.value || "").toString();

      // Check if ABN length is 11 digits
      if (abn.length !== 11) {
        return (options.error = "ABN must be 11 digits");
      }

      // Subtract 1 from the first digit
      abn = (parseInt(abn.charAt(0)) - 1).toString() + abn.substring(1);

      // Define the weighting factors
      const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

      // Calculate the sum of the products
      let sum = 0;
      for (let i = 0; i < abn.length; i++) {
        sum += parseInt(abn.charAt(i)) * weights[i];
      }

      // Check if sum modulo 89 is 0
      const isvalid = sum % 89 === 0;
      if (!isvalid) {
        return (options.error = "Invalid ABN");
      }
    }
  }

  function validateTFN(sender, options) {
    // console.log("validateTFN", sender, options);
    let question = options.question;

    // console.log("tfns", tfns);
    // console.log('tfn',sender.getQuestionByName('tfn'));
    const spousePanel = sender.getQuestionByName("question36");
    const panel = spousePanel.panels[0];
    const spouseTfn = panel.getQuestionByName("spouseTfn");
    // console.log('spousetfn', spouseTfn);

    if (question.getType() === "paneldynamic") {
      // console.log("paneldynamic", question);
      // console.log("question.jsonObj", question.jsonObj);
      // const nestedQuestions = question.getNestedQuestions();
      const nestedQuestions = question.jsonObj.templateElements;
      // console.log("nestedQuestions", nestedQuestions);
      const foundTfnQ = nestedQuestions.find((q) => {
        console.log("q", q);
        // console.log("q.jsonObj", q.jsonObj);
        return q?.tfn;
      });

      if (foundTfnQ) {
        console.log("foundTfnQ", foundTfnQ);
      }

      // console.log("question tfn for dynamic panel", question);

      // const plainData = question.getPlainData();
      // console.log("plainData", plainData);
      // console.log("json obj", nestedQuestions[0].jsonObj);
    }

    if (question.jsonObj?.tfn) {
      // console.log("tfn", question);
      // console.log("TFN question found", options.value);
      if (!options.value && question.isRequired) {
        return (options.error = "TFN is required");
      }
      // Ensure TFN is a string to handle leading zeros
      const tfn = options.value.toString();
      // tfn = tfn.toString();

      // Check if TFN length is 9 digits
      if (tfn.length !== 9) {
        return (options.error = "TFN must be 9 digits");
      }

      // Define the weighting factors
      const weights = [1, 4, 3, 7, 5, 8, 6, 9, 10];

      // Calculate the sum of the products
      let sum = 0;
      for (let i = 0; i < tfn.length; i++) {
        sum += parseInt(tfn.charAt(i)) * weights[i];
      }

      // Check if sum modulo 11 is 0
      const valid = sum % 11 === 0;
      if (!valid) {
        return (options.error = "Invalid TFN");
      }
    } else {
      return (options.error = null);
    }
  }

  const model = new Model(formData);
  model.onComplete.add(reponseComplete);

  // model.onValidateQuestion.add(validateTFN);
  model.onValidateQuestion.add(isValidABN);
  // model.onValidateQuestion.add(isValidUtr);
  // model.onValidatePanel.add((sender, options) => {
  //   console.log("onValidatePanel", sender, options);
  //   const panel = options.panel;

  //   panel.elementsValue.forEach((element) => {
  //     console.log("element", element);
  //     console.log("element?.jsonObj", element?.jsonObj);

  //     if (element?.jsonObj?.tfn) {
  //       console.log("tfn", element);
  //       console.log("TFN question found", element.value);
  //       if (!element.value && element.isRequired) {
  //         return (options.error = "TFN is required");
  //       }
  //       // Ensure TFN is a string to handle leading zeros
  //       const tfn = element.value.toString();
  //       // tfn = tfn.toString();

  //       // Check if TFN length is 9 digits
  //       if (tfn.length !== 9) {
  //         return (options.error = "TFN must be 9 digits");
  //       }

  //       // Define the weighting factors
  //       const weights = [1, 4, 3, 7, 5, 8, 6, 9, 10];

  //       // Calculate the sum of the products
  //       let sum = 0;
  //       for (let i = 0; i < tfn.length; i++) {
  //         sum += parseInt(tfn.charAt(i)) * weights[i];
  //       }

  //       // Check if sum modulo 11 is 0
  //       const valid = sum % 11 === 0;
  //       if (!valid) {
  //         return (options.error = "Invalid TFN");
  //       }
  //     }
  //   });
  // });
  model.onValidateQuestion.add((sender, options) => {
    // console.log("onValidateQuestion", sender, options);
    const question = options.question;
    console.log("question", question);
    // parse as json
    const tfnsJson = JSON.parse(tfns || "[]");
    // console.log("tfnsJson", tfnsJson);

    const validateTFN = (tfn) => {
      if (tfn.length !== 9) {
        return "TFN must be 9 digits";
      }

      // Define the weighting factors
      const weights = [1, 4, 3, 7, 5, 8, 6, 9, 10];

      // Calculate the sum of the products
      let sum = 0;
      for (let i = 0; i < tfn.length; i++) {
        sum += parseInt(tfn.charAt(i)) * weights[i];
      }

      // Check if sum modulo 11 is 0
      const valid = sum % 11 === 0;
      if (!valid) {
        return "Invalid TFN";
      }
    };

    const validateABN = (abnValue) => {
      let abn = (options.value || "").toString();

      // Check if ABN length is 11 digits
      if (abnValue.length !== 11) {
        return (options.error = "ABN must be 11 digits");
      }

      // Subtract 1 from the first digit
      abnValue = (parseInt(abnValue.charAt(0)) - 1).toString() + abnValue.substring(1);

      // Define the weighting factors
      const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

      // Calculate the sum of the products
      let sum = 0;
      for (let i = 0; i < abnValue.length; i++) {
        sum += parseInt(abnValue.charAt(i)) * weights[i];
      }

      // Check if sum modulo 89 is 0
      const isvalid = sum % 89 === 0;
      if (!isvalid) {
        return "Invalid ABN";
      } else {
        return null;
      }
    }

    // maps the tfn questions and if question is of type "question" and name matches then validate, if question is of type "panel" then validate the nested questions
    // tfnsJson.forEach((tfn) => {
    //   // console.log("tfn", tfn);
    //   if (tfn.type === "question") {
    //     if (tfn.name === question.name) {
    //       console.log("tfn question found", question.name);
    //       const response = validateTFN(options.value);
    //       return (options.error = response);
    //     }
    //   } else if (tfn.type === "panel") {
    //     if (tfn.name === question.name) {
    //       console.log("tfn panel found", question.name);
    //       const response = validateTFN(options.value);
    //       return (options.error = response);
    //     }
    //   }
    // });

    tfnsJson.forEach((tfn) => {
      // console.log("tfn", tfn);
      if (tfn.name === question.name) {
        console.log("tfn question found", question.name);

        if (!options.value && question.isRequired) {
          return (options.error = "TFN is required");
        }
  
        if (!options.value && !question.isRequired) {
          return;
        }

        // setTfnsValues([...tfnValues, { name: question.name, value: options.value }]);
        // tfnValues.current = [...tfnValues.current, { name: question.name, value: options.value }];
        const response = validateTFN(options.value);
        return (options.error = response);
      }
    });

    if (question.name === "abn") {
      console.log("abn question found", question.name, options.value);
      if (!options.value && question.isRequired) {
        return (options.error = "ABN is required");
      }

      if (!options.value && !question.isRequired) {
        return;
      }
      const response = validateABN(options.value);
      return (options.error = response);
    }
  });

  if (formAPI.loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="min-w-min w-3/4">
        <Grid>
          <Card>
            <Survey model={model} />
          </Card>
        </Grid>
      </div>
    </>
  );
}

export default PreVerificationFormView;
