/***
 *
 *   TERMS & CONDITIONS
 *   Boilerplate terms & conditions – replace the values
 *   inside [] with your own text
 *
 **********/

import React from "react";
import { Article, List } from "components/lib";
import { Helmet } from "react-helmet";

export function Terms(props) {
  return (
    <Article>
      <Helmet>
        <meta
          name="description"
          content="Learn more about IdentityCheck Terms and Conditions. Identity Checks Anywhere."
        ></meta>
        <meta name="title" content="IdentityCheck Terms & Conditions"></meta>
      </Helmet>
      <div>
      <iframe src={"https://docs.google.com/document/d/1rLd1_Od7qzRFKWPuLMG0igrNy2H3dJ3b/edit"} width="100%" height="1600px" />
    </div>
      <p>Terms and Conditions</p>
      <p>1 Definitions and Interpretation</p>
      <p>1.1 Definitions</p>
      <p>
        1. The following definitions apply unless the context requires
        otherwise.
      </p>
      <p>
        2. Additional Charge means a charge in accordance with Stackgo’s
        standard rates
      </p>
      <p>
        from time to time, or as otherwise reasonably determined by Stackgo or
        agreed with the
      </p>
      <p>Customer.</p>
      <p>3. Agreement means these Terms and Conditions.</p>
      <p>
        4. Application means Stackgo’s web and/or mobile software application
        that facilitates
      </p>
      <p>
        the provision of identity verification services through a Third Party
        Provider, and it
      </p>
      <p>
        includes but is not limited to any and all improvements, developments,
        modifications,
      </p>
      <p>
        derivatives and variations of such software and/or application from time
        to time and where
      </p>
      <p>the context permits.</p>
      <p>
        5. Business Day means a day on which banks are generally open for
        business in New
      </p>
      <p>South Wales other than a Saturday, Sunday or public holiday.</p>
      <p>
        6. Business Hours means 9.00am to 5.30pm Sydney time on Business Days.
      </p>
      <p>
        7. Certified Party means a party that Stackgo has trained in the
        relevant aspects of the
      </p>
      <p>Application (if any).</p>
      <p>
        8. Commencement Date means the commencement date of this Agreement
        specified
      </p>
      <p>in the Subscription Plan.</p>
      <p>
        9. Confidential Information means the confidential information of a
        party and includes
      </p>
      <p>information relating to, in the case of Stackgo:</p>
      <p>(a) its personnel, policies and business strategies;</p>
      <p>
        (b) the terms upon which the Application and other products have been
        supplied,
      </p>
      <p>installed and/or supported pursuant to this Agreement;</p>
      <p>(c) the pricing and payment terms set out in this Agreement;</p>
      <p>(d) the design and content of the Application; and</p>
      <p>
        (e) any information made available to the Customer or an Entity via the
        Application
      </p>
      <p>and/or the Website.</p>
      <p>10. Customer means the Customer named in the Subscription Plan.</p>
      <p>
        11. End User means the person to whom is providing the identification
        verification.
      </p>
      <p>
        12. End User Limit means the maximum number of End Users who the
        Customer or the
      </p>
      <p>
        Customer’s Entity sends an invitation to for the End User to commence
        the verification
      </p>
      <p>
        process per month and Use the Application as specified in the
        Subscription Plan.
      </p>
      <p>
        13. EULA means Stackgo’s end user licence agreement as amended from time
        to time.
      </p>
      <p>
        14. Entities means the legal entities identified in the Subscription
        Plan or approved in
      </p>
      <p>
        writing by Stackgo. For the purposes of interpreting the Licence
        Agreement, an "Entity”
      </p>
      <p>includes a Joint Venture.</p>
      <p>15. Event of Insolvency means:</p>
      <p>
        (a) a controller, manager, trustee, administrator or similar office is
        appointed in
      </p>
      <p>respect of the Customer or an asset of the Customer;</p>
      <p>
        (b) a liquidator or provisional liquidator is appointed to the Customer;
      </p>
      <p>(c) any arrangement is made with the Customer's creditors;</p>
      <p>
        (d) the Customer is unable to or fails to pay its debts as and when they
        fall due;
      </p>
      <p>(e) a default judgement is ordered against the Customer;</p>
      <p>
        (f) an application is made, a resolution passed or a meeting convened
        for the
      </p>
      <p>purpose of considering a resolution to wind up the Customer; or</p>
      <p>
        (g) any other similar event occurs under any law applying to the
        Customer.
      </p>
      <p>
        16. Any of the above terms defined in the Corporations Act 2001, have
        the meaning
      </p>
      <p>assigned in that Act.</p>
      <p>
        17. Fees means the Plan Pricing, the Maintenance and Support Fees (if
        any) and/or any
      </p>
      <p>
        Additional Charges, fees, charges, expenses or payments specified in
        this Agreement.
      </p>
      <p>
        18. Force Majeure means a circumstance beyond the reasonable control of
        a party and
      </p>
      <p>
        which results in that party being unable to observe or perform an
        obligation on time under
      </p>
      <p>
        this Agreement (other than an obligation to make a payment). Such
        circumstances
      </p>
      <p>include but are not limited to:</p>
      <p>
        (a) acts of God, lightning strikes, earthquakes, floods, droughts,
        storms, tempests,
      </p>
      <p>
        mud slides, washaways, pandemics or epidemics, explosions, fires and any
      </p>
      <p>natural disaster;</p>
      <p>
        (b) acts of war, acts of public enemies, terrorism, riots, civil
        commotion, malicious
      </p>
      <p>damage, sabotage and revolution; and</p>
      <p>(c) failure of internet and telecommunication services.</p>
      <p>
        19. Initial Term means 24 months from the Commencement Date or such
        other period
      </p>
      <p>specified in the Subscription Plan.</p>
      <p>
        20. Intellectual Property Rights means copyright, trademark, design,
        patent,
      </p>
      <p>
        semiconductor, circuit layout rights, the right to keep confidential
        information confidential
      </p>
      <p>and any other rights in the nature of intellectual property rights.</p>
      <p>
        21. Plan Pricing means the pricing and fees associated to the Customer’s
        use of the
      </p>
      <p>Application as specified in the Subscription Plan.</p>
      <p>
        22. Maintenance and Support Fees means the maintenance and support fees
        (if any)
      </p>
      <p>specified in the Subscription Plan.</p>
      <p>
        23. Maintenance and Support Services means the maintenance and support
        services
      </p>
      <p>(if any) specified in the Subscription Plan.</p>
      <p>24. New Release means:</p>
      <p>
        (a) a new release of the Application providing for a significant change
        in technology;
      </p>
      <p>and/or</p>
      <p>
        (b) a new generally available version of the Application containing
        corrections of
      </p>
      <p>programming errors and/or functional enhancements.</p>
      <p>
        25. Personnel means employees or contractors of the Customer and/or
        Entities and any
      </p>
      <p>other persons approved by Stackgo.</p>
      <p>
        26. Representative means any officer, employee, agent, consultant,
        contractor or other
      </p>
      <p>representative.</p>
      <p>
        27. Stackgo, we, us and our means Stackgo Pty Ltd (ACN 644 421 518).
      </p>
      <p>
        28. Services means any services performed by Stackgo pursuant to this
        Agreement and
      </p>
      <p>
        any other services referred to in the Subscription Plan and Maintenance
        and Support
      </p>
      <p>Services.</p>
      <p>
        29. Subscription Plan means the subscription plan selected by the
        Customer as
      </p>
      <p>
        amended and varied from time to time which is outlined in detail on the
        Website and
      </p>
      <p>includes any trial offered by Stackgo.</p>
      <p>
        30. Terms and Conditions means the terms and conditions set out in
        clauses 1 to 18 of
      </p>
      <p>this document.</p>
      <p>
        31. Use means to directly or indirectly activate the processing
        capabilities of the
      </p>
      <p>
        Application, load, execute, access, utilise, store, employ the
        Application, or display
      </p>
      <p>information resulting from such capabilities.</p>
      <p>Website means the website at www.stackgo.io.</p>
      <p>2 Licence Grants and Restrictions</p>
      <p>
        2.1 Subject to the Customer’s compliance with this Agreement, including
        payment of all Fees,
      </p>
      <p>
        Stackgo grants to the Customer a non-exclusive, non-transferable licence
        to access and
      </p>
      <p>
        Use the Application for the Customer’s own business purposes during the
        term of this
      </p>
      <p>Agreement.</p>
      <p>2.2 The Application may only be used:</p>
      <p>(a) by the Customer and Entities;</p>
      <p>
        (b) for End Users up to the End User Limit (otherwise additional
        verification fees will
      </p>
      <p>apply);</p>
      <p>(c) in accordance with this Agreement; and</p>
      <a href="http://www.stackgo.io/"></a>{" "}
      <a href="http://www.stackgo.io/"></a>{" "}
      <a href="http://www.stackgo.io/"></a>{" "}
      <a href="http://www.stackgo.io/"></a>{" "}
      <a href="http://www.stackgo.io/"></a>
      <p>(d) in accordance with any written instructions from Stackgo.</p>
      <p>
        2.3 If the Customer requires an increase in the End User Limit or to add
        Entities (subject to
      </p>
      <p>
        Stackgo's approval of the Entity), the Customer will provide Stackgo
        with a written
      </p>
      <p>
        request. Stackgo will implement the request within a reasonable time of
        receiving it in
      </p>
      <p>accordance with this Agreement.</p>
      <p>
        2.4 Except as otherwise expressly permitted under this Agreement, the
        Customer must not
      </p>
      <p>(and must not allow a third party, including any Entity) to:</p>
      <p>
        (a) reverse engineer or otherwise attempt to discover the source code of
        or trade
      </p>
      <p>secrets embodied in the Application or any portion thereof;</p>
      <p>
        (b) distribute, transfer, grant sub-licences to, or otherwise make
        available the
      </p>
      <p>
        Application or any portion thereof to third parties, including as an
        application
      </p>
      <p>
        service provider, service bureau, or rental source, unless otherwise
        authorised in
      </p>
      <p>writing by Stackgo;</p>
      <p>
        (c) embed or incorporate in any manner the Application into other
        applications of the
      </p>
      <p>
        Customer or third parties other than as authorised by Stackgo in
        writing;
      </p>
      <p>(d) create modifications to or derivative works of the Application;</p>
      <p>(e) reproduce the Application;</p>
      <p>
        (f) attempt to modify, alter, or circumvent any licence control and
        protection
      </p>
      <p>mechanisms within the Application;</p>
      <p>
        (g) Use or transmit the Application in violation of any applicable law,
        rule, or
      </p>
      <p>regulation;</p>
      <p>(h) demonstrate the Application to a competitor of Stackgo;</p>
      <p>
        (i) intentionally access, use, or copy any portion of the Application to
        directly or
      </p>
      <p>
        indirectly develop, promote, distribute, sell, or support any product or
        service that
      </p>
      <p>is competitive with the Application; or</p>
      <p>
        (j) remove, obscure, or alter any copyright notices or any name,
        trademark, service
      </p>
      <p>
        mark, tagline, hyperlink, or other designation included on any display
        screen
      </p>
      <p>within the Application (“Stackgo’s Marks”).</p>
      <p>
        The Customer shall be responsible for all damages and liabilities
        incurred as a result of such actions.
      </p>
      <p>3 Delivery, Acceptance and How it Works</p>
      <p>
        Upon the Customer’s acceptance of this Agreement and payment of any
        required initial Fees such as
      </p>
      <p>
        the Subscription Plan being paid one (1) month in advance, Stackgo will
        make the Application
      </p>
      <p>
        available to the Customer for use through delivery of a password
        protected account. The
      </p>
      <p>
        Application is deemed accepted upon delivery to the Customer, provided
        that such
      </p>
      <p>
        acceptance shall not in any way impact the warranties as provided in
        this Agreement.
      </p>
      <p>3.1 Identification Document Photo</p>
      <p>
        The End User will be required to take a photo of its identification
        document through the Third
      </p>
      <p>
        Party Platform. If none of the photos taken by the End User enable the
        identification of the
      </p>
      <p>
        End User’s identification document, the End User may be required to
        recommence the
      </p>
      <p>verification process.</p>
      <p>3.2 Photographs</p>
      <p>
        The End User may be required to take a photograph of themselves via the
        Third Party
      </p>
      <p>
        Platform and the Third Party Provider will use reasonable endeavours to
        inspect the
      </p>
      <p>
        photographs provided to enable the identification on the photograph.
      </p>
      <p>3.3 Video and other data</p>
      <p>
        Video(s) of the End User may be recorded during the identification
        verification process and
      </p>
      <p>
        we or the Third Party Provider may inspect and control the data
        submitted by the End Uswer
      </p>
      <p>
        or any other data that has been collected during the verification
        process.
      </p>
      <p>3.4 Device Data</p>
      <p>
        The End User is required to accept the EULA and the Third Party Provider
        Terms and
      </p>
      <p>
        acknowledge that the Application and/or the Third Party Platform may
        inspect the device that
      </p>
      <p>
        the End User has used to access the Application and/or the Third Party
        Platform for the
      </p>
      <p>purposes of verifying the End User’s identification.</p>
      <p>3.5 Personal Information</p>
      <p>
        The End User will be required to provide personal information about
        themselves, such as their
      </p>
      <p>
        full name, date of birth, expiry date of the document and document
        number.
      </p>
      <p>3.6 Reaching a Verification Decision</p>
      <p>
        After carrying out the verification process and checking the data, the
        Application or the Third
      </p>
      <p>
        Party Platform will make one of the following verification decisions
        (each a "Verification
      </p>
      <p>Decision"):</p>
      <p>
        (a) A positive Verification Decision: approved; the document photo and
        the End User
      </p>
      <p>
        portrait photograph are clear and readable, the End User’s appearance
        matches
      </p>
      <p>
        the image on the document, there is no data about the manipulation of
        technical
      </p>
      <p>data or any other non-compliance of the submitted data.</p>
      <p>
        (b) A negative Verification Decision: declined; the verification and
        inspection of the
      </p>
      <p>
        End User is deemed to have failed. The verification of the End User is
        deemed to
      </p>
      <p>have failed if any of the following issues have been detected:</p>
      <p>
        (i) the End User has deliberately submitted data that does not comply
        with
      </p>
      <p>
        the personal identification data available in the personal
        identification
      </p>
      <p>documents database, or does not match other information or data</p>
      <p>obtained in the course of the verification process;</p>
      <p>
        (ii) the End User refused to follow the instructions given as to the
        framing
      </p>
      <p>and proportions of his or her face and document;</p>
      <p>
        (iii) there are circumstances that refer to the suspicion of criminal
        activities. If
      </p>
      <p>
        the suspicion is justified, Stackgo or the Third Party Provider may
        inform
      </p>
      <p>the Customer of this information.</p>
      <p>3.7 Communication of the Verification Decision</p>
      <p>
        Stackgo or the Third Party Provider will communicate the Verification
        Decision to the
      </p>
      <p>
        Customer and may provide the End User Data to the Customer that has been
        submitted by
      </p>
      <p>the End User to the Application or the Third Party Platform.</p>
      <p>4 Change Requests</p>
      <p>
        4.1 If the Customer requires any changes or enhancements to the
        Application, the Customer
      </p>
      <p>will provide Stackgo with a written request.</p>
      <p>
        4.2 If Stackgo agrees to implement a request to change or enhance the
        Application, which is
      </p>
      <p>
        a matter in Stackgo's sole discretion, Stackgo will advise the Customer
        of Stackgo's
      </p>
      <p>proposal for implementation including the Additional Charges.</p>
      <p>
        4.3 Within 30 days of receipt of Stackgo's proposal (or such other time
        as is agreed), the
      </p>
      <p>
        Customer must notify Stackgo in writing whether the Customer accepts the
        proposal, after
      </p>
      <p>
        which the Customer will be deemed to have refused the proposal. If the
        Customer
      </p>
      <p>
        accepts the proposal the parties will sign a variation in accordance
        with clause 18.5.
      </p>
      <p>
        4.4 The Customer acknowledges and agrees that Stackgo may include any
        changes,
      </p>
      <p>
        enhancements or developments requested by the Customer in future New
        Releases.
      </p>
      <p>5 Customer’s Obligations</p>
      <p>
        5.1 The Customer acknowledges that the operation of the Application and
        Third Party
      </p>
      <p>
        Platform may not always be completely error free and without
        interruption. The Customer
      </p>
      <p>and Entities should:</p>
      <p>
        (a) implement data backup and verification measures in accordance with
        best
      </p>
      <p>industry practice; and</p>
      <p>
        (b) keep in force throughout the term of this Agreement appropriate
        insurance
      </p>
      <p>
        against any loss that may be suffered in the event of failure, defect or
        error in the
      </p>
      <p>
        Application. Stackgo is not liable for any loss suffered by the Customer
        as a result
      </p>
      <p>of a failure to comply with this requirement.</p>
      <p>
        5.2 The Customer is solely responsible for any data, text, files,
        information, images, graphics,
      </p>
      <p>
        and other content or materials that the End Users upload to the
        Application or the Third
      </p>
      <p>
        Party Platform (collectively, “Customer Data”). Stackgo does not claim
        ownership of any
      </p>
      <p>
        Customer Data. By submitting and uploading Customer Data, the Customer
        grants
      </p>
      <p>
        Stackgo and the Third Party Provider a non-exclusive, worldwide,
        transferable,
      </p>
      <p>
        irrevocable right and licence to use, store, reproduce, modify,
        distribute, publicly perform,
      </p>
      <p>
        and publicly display the Customer Data for the purpose of providing the
        Application and
      </p>
      <p>
        Services to the Customer, and/or the End User and for deidentifying,
        benchmarking and
      </p>
      <p>reporting purposes.</p>
      <p>6 Stackgo’s Obligations</p>
      <p>
        6.1 Stackgo, or its third party hosting provider, will establish and
        maintain commercially
      </p>
      <p>
        reasonable administrative, physical, and technical safeguards designed
        to:
      </p>
      <p>(a) protect the security and confidentiality of Customer Data;</p>
      <p>
        (b) protect against anticipated threats or hazards to the security or
        integrity of
      </p>
      <p>Customer Data; and</p>
      <p>(c) protect against unauthorised access to or use of Customer Data.</p>
      <p>7 Services</p>
      <p>
        7.1 Without limiting anything else in this Agreement, in order for
        Stackgo to perform any
      </p>
      <p>
        Services successfully and in a timely manner, Stackgo requires the
        Customer's timely co-
      </p>
      <p>operation, including:</p>
      <p>
        (a) providing reasonable access to the Customer personnel, materials,
        information
      </p>
      <p>and facilities to assist Stackgo with its Services;</p>
      <p>(b) arranging access to Third Party Providers where applicable;</p>
      <p>(c) accept the Third Party Provider Terms;</p>
      <p>
        (d) ensuring that appropriate back-up, security and virus checking
        procedures are in
      </p>
      <p>place for any computer facilities the Customer provides;</p>
      <p>(e) making senior executives available for consultation on request;</p>
      <p>(f) providing reasonable working facilities for Stackgo; and</p>
      <p>
        (g) making decisions promptly to facilitate the performance of the
        Services.
      </p>
      <p>
        7.2 Unless expressly agreed to be fixed, any timeframes for the
        provision of Services
      </p>
      <p>
        (including without limitation any specified in the Subscription Plan)
        are estimates and are
      </p>
      <p>
        not contractually binding. Estimates of time for completing the Services
        are given on the
      </p>
      <p>
        assumption (among other things) that Stackgo receives the co-operation
        and commitment
      </p>
      <p>from the Customer as specified in clause 7.1.</p>
      <p>
        7.3 If the Customer does not perform or delays in performing any of its
        obligations (including
      </p>
      <p>
        those specified in clause 7.1), Stackgo is entitled, if it reasonably
        deems necessary, to
      </p>
      <p>
        vary any timetable and to pass on to the Customer any resulting costs or
        expenses as
      </p>
      <p>
        Additional Charges. Stackgo will advise the Customer of any likely or
        foreseeable delays
      </p>
      <p>
        and give the Customer a chance to rectify these likely delays before
        Stackgo applies
      </p>
      <p>Additional Charges.</p>
      <p>
        7.4 In the course of providing the Services, Stackgo will rely on
        information supplied by the
      </p>
      <p>
        Customer or others. Stackgo is not obliged to verify the accuracy and
        completeness of
      </p>
      <p>
        that information. If any information provided is materially incorrect,
        Additional Charges
      </p>
      <p>may apply.</p>
      <p>
        7.5 Stackgo is entitled to sub-contract, transfer, assign or novate any
        of the Services at its
      </p>
      <p>sole discretion.</p>
      <p>
        7.6 Stackgo may, at the Customer's request, provide additional
        consulting services relating to
      </p>
      <p>this Agreement for an Additional Charge.</p>
      <p>8 Fees and Payment</p>
      <p>
        8.1 The Customer agrees to pay the amount specified in the Plan Pricing
        with respect to the
      </p>
      <p>Subscription Plan selected by the Customer.</p>
      <p>8.2 Without limitation, Additional Charges may apply:</p>
      <p>
        (a) to any increase in the End User Limit or excess Use of the
        Application above the
      </p>
      <p>End User Limit;</p>
      <p>(b) to the addition of Entities;</p>
      <p>
        (c) to the increase in Maintenance and Support Services resulting from
        (a) or (b)
      </p>
      <p>above (if applicable):</p>
      <p>
        (d) for any work required to be undertaken by Stackgo to implement a
        request made
      </p>
      <p>under clause 2.3: and</p>
      <p>
        (e) to reflect an increase in Maintenance and Support Services required
        as a result of
      </p>
      <p>
        the implementation of a New Release, additional modules, customisations,
      </p>
      <p>enhancements or features (if any).</p>
      <p>
        8.3 Except for Additional Charges, which may be invoiced as and when
        incurred, all other
      </p>
      <p>Fees will be invoiced in accordance with the Subscription Plan.</p>
      <p>
        8.4 Subject to clause 8.5, the Customer must pay all invoices (without
        any set off, deduction
      </p>
      <p>
        or withholding whatsoever) within 14 days of the date of the invoice.
        Stackgo may charge
      </p>
      <p>
        interest on any amount not paid by the due date at a rate equal to two
        percent above the
      </p>
      <p>
        Corporate Overdraft Reference Rate of the Commonwealth Bank of Australia
        from time to
      </p>
      <p>time applicable for monthly charging cycles.</p>
      <p>
        8.5 The Customer must notify Stackgo of any dispute in relation to an
        invoice within 14 days
      </p>
      <p>
        of the date of the invoice and the reasons for the dispute or the full
        amount becomes due
      </p>
      <p>
        and payable in accordance with clause 8.4. If the Customer disputes in
        good faith any
      </p>
      <p>
        amount on an invoice, the Customer must pay the portion of the invoice
        not in dispute
      </p>
      <p>
        within 14 days of the date of the invoice. If it is agreed that some or
        all of the disputed
      </p>
      <p>
        amount was properly payable, then the Customer must immediately pay that
        amount
      </p>
      <p>together with interest in accordance with clause 8.4.</p>
      <p>8.6 Subject to clause 8.1 to 8.5,</p>
      <p>
        (a) prior to the conclusion of the Initial Term, Stackgo will not
        increase the Fees; and
      </p>
      <p>
        (b) after the conclusion of the Initial Term, Stackgo may increase the
        Fees by giving
      </p>
      <p>
        the Customer written notice. Changes in Fees will be effective 30 days
        after the
      </p>
      <p>notice has been sent to the Customer.</p>
      <p>9 Goods and Services Tax</p>
      <p>
        9.1 In this clause, terms used have the meaning given to them by the GST
        Law as defined in
      </p>
      <p>
        Section 195-1 of the A New Tax System (Goods and Services Tay) Act 1999
        (the “GST
      </p>
      <p>Act”).</p>
      <p>
        9.2 If a party to this Agreement (the “Supplier”) makes a supply under
        or in connection with
      </p>
      <p>
        this Agreement and is liable by law to pay GST on that supply, the
        consideration
      </p>
      <p>
        otherwise payable by the recipient of the supply will be increased by an
        amount equal to
      </p>
      <p>the GST paid or payable by the Supplier.</p>
      <p>
        9.3 If this Agreement requires a party to pay for, reimburse or
        contribute to any expense, loss
      </p>
      <p>
        or outgoing ("reimbursable expense”) suffered or incurred by another
        party, the amount
      </p>
      <p>
        required to be paid, reimbursed or contributed by the first party is the
        amount of the
      </p>
      <p>
        reimbursable expense net of any input tax credit or reduced input tax
        credit to which the
      </p>
      <p>other party is entitled in respect of the reimbursable expense.</p>
      <p>
        9.4 If a party to this Agreement has the benefit of an indemnity for a
        cost, expense, loss or
      </p>
      <p>
        outgoing (“indemnified cost”) under this Agreement, the indemnity is for
        the indemnified
      </p>
      <p>
        cost net of any input tax credit or reduced input tax credit to which
        that party is entitled in
      </p>
      <p>respect of the indemnified cost.</p>
      <p>
        9.5 Each party agrees to do all things, including providing tax invoices
        and other
      </p>
      <p>
        documentation that may be necessary or desirable to enable or assist the
        other party to
      </p>
      <p>
        claim any input tax credit, set-off, rebate or refund in relation to any
        amount of GST paid
      </p>
      <p>or payable in respect of any supply under this Agreement.</p>
      <p>
        9.6 Subject to the operation of this clause, all amounts in this
        Agreement are GST exclusive.
      </p>
      <p>10 Confidentiality</p>
      <p>
        10.1 Except as provided in this clause, a party must not, without the
        prior written approval of
      </p>
      <p>
        the other party, disclose the other party's Confidential Information.
      </p>
      <p>10.2 A party is not in breach of clause 10.1:</p>
      <p>
        (a) in circumstances where it is legally compelled to disclose the other
        party's
      </p>
      <p>Confidential Information; or</p>
      <p>
        (b) if it discloses Confidential Information (other than of a technical
        nature) to its
      </p>
      <p>
        related companies, solicitors, auditors, insurers and accountants or in
        the case of
      </p>
      <p>
        Stackgo, to third parties solely for the purposes of performing this
        Agreement.
      </p>
      <p>
        10.3 Each party must take all reasonable steps to ensure that its
        employees, agents and sub-
      </p>
      <p>
        contractors do not make public or disclose the other party's
        Confidential Information.
      </p>
      <p>
        10.4 Each party agrees to promptly notify the other party of any
        unauthorised access to or
      </p>
      <p>disclosure of the Confidential Information.</p>
      <p>
        10.5 The receiving party agrees that any breach of this clause may cause
        irreparable harm to
      </p>
      <p>
        the disclosing party, and such disclosing party shall be entitled to
        seek equitable relief in
      </p>
      <p>
        addition to all other remedies provided by this Agreement or available
        at law.
      </p>
      <p>11 Liability</p>
      <p>
        11.1 Subject to clauses 11.2, 11.4 and 18.6 and otherwise to the extent
        permitted by law:
      </p>
      <p>
        (a) the total aggregate liability of Stackgo to the Customer for loss or
        damage arising
      </p>
      <p>
        from or in relation to this Agreement, whether arising from breach of
        contract, tort
      </p>
      <p>
        (including negligence), in equity or otherwise, is limited to the Plan
        Pricing paid for
      </p>
      <p>
        the 6-month period immediately preceding the event giving rise to the
        loss or
      </p>
      <p>damage;</p>
      <p>
        (b) Stackgo is not liable to the Customer and the Customer has no right
        of set off, for:
      </p>
      <p>
        (i) any loss of data, goodwill, interest, revenues, profits, contracts
        or any
      </p>
      <p>inaccuracy of data; or</p>
      <p>
        (ii) any indirect, special, incidental, consequential, exemplary,
        punitive, or
      </p>
      <p>aggravated damages or loss; or</p>
      <p>
        (iii) any internal costs and expenses (whether director indirect)
        howsoever
      </p>
      <p>incurred,</p>
      <p>
        arising from the negligence of, or any breach of this Agreement by,
        Stackgo or its
      </p>
      <p>
        Representatives even if Stackgo has been advised of the possibility that
        such
      </p>
      <p>damages may occur,</p>
      <p>
        (c) Stackgo is not liable for loss or damage arising from third party
        products or
      </p>
      <p>services.</p>
      <p>
        11.2 Nothing in this Agreement limits Stackgo's liability to an
        individual for death or personal
      </p>
      <p>
        injury caused by a negligent or wilful act or omission of Stackgo or its
        Representatives.
      </p>
      <p>
        11.3 No warranty, condition or term (“term”) applies to this Agreement
        unless it is set out in this
      </p>
      <p>
        Agreement or is implied by law and cannot be excluded, modified or
        restricted. Without
      </p>
      <p>limiting clause 11.4:</p>
      <p>
        (a) the Application and Third Party Platform is supplied on an “as is”
        basis; and
      </p>
      <p>
        (b) the Application and Third Party Platform is suitable for use in
        Australia only. If the
      </p>
      <p>
        Customer intends to use the Application in, or in relation to, any other
        jurisdiction
      </p>
      <p>it does so at its own risk.</p>
      <p>11.4 If:</p>
      <p>
        (a) any term is implied into this Agreement by law, and the law
        prohibits the
      </p>
      <p>
        exclusion, restriction or modification of that term or liability under
        it, then that term
      </p>
      <p>shall be deemed to be included in this Agreement;</p>
      <p>
        (b) a statutory guarantee applies to goods or services supplied under
        this
      </p>
      <p>Agreement;</p>
      <p>
        then, to the extent permitted by law, Stackgo's liability for breach of
        that term or guarantee is limited to
      </p>
      <p>(at Stackgo's option):</p>
      <p>
        (c) in relation to services, re-supplying the services or paying for
        them to be re-
      </p>
      <p>supplied; and</p>
      <p>
        (d) in relation to goods, re-supplying them, or paying for having them
        re-supplied.
      </p>
      <p>
        11.5 The Customer will at all times indemnify and hold harmless Stackgo
        and its
      </p>
      <p>
        Representatives from and against any loss, costs (including reasonable
        legal costs and
      </p>
      <p>
        expenses) or liability incurred or suffered by Stackgo or its
        Representatives arising from
      </p>
      <p>
        any proceedings against Stackgo or its Representatives where such loss,
        costs or liability
      </p>
      <p>was caused by:</p>
      <p>
        (a) a breach by the Customer or an Entity of its obligations under this
        Agreement (or
      </p>
      <p>any other applicable licence terms);</p>
      <p>
        (b) any wilful, unlawful or negligent act or omission of the Customer,
        an Entity or their
      </p>
      <p>Representatives; or</p>
      <p>
        (c) a claim by a third party alleging an infringement of that third
        party's Intellectual
      </p>
      <p>
        Property Rights if such infringement relates to an actor omission of the
        Customer,
      </p>
      <p>a User, an Entity or their Representatives.</p>
      <p>12 Term and Termination</p>
      <p>12.1 Term</p>
      <p>(a) This Agreement is for the Initial Term.</p>
      <p>
        (b) If the Customer has paid all amounts due and owing to Stackgo and is
        not in
      </p>
      <p>
        breach of this Agreement, this Agreement automatically renews for an
        unlimited
      </p>
      <p>number of successive 12-month terms, unless otherwise terminated in</p>
      <p>accordance with clauses 12.2 or 12.3.</p>
      <p>12.2 Immediate Termination</p>
      <p>
        (a) Either party may terminate this Agreement effective upon written
        notice if the
      </p>
      <p>other party has an Event of Insolvency.</p>
      <p>
        (b) Stackgo may terminate this Agreement or any Subscription Plan
        effective upon
      </p>
      <p>written notice if:</p>
      <p>(i) Stackgo is required to do so by law; or</p>
      <p>
        (ii) the provision of the Application to you by Stackgo is, in the
        opinion of
      </p>
      <p>Stackgo, no longer commercially viable or has become impractical or</p>
      <p>unfeasible.</p>
      <p>12.3 Termination with notice</p>
      <p>(a) Termination by the Customer</p>
      <p>
        (i) Prior to the conclusion of the Initial Term, the Customer may
        terminate
      </p>
      <p>
        this Agreement for any reason. If the Customer does so, the Customer
      </p>
      <p>will be liable to pay the Early Termination Fee as set out in the</p>
      <p>Subscription Plan.</p>
      <p>
        (ii) After the conclusion of the Initial Term, the Customer may
        terminate this
      </p>
      <p>Agreement:</p>
      <p>(A) if Stackgo breaches this Agreement and fails (or is unable) to</p>
      <p>cure the breach following 30 days’ written notice; or</p>
      <p>
        (B) for any reason by providing Stackgo with 60 days’ written notice.
      </p>
      <p>(b) Termination/Suspension by Stackgo</p>
      <p>
        (i) Stackgo may, at its option, suspend all Licences for the Application
        and
      </p>
      <p>Services provided to the Customer or terminate this Agreement:</p>
      <p>
        (A) if the Customer fails to pay a Fee that remains overdue after first
      </p>
      <p>providing the Customer with ten (10) days’ prior written notice.</p>
      <p>Any suspension by Stackgo under the preceding sentence will not</p>
      <p>relieve the Customer of its payment obligations hereunder;</p>
      <p>
        (B) if the Customer breaches this Agreement and fails (or is unable)
      </p>
      <p>to cure the breach following 30 days’ written notice; or</p>
      <p>(C) for any reason by providing the Customer with 60 days’ written</p>
      <p>notice.</p>
      <p>12.4 The Customer must notify Stackgo of:</p>
      <p>(a) any change to the Customer's relationship with an Entity; and</p>
      <p>(b) any Entities to which the Agreement is no longer to apply.</p>
      <p>
        12.5 Stackgo reserves the right to terminate an Entity’s right to use
        the Application after
      </p>
      <p>
        receiving a notice under clause 12.4(a) or if the Entity breaches a term
        of this Agreement.
      </p>
      <p>12.6 On the termination or expiry of this Agreement:</p>
      <p>
        (a) the Customer and any Entities must no longer use the Application;
      </p>
      <p>(b) the Customer must pay any outstanding Fees to Stackgo;</p>
      <p>
        (c) other than as provided in clause 14.4, Stackgo may retain any moneys
        paid;
      </p>
      <p>
        (d) Stackgo may charge a reasonable sum for work performed up to
        termination in
      </p>
      <p>respect of which work no sum has previously been invoiced or paid;</p>
      <p>
        (e) Stackgo will be released from any further obligations under this
        Agreement;
      </p>
      <p>
        (f) either party may pursue any additional or alternative remedies
        provided by this
      </p>
      <p>Agreement or law;</p>
      <p>
        (g) each party must return any property (including Confidential
        Information) of the
      </p>
      <p>other; and</p>
      <p>
        (h) all copies of the Application must be returned to Stackgo and
        permanently erased
      </p>
      <p>
        from all equipment and storage devices used by the Customer and the
        Entities,
      </p>
      <p>
        regardless of whether such equipment and storage devices are owned by
        the
      </p>
      <p>Customer, the Entities or a third party.</p>
      <p>
        12.7 Any clauses of this Agreement which are capable of having effect
        after the expiration or
      </p>
      <p>
        termination of this Agreement will continue to remain in full force and
        effect.
      </p>
      <p>13 Personal Information</p>
      <p>
        13.1 Disclosure of personal information to Stackgo is subject to the
        Privacy Act 1988 (“Privacy
      </p>
      <p>
        Act”). Accordingly, the Services are provided on the understanding that
        the Customer
      </p>
      <p>
        (and any Entities) will only disclose personal information about an
        individual to Stackgo:
      </p>
      <p>(a) for a purpose related to the performance of the Services;</p>
      <p>
        (b) provided the Customer (or Entity) has made all disclosures required
        under the
      </p>
      <p>Privacy Act;</p>
      <p>
        (c) provided the Customer (or Entity) obtained any consents required
        under the
      </p>
      <p>
        Privacy Act to allow Stackgo and its Representatives to handle the
        information;
      </p>
      <p>and</p>
      <p>(d) provided to do so would not otherwise breach the Privacy Act.</p>
      <p>
        13.2 As Stackgo relies on the Customer and an Entity to fulfil the
        obligations in clause 13.1,
      </p>
      <p>
        the Customer indemnifies Stackgo and its Representatives against any
        claim, loss or
      </p>
      <p>
        expense resulting from the failure of the Customer or an Entity to make
        any disclosure or
      </p>
      <p>
        obtain any consent required under the Privacy Act or to otherwise comply
        with the Privacy
      </p>
      <p>Act.</p>
      <p>
        13.3 If the performance of the Services requires a third party to supply
        personal information to
      </p>
      <p>
        Stackgo on the Customer's request, it is the Customer's obligation to
        ensure that the third
      </p>
      <p>
        party complies with clause 13.1 and the Customer indemnifies Stackgo
        against any claim,
      </p>
      <p>
        loss or expense resulting from that party's failure to do so, or to
        otherwise comply with the
      </p>
      <p>Privacy Act.</p>
      <p>14 Intellectual Property</p>
      <p>14.1 Any Intellectual Property Rights:</p>
      <p>(a) in the Application;</p>
      <p>(b) database(s) design;</p>
      <p>
        (c) in any changes, modifications, developments or enhancements made to
        the
      </p>
      <p>Application by Stackgo or anyone else (including Customer specific</p>
      <p>modifications);</p>
      <p>(d) arising from Stackgo's provision of the Services; and</p>
      <p>(e) on the Website,</p>
      <p>are owned and are the sole property of Stackgo or its licensors.</p>
      <p>
        14.2 Stackgo indemnifies the Customer against any finally awarded
        damages or settlement
      </p>
      <p>
        amounts approved by Stackgo arising out of or in connection with a claim
        that the use of
      </p>
      <p>
        the Application in accordance with this Agreement infringes a third
        party's copyright or an
      </p>
      <p>Australian patent, provided that the Customer:</p>
      <p>
        (a) notifies Stackgo immediately of any infringement or claim of which
        it is aware and
      </p>
      <p>
        does not make any admissions without Stackgo's prior written consent;
      </p>
      <p>
        (b) at Stackgo's request and expense, allows Stackgo to conduct and/or
        settle all
      </p>
      <p>negotiations and litigation resulting from any such claim,</p>
      <p>
        (c) assists and fully co-operates with Stackgo in the defence or
        settlement of the
      </p>
      <p>claim; and</p>
      <p>
        (d) does not take and ensures that no Entity takes any action that
        prejudices
      </p>
      <p>Stackgo's ability to defend or settle the claim.</p>
      <p>
        14.3 Notwithstanding anything to the contrary in this Agreement, Stackgo
        is not responsible or
      </p>
      <p>
        liable for any infringement of a third party’s Intellectual Property
        Rights if the infringement
      </p>
      <p>would not otherwise exist except for:</p>
      <p>
        (a) the use of the Application with any other software or equipment;
      </p>
      <p>
        (b) any corrections to or modifications of the Application database
        other than by
      </p>
      <p>Stackgo (even if performed by a Certified Party);</p>
      <p>
        (c) modifications to the Application in compliance with the Customer's
        specifications
      </p>
      <p>or requirements;</p>
      <p>
        (d) a failure to promptly implement a New Release made available by
        Stackgo;
      </p>
      <p>
        (e) any breach of this Agreement by the Customer or an Entity, or any
        unauthorised
      </p>
      <p>
        use, access or modification to the Application by the Customer, an
        Entity or a
      </p>
      <p>third party,</p>
      <p>
        and in such circumstances Stackgo's obligations under clause 14.2 do not
        apply.
      </p>
      <p>
        14.4 If the Application is or is likely to become the subject of a claim
        of infringement, Stackgo
      </p>
      <p>may at its sole discretion:</p>
      <p>
        (a) procure for the Customer and any Entity the right to use the
        Application or the
      </p>
      <p>affected part of it;</p>
      <p>(b) replace or modify the Application to make it non infringing; or</p>
      <p>
        (c) require the return of the Application and refund to the Customer any
        Plan Pricing
      </p>
      <p>
        paid for the Application for the remainder of the then current term.
      </p>
      <p>
        14.5 If Stackgo's use or possession of the Application is determined by
        an independent tribunal
      </p>
      <p>
        of fact or law to infringe the Intellectual Property Rights of a third
        person, the Customer
      </p>
      <p>
        and any Entity must cease using the Application immediately and Stackgo
        will refund to
      </p>
      <p>
        the Customer any Plan Pricing paid for the remainder of the then current
        term.
      </p>
      <p>
        14.6 To the extent permitted by law and subject to clause 18.6, clauses
        14.2 to 14.5 state
      </p>
      <p>
        Stackgo's entire liability and the Customer's sole and exclusive
        remedies for any claim of
      </p>
      <p>infringement of third party's rights.</p>
      <p>15 Non-Solicitation</p>
      <p>
        15.1 Other than with Stackgo's prior written consent, the Customer will
        not engage with the
      </p>
      <p>
        Third Party Provider under a contract for services, either directly or
        indirectly (including by
      </p>
      <p>
        a related body corporate or through a recruitment or staffing agency).
      </p>
      <p>
        15.2 The Customer must promptly notify Stackgo if the Third Party
        Provider seeks to be
      </p>
      <p>contracted by the Customer (or a related body corporate).</p>
      <p>
        15.3 If despite clause 15.1, the Customer engages with the Third Party
        Provider without
      </p>
      <p>
        Stackgo's consent, the Customer must immediately pay to Stackgo an
        amount equal to
      </p>
      <p>
        thirty percent (30%) of the annualised revenue that Stackgo would have
        received but for
      </p>
      <p>the breach of this clause 15.3.</p>
      <p>
        15.4 The Customer acknowledges and agrees that this provision is
        reasonable having regard
      </p>
      <p>
        to Stackgo's need to protect its intellectual capital and confidential
        information. Without
      </p>
      <p>
        limiting Stackgo's right to compensation under clause 15.3, the Customer
        acknowledges
      </p>
      <p>
        that Stackgo may seek interlocutory relief to prohibit or restrain the
        Customer from any
      </p>
      <p>breach or threatened breach of this clause.</p>
      <p>
        15.5 A breach of this clause is deemed a material breach of the
        Agreement for the purposes of
      </p>
      <p>termination.</p>
      <p>16 Announcements</p>
      <p>
        16.1 Stackgo may identify the Customer (and an Entity) as a customer of
        Stackgo and a user
      </p>
      <p>
        of the Application on Stackgo's published customer lists, web site, and
        in its marketing
      </p>
      <p>
        and advertising materials and announcements. Stackgo may also include
        non-confidential
      </p>
      <p>
        information about the Customer's business and implementation of the
        Application.
      </p>
      <p>Stackgo may release updated information from time to time.</p>
      <p>17 Dispute Resolution</p>
      <p>17.1 Negotiation</p>
      <p>
        Except as is expressly provided for in this Agreement, if there is a
        dispute or difference between the
      </p>
      <p>
        parties arising out of or in connection with this Agreement (Dispute),
        then within five Business
      </p>
      <p>
        Days of a party notifying the other party in writing of the Dispute, a
        senior representative from
      </p>
      <p>
        each party must meet and use all reasonable endeavours acting in good
        faith to resolve the
      </p>
      <p>Dispute by joint discussions.</p>
      <p>17.2 Mediation</p>
      <p>
        (a) If the Dispute is not settled within thirty (30) days of
        notification under clause 17.1,
      </p>
      <p>
        the parties must submit the Dispute to mediation administered by the
        Australian
      </p>
      <p>Commercial Disputes Centre.</p>
      <p>
        (b) The Australian Commercial Disputes Centre's Guidelines for
        Commercial
      </p>
      <p>
        Mediation apply to any mediation under this Agreement and the parties
        must
      </p>
      <p>comply with those rules.</p>
      <p>
        (c) Any mediation meetings and proceedings under this clause must be
        held in
      </p>
      <p>Sydney, New South Wales.</p>
      <p>17.3 Court proceedings and other relief</p>
      <p>
        A party may not start court proceedings in relation to a Dispute until
        it has exhausted the procedures
      </p>
      <p>
        in this clause, unless the party seeks injunctive or other interlocutory
        relief.
      </p>
      <p>17.4 Continuation of rights and obligations</p>
      <p>
        Despite the existence of a dispute or difference each party must
        continue to perform this Agreement.
      </p>
      <p>18 General</p>
      <p>18.1 Notices</p>
      <p>
        Any notice, demand, consent or other communication (a "Notice”) given or
        made under this
      </p>
      <p>Agreement:</p>
      <p>
        (a) must be in writing and signed by a person duly authorised by the
        Customer;
      </p>
      <p>
        (b) must be delivered to the intended recipient by prepaid post (if
        posted to an
      </p>
      <p>
        address in another country, by registered airmail), by hand, by email,
        or by fax at
      </p>
      <p>
        the address, email, facsimile number shown in the Subscription Plan;
      </p>
      <p>(c) will be conclusively taken to be duly given or made:</p>
      <p>(i) in the case of delivery in person, when delivered;</p>
      <p>
        (ii) in the case of delivery by post, seven Business Days after the date
        of
      </p>
      <p>posting;</p>
      <p>
        (iii) if by email, on the earlier of the Customer receiving an automated
      </p>
      <p>message confirming delivery or, provided no automated message is</p>
      <p>
        received stating that the email has not been delivered, three hours
        after
      </p>
      <p>
        the time the email was sent by the Customer, such time to be determined
      </p>
      <p>by reference to the device from which the email was sent,</p>
      <p>
        but if the result is that a Notice would be taken to be given or made on
        a day that is not a
      </p>
      <p>
        Business Day in the place to which the Notice is sent or at a time that
        is later than
      </p>
      <p>
        5.30 pm in the place to which the Notice is sent, it will be
        conclusively taken to have
      </p>
      <p>
        been duly given or made at the start of business on the next Business
        Day in that
      </p>
      <p>place.</p>
      <p>18.2 Assignment</p>
      <p>
        (a) The terms of this Agreement shall be binding on the parties and
        their successors.
      </p>
      <p>
        (b) Stackgo or the Customer may assign its rights and obligations under
        this
      </p>
      <p>Agreement:</p>
      <p>
        (i) to a third party if any part of the business of Stackgo or the
        Customer is
      </p>
      <p>sold or transferred to that third party; and/or</p>
      <p>
        (ii) provided that Stackgo or the Customer (as applicable) can
        demonstrate
      </p>
      <p>
        that the assignee is able to perform its obligations under this
        Agreement.
      </p>
      <p>
        Stackgo or the Customer may not otherwise assign its rights under this
        Agreement without the
      </p>
      <p>
        consent of the other party and such consent will not be unreasonably
        withheld or
      </p>
      <p>delayed.</p>
      <p>
        (c) Any attempted assignment, transfer, or delegation in violation of
        the foregoing
      </p>
      <p>shall be null and void.</p>
      <p>18.3 Further assurances</p>
      <p>
        Each party must do anything necessary or desirable (including executing
        agreements and
      </p>
      <p>
        documents) to give full effect to this Agreement and the transactions
        contemplated by it.
      </p>
      <p>18.4 Entire Agreement</p>
      <p>
        The Agreement and any variations to it embody the entire understanding
        and agreement between the
      </p>
      <p>
        parties as to its subject matter. All previous negotiations,
        understandings, representations or
      </p>
      <p>
        warranties (“other understandings') in relation to the subject matter of
        this Agreement are
      </p>
      <p>
        superseded by this Agreement. The Customer acknowledges and agrees that
        in entering into
      </p>
      <p>
        this Agreement it is not relying on any other understandings (including
        any express
      </p>
      <p>warranties) which are not expressly set out in this Agreement.</p>
      <p>18.5 Amendments</p>
      <p>
        This Agreement may only be modified or supplemented by a written
        document executed by an
      </p>
      <p>authorised representative of each party.</p>
      <p>18.6 Compliance with the Australian Consumer Law</p>
      <p>
        If the Australian Consumer Law in Schedule 2 to the Competition and
        Consumer Act 2010 (Cth)
      </p>
      <p>
        applies to any supply of goods or services under this Agreement, nothing
        in this Agreement
      </p>
      <p>
        excludes, restricts or modifies a guarantee, right, liability or remedy
        to the extent that to do so
      </p>
      <p>
        would render a term void or be a breach of the Competition and Consumer
        Act 2010.
      </p>
      <p>18.7 Waiver</p>
      <p>
        A failure to exercise or a delay in exercising any right, power or
        remedy under this Agreement does
      </p>
      <p>
        not operate as a waiver. A single or partial exercise or waiver of the
        exercise of any right,
      </p>
      <p>
        power or remedy does not preclude any other or further exercise of that
        or any other right,
      </p>
      <p>
        power or remedy. A waiver is not valid or binding on the Party granting
        that waiver unless
      </p>
      <p>made in writing.</p>
      <p>18.8 Severability</p>
      <p>
        (a) Any provision in this Agreement that is invalid or unenforceable in
        any jurisdiction
      </p>
      <p>
        is to be read down for the purpose of that jurisdiction, if possible, so
        as to be valid
      </p>
      <p>
        and enforceable, and otherwise shall be severed to the extent of the
        invalidity or
      </p>
      <p>
        unenforceability, without affecting the remaining provisions of this
        Agreement, so
      </p>
      <p>
        long as the economic or legal substance of the transactions contemplated
        hereby
      </p>
      <p>is not affected in any manner adverse to any Party.</p>
      <p>
        (b) Upon such determination, the Parties shall negotiate in good faith
        in an effort to
      </p>
      <p>
        agree upon such a suitable and equitable provision to effect the
        original intention
      </p>
      <p>of the Parties.</p>
      <p>18.9 Remedies cumulative</p>
      <p>
        The rights, powers and remedies provided to a Party in this Agreement
        are in addition to, and do not
      </p>
      <p>
        exclude or limit, any right, power or remedy provided by law or equity
        or any agreement.
      </p>
      <p>18.10 No Partnership or Agency</p>
      <p>
        Nothing contained or implied in this Agreement shall be construed to
        place the Parties in the
      </p>
      <p>
        relationship of partners, joint venturers, principal and agent, or
        employer and employee.
      </p>
      <p>
        Neither Party shall have the power to assume, create, or incur liability
        or any obligation of any
      </p>
      <p>
        kind, express or implied, in the name of or on behalf of the other Party
        by virtue of this
      </p>
      <p>Agreement.</p>
      <p>18.11 Governing law & Jurisdiction</p>
      <p>
        (a) This Agreement is governed by and is to be construed in accordance
        with the
      </p>
      <p>laws of New South Wales.</p>
      <p>
        (b) With respect to any legal action or proceedings which may be brought
        with
      </p>
      <p>
        respect to this Agreement or any transaction contemplated by this
        Agreement
      </p>
      <p>
        (each, a Relevant Action) with respect to any Customer in Australia,
        each party
      </p>
      <p>irrevocably and unconditionally:</p>
      <p>
        (i) submits to and accepts, for itself and in respect of its assets, the
        non-
      </p>
      <p>
        exclusive jurisdiction of courts exercising jurisdiction in Sydney New
      </p>
      <p>
        South Wlaes in connection with matters concerning this Agreement; and
      </p>
      <p>
        (ii) agrees that it will not object to the venue or claim that the
        Relevant Action
      </p>
      <p>has been brought in an inconvenient forum.</p>
      <p>
        (c) The United Nations Convention on Contracts for the International
        Sale of Goods
      </p>
      <p>(and any adopting legislation) does not apply to this Agreement.</p>
      <p>18.12 Limitation of action</p>
      <p>
        Subject to clause 18.6 and to the maximum extent permitted by law, other
        than in relation to a claim
      </p>
      <p>
        for breach of Stackgo's Intellectual Property Rights, no claim can be
        made under this
      </p>
      <p>Agreement more than two (2) years after;</p>
      <p>(a) the discovery of the circumstances giving rise to the claim; or</p>
      <p>(b) the effective date of termination of this Agreement.</p>
      <p>18.13 Order of Priority</p>
      <p>
        Where there is any conflict between documents comprising this Agreement,
        they will prevail in the
      </p>
      <p>following order:</p>
      <p>(a) the Subscription Plan;</p>
      <p>(b) these Terms and Conditions;</p>
      <p>
        (c) any additional documents described in the Agreement in the order
        listed in these
      </p>
      <p>Terms and Conditions.</p>
      <p>18.14 Communication</p>
      <p>
        (a) Other than in relation to clause 18.1, Stackgo may communicate
        electronically
      </p>
      <p>with the Customer.</p>
      <p>
        (b) Electronically transmitted information is not guaranteed to be
        secure or error free
      </p>
      <p>
        and can be adversely affected or unsafe to use. Stackgo will not be
        liable in
      </p>
      <p>
        respect of any error, omission or loss of confidentiality arising from
        or in
      </p>
      <p>connection with the electronic communication of information.</p>
      <p>18.15 Non-exclusivity</p>
      <p>
        Nothing in this Agreement will prevent or restrict Stackgo from
        providing services to customers other
      </p>
      <p>
        than the Customer. Without limiting the foregoing, Stackgo may use all:
      </p>
      <p>(a) skill and knowledge acquired; and</p>
      <p>(b) ideas and methodologies developed,</p>
      <p>
        during the course of performing the Services for any purposes, including
        to perform services for other
      </p>
      <p>Customers.</p>
      <p>18.16 Force Majeure</p>
      <p>
        (a) Each party will be excused from any delay or failure in performance
        hereunder,
      </p>
      <p>
        other than the payment of money, caused by reason of a Force Majeure
        Event.
      </p>
      <p>
        (b) The obligations and rights of the party so excused will be extended
        on a day-to-
      </p>
      <p>
        day basis for the period of time equal to that of the underlying cause
        of the delay.
      </p>
    </Article>
  );
}
