/**
 * Renders the ReportRequested component.
 * This component represents the endpoint for the report requested page.
 * It displays a card with information about the verification process and allows the user to download their verification report.
 *
 *   ReportRequested
 *
 **********/

import React, { Fragment } from "react";
import { Row, Card} from "components/lib";

const axios = require("axios");

export function ReportRequested(props) {
  return (
    <Fragment>
      <Row color="white">
        <Card>
          <p>
            Your report will be emailed to you shortly. You can now close this
            window.
          </p>
        </Card>
      </Row>
    </Fragment>
  );
}
