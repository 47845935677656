import React, { Fragment, useCallback, useContext } from "react";
import Axios from "axios";
import { AuthContext, Row, AccountNav, useAPI, Grid } from "components/lib";
import "survey-core/defaultV2.min.css";
import { StylesManager, Model, FunctionFactory } from "survey-core";
import { Survey } from "survey-react-ui";
import { SurveyQuestionQuill } from "./QuillComponent";

StylesManager.applyTheme("defaultV2");

const defaultImageFunction = (params) => {
  if (!params[0]) {
    return "https://storage.googleapis.com/identitycheck/1x1.png";
  }
  console.log("params", params[0]);
  return params[0];
};

FunctionFactory.Instance.register("defaultImageFunction", defaultImageFunction);

const json = {
  completedHtml: "<h3>Your changes have been saved.</h3>",
  pages: [
    {
      name: "Enable Custom Emails",
      elements: [
        {
          type: "boolean",
          name: "enableCustomEmails",
          title: "Enable Custom Emails",
          isRequired: true,
        },
      ],
    },
    {
      name: "info",
      elements: [
        {
          type: "text",
          name: "accountName",
          title: "Account Name",
          readOnly: true,
        },
        {
          type: "dropdown",
          name: "language",
          title: "Language",
          defaultValue: "en",
          description: "This will translate the language of the ‘confirm my identity’ button and ‘This service is supplied by IdentityCheck.’ text in the email below.",
          enableIf: "{enableCustomEmails} = true",
          choices: [
            { value: "en", text: "English" },
            { value: "es", text: "Spanish" },
            { value: "fr", text: "French" },
            { value: "de", text: "German" },
          ],
        },
        {
          type: "text",
          isRequired: true,
          name: "subject",
          title: "Subject",
          enableIf: "{enableCustomEmails} = true",
        },
        {
          type: "text",
          inputType: "email",
          name: "contactEmail",
          title: "Reply to Email",
          isRequired: true,
          enableIf: "{enableCustomEmails} = true",
        },
        {
          type: "text",
          inputType: "url",
          name: "logoUrl",
          title: "Logo URL (max: 150px X 40px)",
          description:
            "You can use the default image by leaving this field blank or link to your logo from a public URL.",
          // isRequired: true,
          enableIf: "{enableCustomEmails} = true",
        },
        {
          type: "quill",
          name: "body",
          title: "Email Body",
          description:
            "Additional information you may wish to pass on to individuals undergoing an identity check.",
          enableIf: "{enableCustomEmails} = true",
        },
      ],
    },
    {
      name: "preview",
      elements: [
        {
          type: "html",
          name: "preview",
          html: `<!DOCTYPE html>
          <html style="background-color: #faf9f6">
            <head>
              <meta name="viewport" content="width=device-width" />
              <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
              <title>{{title}}</title>
          
              <style media="all" type="text/css">
                @media all {
                  br {
                    margin-bottom: 5px;
                  }
          
                  .ExternalClass {
                    width: 100%;
                  }
          
                  .ExternalClass,
                  .ExternalClass p,
                  .ExternalClass span,
                  .ExternalClass font,
                  .ExternalClass td,
                  .ExternalClass div {
                    line-height: 100%;
                  }
                }
                @font-face {
                  font-family: "MontBold";
                  src: local("MontBold"),
                    url("../client/src/components/fonts/Mont-HeavyDEMO.otf")
                      format("opentype");
                }
              </style>
          
              <!--[if gte mso 9]>
                <xml>
                  <o:OfficeDocumentSettings>
                    <o:AllowPNG />
                    <o:PixelsPerInch>96</o:PixelsPerInch>
                  </o:OfficeDocumentSettings>
                </xml>
              <![endif]-->
            </head>
          
            <body
              style="
                font-family: sans-serif;
                -webkit-font-smoothing: antialiased;
                font-size: 14px;
                line-height: 1.4;
                -ms-text-size-adjust: 100%;
                -webkit-text-size-adjust: 100%;
                background-color: #faf9f6;
                margin: 0;
                padding: 0;
              "
            >
            <div>
            <p style="
            font-family: sans-serif;
            font-size: 18px;">Subject: <b>{subject} </b> </p>
            </div>
              <table
                border="0"
                cellpadding="0"
                cellspacing="0"
                style="
                  border-collapse: separate;
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  width: 100%;
                  background-color: #faf9f6;
                  text-align: center;
                "
                width="100%"
                bgcolor="#f6f6f6"
                align="center"
              >
                <!-- start wrapper -->
                <tr>
                  <td
                    style="
                      font-family: sans-serif;
                      font-size: 14px;
                      vertical-align: top;
                      margin: 0 auto !important;
                      padding: 10px;
                      width: 100%;
                    "
                    width="100%"
                    valign="top"
                  >
                    <!-- start white box -->
                    <div
                      style="
                        box-sizing: border-box;
                        display: block;
                        margin: 0 auto;
                        width: 520px;
                        padding: 10px;
                      "
                    >
                      <!-- start logo -->
                      <div style="margin-bottom: 20px; margin-top: 10px; width: 500px">
                        <table
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          style="
                            border-collapse: separate;
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            width: 500px;
                            min-width: 100%;
                          "
                          width="500"
                        >
                          <tr style="object-fit: contain">
                            <td
                              style="
                                font-family: sans-serif;
                                font-size: 14px;
                                vertical-align: middle;
                                text-align: center;
                              "
                              valign="top"
                              align="center"
                            >
                              <a
                                href="{{domain}}"
                                target="_blank"
                                style="color: #3498db; text-decoration: underline"
                                ><img
                                  src="{validatedLogo}"
                                  alt="Logo"
                                  align="center"
                                  style="none; -ms-interpolation-mode: bicubic; max-width: 100%; max-width: 150px; max-height: 41.5px;"
                              /></a>
                            </td>
                            <td
                              style="
                                font-family: sans-serif;
                                font-size: 14px;
                                vertical-align: middle;
                                text-align: center;
                              "
                              valign="top"
                              align="center"
                            >
                              <a
                                href="{{domain}}"
                                target="_blank"
                                style="color: #3498db; text-decoration: underline"
                                ><img
                                  src="https://storage.googleapis.com/identitycheck/IdentityCheck_Logo_RGB_Purple_300px.png"
                                  alt="Logo"
                                  align="center"
                                  style="none; -ms-interpolation-mode: bicubic; width: 250px"
                              /></a>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <!-- end logo -->
          
                      <!-- start content -->
                      <table
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          border-collapse: separate;
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          width: 500px;
                          background: #fff;
                          border-radius: 3px;
                        "
                        width="500"
                      >
                        <tr>
                          <td
                            style="
                              vertical-align: top;
                              box-sizing: border-box;
                              padding: 0 40px;
                              text-align: left;
                            "
                            valign="top"
                          >
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              style="
                                border-collapse: separate;
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                width: 100%;
                                background: #fff;
                                border-radius: 3px;
                              "
                              width="100%"
                            >
                              <tbody>
                                <!-- start title -->
                                <tr>
                                  <td
                                    style="
                                      font-family: sans-serif;
                                      font-size: 14px;
                                      vertical-align: top;
                                      padding: 30px 0 20px;
                                    "
                                    valign="top"
                                  >
                                    <h1
                                      style="
                                        color: #555555;
                                        font-family: 'MontBold', helvetica, arial,
                                          sans-serif;
                                        font-weight: 600;
                                        line-height: 1.4;
                                        margin: 0 0 0 0;
                                        font-size: 28px;
                                        text-align: left;
                                      "
                                    >
                                      
                                    </h1>
                                  </td>
                                </tr>
                                <!-- end title -->
          
                                <!-- start message -->
                                <tr>
                                  <td
                                    style="
                                      font-size: 18px;
                                      vertical-align: top;
                                      padding: 0;
                                      color: #000000;
                                    "
                                    valign="top"
                                  >{body}
                                  <br />
                                  <p>
                                   This service is supplied by IdentityCheck.</p>
                                  <br />
                                  <br />
                                  </td>
                                </tr>
                                <!-- end message -->
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <!-- end header -->
          
                        <!-- start button -->
                        <tr>
                          <td
                            style="
                              font-family: sans-serif;
                              font-size: 14px;
                              vertical-align: top;
                              box-sizing: border-box;
                              padding: 0px 40px 20px;
                            "
                            valign="top"
                          >
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              style="
                                border-collapse: separate;
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                width: 100%;
                                box-sizing: border-box;
                                min-width: 100% !important;
                              "
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    align="center"
                                    style="
                                      font-family: sans-serif;
                                      font-size: 14px;
                                      vertical-align: top;
                                      padding-bottom: 15px;
                                    "
                                    valign="top"
                                  >
                                    <table
                                      border="0"
                                      cellpadding="0"
                                      cellspacing="0"
                                      style="
                                        border-collapse: separate;
                                        mso-table-lspace: 0pt;
                                        mso-table-rspace: 0pt;
                                        width: 100%;
                                      "
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style="
                                              font-family: sans-serif;
                                              font-size: 14px;
                                              vertical-align: top;
                                              background-color: #8c66f2;
                                              border-radius: 2px;
                                              text-align: center;
                                            "
                                            valign="top"
                                            bgcolor="#8EC480"
                                            align="center"
                                          >
                                            <a
                                              href="{{buttonURL}}"
                                              target="_blank"
                                              style="
                                                padding: 20px;
                                                display: block;
                                                color: #ffffff;
                                                background-color: #8c66f2;
                                                border-radius: 4px;
                                                box-sizing: border-box;
                                                cursor: pointer;
                                                text-decoration: none;
                                                font-family: 'Roboto', helvetica, arial,
                                                  sans-serif;
                                                font-size: 18px;
                                                font-weight: 700;
                                                margin: 0;
                                                text-transform: uppercase;
                                                border-color: #3498db;
                                              "
                                              >CONFIRM MY IDENTITY</a
                                            >
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <!-- end button -->
                      </table>
                    </div>
                    <!-- end white box -->
          
                    <!-- start footer -->
                    <div
                      style="
                        clear: both;
                        text-align: center;
                        margin: 0 auto;
                        width: 500px;
                      "
                    >
                      <table
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          border-collapse: separate;
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          width: 100%;
                        "
                        width="100%"
                      >
                        <tr>
                          <td
                            style="
                              font-family: 'Roboto', helvetica, arial, sans-serif;
                              vertical-align: top;
                              padding-top: 10px;
                              padding-bottom: 10px;
                              font-size: 12px;
                              color: #0b0b0b;
                              text-align: center;
                            "
                            valign="top"
                            align="center"
                          >
                            <span
                              id="copyright"
                              style="
                                color: #0b0b0b;
                                font-family: 'Roboto', helvetica, arial, sans-serif;
                                font-size: 12px;
                                line-height: 1.8;
                                text-align: center;
                              "
                            ></span>
                            <br />
                            Don't like these emails?
                            <a
                              href="%unsubscribe_url%"
                              style="
                                text-decoration: underline;
                                color: #0b0b0b;
                                font-size: 12px;
                                font-family: 'Roboto', helvetica, arial, sans-serif;
                                text-align: center;
                              "
                              >Unsubscribe</a
                            >. <br />
                            View our end user licence agreement
                            <a
                              href="https://identity.stackgo.io/eula"
                              style="
                                text-decoration: underline;
                                color: #0b0b0b;
                                font-size: 12px;
                                font-family: 'Roboto', helvetica, arial, sans-serif;
                                text-align: center;
                              "
                              >here</a
                            >.
                          </td>
                        </tr>
                      </table>
                    </div>
                    <!-- end footer -->
                  </td>
                </tr>
                <!-- end wrapper -->
              </table>
            </body>
          </html>          
          `,
          enableIf: "{enableCustomEmails} = true",
        },
      ],
    },
  ],
  showQuestionNumbers: "off",
  calculatedValues: [
    {
      name: "validatedLogo",
      expression: "defaultImageFunction({logoUrl})",
      includeIntoResult: false,
    },
  ],
};


export function CustomEmailsView(props) {
  const accountInfo = useAPI("/api/account");
  const survey = new Model(json);

  function setExistingCustomEmailSettings() {
    if (!accountInfo?.loading && accountInfo?.data) {
      survey.setValue("accountName", accountInfo?.data.name);
      survey.setValue(
        "enableCustomEmails",
        accountInfo?.data.custom_email_enabled
      );
      survey.setValue(
        "subject",
        accountInfo?.data.custom_email_settings?.subject ||
          `${accountInfo?.data.name} is inviting you to complete an Identity Check`
      );
      survey.setValue("contactEmail", accountInfo?.data.owner_email);
      survey.setValue(
        "logoUrl",
        accountInfo?.data.custom_email_settings?.logoUrl
      );
      survey.setValue("body", accountInfo?.data.custom_email_settings?.body);
      survey.setValue(
        "language",
        accountInfo?.data.custom_email_settings?.language || "en"
      );
    }
  }

  async function saveSurveyResults(url, json) {
    console.log("saveSurveyResults", url, json);
    const response = await Axios.post(url, json);
  }

  const customEmailConfigComplete = useCallback((sender) => {
    saveSurveyResults("/api/account/custom-email", sender.data);
    window.location = "/account"
  });

  useContext(AuthContext);

  setExistingCustomEmailSettings();
  survey.onComplete.add(customEmailConfigComplete);

  return (
    <Fragment>
      <AccountNav />
      <Grid>
        <Row>
          <h1>Custom Emails</h1>
          {!accountInfo?.loading && <Survey model={survey} />}
        </Row>
      </Grid>
    </Fragment>
  );
}
